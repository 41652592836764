import ReactPlayer from "react-player";

export default function PromotorsDetialsCampaign({
  promotionDataJson,
  setServiceForm,
  handleClickCounterPromotion,
}) {
  const handleOpenForm = (promotionData) => {
    setServiceForm(true);
    handleClickCounterPromotion(
      "leads",
      promotionData?._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  const viewMoreButton = () => {
    if (promotionDataJson?.contactUrl == "form") {
      return (
        <button onClick={() => handleOpenForm(promotionDataJson)}>
          {promotionDataJson?.contactButtonText}
        </button>
      );
    } else {
      return (
        <a href={promotionDataJson?.contactUrl} target="_blank">
          <button>{promotionDataJson?.contactButtonText}</button>
        </a>
      );
    }
  };
  return (
    <div className="template-company-container">
      <div className="template-company-details">
        <div className="">
          <img src={promotionDataJson?.promotionLogo} alt="" className="" />
          {/* <div>
            <h1 className="">{promotionDataJson?.contactTitle}</h1>
            <p className="max-w-[406px] md:mt-[12px]">
              {promotionDataJson?.description}
            </p>
          </div> */}
        </div>
      </div>
      <div className="flex justify-between gap-[30px]">
        <div className="template-company-description ">
          <p
            dangerouslySetInnerHTML={{
              __html:
                promotionDataJson?.eventHeader?.promoterDescription?.replace(
                  /\n/g,
                  "<br />"
                ),
            }}
          ></p>
        </div>
        <div className={`template-company-more `}>
          <h1>{promotionDataJson?.eventHeader?.contactDescription} </h1>
          {viewMoreButton()}
        </div>
      </div>
      <div className="template-company-description-mobile">
        <p
          dangerouslySetInnerHTML={{
            __html:
              promotionDataJson?.eventHeader?.promoterDescription?.replace(
                /\n/g,
                "<br />"
              ),
          }}
        />
      </div>
      <div
        className={`template-company-more-mobile ${
          promotionDataJson?.galleryItems?.length % 2 == 0
            ? "template-company-gallery-span2"
            : "template-company-gallery-span1"
        }`}
      >
        <h1>{promotionDataJson?.eventHeader?.contactDescription} </h1>
        {viewMoreButton()}
      </div>

      <div className="template-company-gallery-row ">
        <div className="template-company-gallery-column">
          {promotionDataJson?.galleryItems?.map((obj, index) => {
            if (index % 2 == 0) {
              return (
                <div key={index}>
                  {obj?.fileType?.startsWith("image") && (
                    <img
                      src={obj?.fileUrl}
                      alt=""
                      className="h-full w-full object-cover"
                      // className="min-w-[264px] md:min-w-[100%] md:w-[100%]  md:h-[100%] aspect-square w-[264px] h-[100%] object-cover "
                    />
                  )}
                  {obj.fileType?.startsWith("video") && (
                    <div
                    // className="min-w-[264px] md:min-w-[100%] md:w-[100%] md:h-[100%] w-[264px] aspect-square h-[100%] object-cover "
                    >
                      <ReactPlayer
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                          youtube: { playerVars: { disablekb: 1 } },
                        }}
                        playing={false}
                        controls={true}
                        width="100%"
                        light={obj?.thumbnailUrl ? obj?.thumbnailUrl : true}
                        height="100%"
                        url={obj?.fileUrl}
                      />
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
        <div className="template-company-gallery-column">
          {promotionDataJson?.galleryItems?.map((obj, index) => {
            console.log("index % 2 == 0", index % 2 == 0);
            if (index % 2 !== 0) {
              return (
                <div key={index}>
                  {obj?.fileType?.startsWith("image") && (
                    <img
                      src={obj?.fileUrl}
                      alt=""
                      className="h-full w-full object-cover"
                      // className="min-w-[264px] md:min-w-[100%] md:w-[100%]  md:h-[100%] aspect-square w-[264px] h-[100%] object-cover "
                    />
                  )}
                  {obj.fileType?.startsWith("video") && (
                    <div
                    // className="min-w-[264px] md:min-w-[100%] md:w-[100%] md:h-[100%] w-[264px] aspect-square h-[100%] object-cover "
                    >
                      <ReactPlayer
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                          youtube: { playerVars: { disablekb: 1 } },
                        }}
                        playing={false}
                        controls={true}
                        width="100%"
                        light={obj?.thumbnailUrl ? obj?.thumbnailUrl : true}
                        height="100%"
                        url={obj?.fileUrl}
                      />
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
