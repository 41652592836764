import React, { useEffect, useState } from "react";
//import Navbar from "../Components/Navbar/Navbar";
import { useNavigate } from "react-router";
import axios from "axios";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";

function PrivacyPolicy() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //let count = 0;
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/privacyPolicy.json");
      setData(response?.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full bg-white">
      <div className="min-h-[80vh] max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
        <div className=" h-[79px] navbar flex items-center">
          <p
            onClick={() => {
              navigate("/");
            }}
            className="underline cursor-pointer text-black "
          >
            Back
          </p>
        </div>
        <div className="p-[40px]">
          {loading ? (
            <div className="h-[50px] w-[50px] mx-auto">
              <LoadingCircle />
            </div>
          ) : (
            <div className="w-full h-full flex flex-col text-[#000]">
              {data &&
                data.privacyPolicy &&
                data.privacyPolicy.map((obj) => {
                  return (
                    <>
                      {obj.heading && (
                        <span
                          className={`mb-[20px]  font-bold text-[18px] lg:text-[24px] first:text-[24px] lg:first:text-[40px]`}
                        >
                          {obj.heading}
                        </span>
                      )}
                      {obj.paragraph && (
                        <span
                          className={`mb-[30px] text-[16px] lg:text-[21px]`}
                        >
                          {obj.paragraph}
                        </span>
                      )}
                    </>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
