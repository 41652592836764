import React, { useEffect } from "react";
import styles from "./LikePopup.module.css";
import LikedAlready from "../../assests/LikedAlready.svg";

const LikePopup = ({ setPopup, backgroundColor, message }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div className={styles.mainDiv}>
      <div className={styles.container}>
        <img
          src={LikedAlready}
          alt=""
          className="w-[56px] h-[56px] mb-[24px]  "
        />
        {/* <p className="text-regular-medium px-[20px] mb-[16px]">
          Option to dislike is disabled
        </p> */}
        <pre className="text-small text-[#CDCDCD] px-[20px]">{message}</pre>
        <div className="seperator my-[20px]" />
        <div className="flex flex-col gap-[16px] w-[100%] cursor-pointer">
          <button
            className={`text-regular-medium ${styles.button}`}
            onClick={() => setPopup(false)}
            style={{
              backgroundColor: backgroundColor,
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default LikePopup;
