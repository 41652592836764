import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template8.module.css";

export default function Template8({
  carousel,
  handleOpenForm,
  handleLinkClick,
  handleOpenDetails,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto">
        {carousel.heading && (
          <div className="flex items-center mb-[16px] w-full">
            <p
              className={` text-[22px] md:text-[32px] leading-[38px]   ${styles.heading}
        ${
          carousel.items.length < 5
            ? "md:text-center md:pl-[0px] pl-[16px] "
            : " pl-[16px] md:pl-0"
        } whitespace-nowrap`}
              style={{ fontFamily: carousel?.font }}
            >
              {carousel.heading}
            </p>
            <div
              className="w-full h-[0.5px] mx-[16px] border-0 border-none opacity-50"
              style={{ background: carousel?.textColor }}
            />
          </div>
        )}
        <div
          className={`h-[100%]  grid grid-cols-2 md:grid-cols-4 px-[16px] md:px-0 gap-[12px] `}
        >
          {carousel &&
            carousel.items &&
            carousel.items.map((add) => {
              return (
                <>
                  <div
                    className="w-full flex flex-col mt-[25px] "
                    onClick={() => {
                      if (add.goto) {
                        if (add.goto === "form") {
                          handleOpenForm(add);
                        } else if (add.goto == "details") {
                          handleOpenDetails(add);
                        } else {
                          handleLinkClick(add);
                        }
                      }
                    }}
                  >
                    {add?.fileType?.startsWith("image") && (
                      <img
                        src={add.fileUrl}
                        alt=""
                        className="w-full object-cover aspect-square"
                      />
                    )}
                    {add.fileType?.startsWith("video") && (
                      <div className="w-full object-cover ">
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                            youtube: { playerVars: { disablekb: 1 } },
                          }}
                          // playing={true}
                          controls={true}
                          width="100%"
                          light={add?.thumbnailUrl ? add?.thumbnailUrl : true}
                          height="100%"
                          url={add?.fileUrl}
                          className={styles.reactPlayer}
                        />
                      </div>
                    )}
                    <div
                      className="w-[100%] text-center cursor-pointer p-[5px]"
                      style={{
                        color: add?.textColor,
                        fontFamily: carousel?.secondaryFont,
                      }}
                    >
                      {add?.shopbuttton && (
                        <div
                          className={styles.button}
                          style={{
                            color: add?.textColor,
                            fontFamily: carousel?.secondaryFont,
                          }}
                        >
                          {add.shopbuttton}
                        </div>
                      )}
                      {add?.tagLine && (
                        <p
                          className={`text_Ell ${styles.tagLine}`}
                          style={{
                            color: add?.textColor,
                            fontFamily: carousel?.secondaryFont,
                          }}
                        >
                          {add.tagLine}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}
