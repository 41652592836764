import React, { useContext, useEffect, useState } from "react";
import ShareNav from "./ShareNav";
import TinyShareGrid from "./TinyShareGrid";
import CertificatePage from "../../Pages/CertificatePage";
import { useLocation } from "react-router";
import PosterPage from "../../Pages/PosterPage";
import Context from "../../context/Context";

function TinySharePage() {
  const location = useLocation();
  const { tinyUrlFromMetaData } = useContext(Context);
  const [certificate, setCertificate] = useState(false);
  const [poster, setPoster] = useState(false);
  const [prmotion, setPromotion] = useState(false);
  const [sharePage, setSharePage] = useState(false);
  const tinyUrl =
    tinyUrlFromMetaData ||
    location.pathname.split("/")[1]?.replace(/\.html$/, "");
  useEffect(() => {
    let certificateLocal = tinyUrl?.startsWith("cert");
    let posterLoacal = tinyUrl?.startsWith("pos");
    let isPromotion = tinyUrl?.startsWith("pro");
    let isSharePage = tinyUrl?.startsWith("vid");
    setCertificate(certificateLocal);
    setPoster(posterLoacal);
    setPromotion(isPromotion);
    setSharePage(isSharePage);
  }, [location.pathname]);

  return (
    <div>
      {certificate && <CertificatePage />}
      {poster && <PosterPage />}
      {(prmotion || sharePage) && <TinyShareGrid />}
    </div>
  );
}

export default TinySharePage;
