import React, { useContext, useEffect, useState } from "react";
import styles from "./WhatsappLike.module.css";
import whatsappLike from "../../assests/whatsappLike.svg";
import singinCloseButton from "../../assests/singinCloseButton.svg";
import copyLinkImage from "../../assests/share/copyLinkBlack.svg";
import copySuccess from "../../assests/share/copySuccessBlack.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";

const WhatsappLike = ({ setPopup, entry, type }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const { userDetailsFromApi } = useUserAuth();
  const { tinyUrlFromMetaData } = useContext(Context);
  const [copied, setCopied] = useState(false);
  const [nameEmpty, setNameEmpty] = useState(false);
  const [name, setName] = useState(userDetailsFromApi?.userName || "");
  const [messageSented, setMessageSented] = useState(false);
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");

  let message = "";
  if (type == "vote") {
    message = name
      ? `#VoteRequest, 
Name:'${entry?.participantName}'. participationId:${tinyUrl}, From:${name}.`
      : `#VoteRequest, 
Name:'${entry?.participantName}'. participationId:${tinyUrl},`;
  } else {
    message = name
      ? `#votingWithValidation, 
Name:'${entry?.participantName}'. participationId_US${userDetailsFromApi?.userId}:${tinyUrl}. From:${name}.`
      : `#votingWithValidation, 
Name:'${entry?.participantName}'. participationId_US${userDetailsFromApi?.userId}:${tinyUrl}.`;
  }

  const handleWhatsappLike = () => {
    if (!name?.replace(/\s/g, "")) {
      setNameEmpty(true);
      return;
    }
    let phoneNumber = "+918618406454";
    const encodedContent = encodeURIComponent(message);
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobile) {
      const url = `https://wa.me/${phoneNumber}?text=${encodedContent}`;
      window.open(url, "_blank");
    } else {
      const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodedContent}`;
      window.open(url, "_blank");
    }
    setTimeout(setMessageSented(true), 2000);
  };
  function copyLink() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, [1000]);
    navigator.clipboard.writeText(message);
    window.document.execCommand("copy");
  }
  return (
    <div className={styles.mainDiv}>
      <div className={styles.container}>
        <div className="w-[100%] flex justify-end p-[10px] cursor-pointer">
          <img
            src={singinCloseButton}
            onClick={() => {
              setPopup(false);
              // setReqForLike(false);
            }}
          />
        </div>

        <h3 className="text-regular-medium mb-[14px]">Vote via Whatsapp</h3>
        {/* <p className="text-small text-opacity-80 mb-[25px]">
          No OTP or login, just send us a message via Whatsapp to our business
          account to vote this entry!
        </p> */}
        <p className="text-small text-opacity-80 mb-[25px]">
          Enter your name, click 'Vote via WhatsApp,' and send the message to
          cast your vote!
        </p>
        <div className="mb-[28px]">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            className="text-regular-callout "
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          {nameEmpty && (
            <div className="text-small text-left w-[100%] mb-[12px]">
              <span className="text-red-600">* </span>
              Enter your name
            </div>
          )}
        </div>
        {/* <div className="w-full">
          <p className="text-small text-center mb-[5px]">
            Copy and sent below text to Whatsapp number +918618406454
          </p>
          <div className="w-full">
            <div
              className="flex flex-row items-center w-full justify-between gap-[5px] cursor-pointer w-[100%] p-[10px] border-[1px] border-slate-400 rounded-[8px]"
              onClick={copyLink}
              tooltip="Copy to clipboard"
            >
              <p className="text-small break-words text-[#858585] w-[90%]">
                {message}
              </p>
              <div className="w-[10%]">
                {copied ? (
                  <img src={copySuccess} className="w-[20px] h-[20px]" />
                ) : (
                  <img src={copyLinkImage} className="w-[20px] h-[20px]" />
                )}
              </div>
            </div>
          </div>
        </div>
        <p className="text-regular my-[20px]">OR</p> */}
        {messageSented ? (
          <p className="text-small text-opacity-80 mb-[25px] flex items-center gap-[5px]">
            <img src={copySuccess} alt="" className="w-[36px] h-[36px]" />
            Your vote will be reflected soon in the participation
          </p>
        ) : (
          <div className={`px-[20px] ${styles.whatsappLogin}`}>
            <button
              className={`${styles.button} text-regular-medium bg-[#7AC280]   flex items-center justify-center gap-[14px] drop-shadow-lg`}
              onClick={handleWhatsappLike}
            >
              <img src={whatsappLike} alt="" className="h-[24px] w-[24px]" />
              {/* Take me to Whatsapp */}
              Vote via WhatsApp
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhatsappLike;
