import React from "react";
import styles from "./ProgressBand.module.css";

export default function ProgressBand({ value }) {
  const progressStyle = {
    width: `${(value / 100) * 100}%`,
    height: "5px",
    background: `linear-gradient(89.99deg, #daff48 0%, #fcff4b 19.79%, #e788ff 82.75%, #ff8989 100%)`,
  };
  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={progressStyle}></div>
    </div>
  );
}
