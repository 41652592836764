import ReactPlayer from "react-player";
import styles from "./PromotorsDetials.module.css";
import { useContext } from "react";
import Context from "../../context/Context";
import DanceTemplatePromotors from "../PromotionTemplate/DanceTemplate/DanceTemplatePromotors";

export default function PromotorsDetials({
  promotionDataJson,
  setServiceForm,
  handleClickCounterPromotion,
}) {
  const { setGallaryClick } = useContext(Context);
  const handleOpenForm = (promotionData) => {
    setServiceForm(true);
    handleClickCounterPromotion(
      "leads",
      promotionData?._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  let colors = promotionDataJson?.themeColors;
  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex?.slice(1, 3), 16);
    const g = parseInt(hex?.slice(3, 5), 16);
    const b = parseInt(hex?.slice(5, 7), 16);
    if (hex) {
      return `drop-shadow(rgba(${r}, ${g}, ${b}, ${alpha})
       4px 4px 0px)`;
    } else {
      return "";
    }
  };
  const viewMoreButton = () => {
    if (promotionDataJson?.contactUrl == "form") {
      return (
        <button
          onClick={() => handleOpenForm(promotionDataJson)}
          style={{
            background: colors?.button1,
            color: colors?.text2,
            filter: hexToRGBA(colors?.button1, 0.6),
          }}
        >
          View More
        </button>
      );
    } else {
      return (
        <a href={promotionDataJson?.contactUrl} target="_blank">
          <button
            style={{
              background: colors?.button1,
              color: colors?.text2,
              filter: hexToRGBA(colors?.button1, 0.6),
            }}
          >
            View More
          </button>
        </a>
      );
    }
  };

  let type = promotionDataJson?.templateType;
  let promotionPageJSX;
  switch (type) {
    case "DANCE":
      promotionPageJSX = (
        <DanceTemplatePromotors promotionDataJson={promotionDataJson} />
      );
      break;
    default:
      promotionPageJSX = (
        <div
          className="bg-[#001254] w-full "
          style={{ background: colors?.supporting }}
        >
          <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] pb-[32px] md:pb-[140px] pt-[32px] md:pt-[60px] mx-auto">
            <div className={styles.container}>
              <div className={styles.details}>
                <div className="flex gap-[16px] md:gap-[24] items-start md:items-center">
                  <img
                    src={promotionDataJson?.promotionLogo}
                    alt=""
                    className=""
                  />
                  <div>
                    <h1 style={{ color: colors?.text1 }}>
                      {promotionDataJson?.contactTitle}
                    </h1>
                    <p
                      className="max-w-[406px] md:mt-[12px]"
                      style={{ color: colors?.text1 }}
                    >
                      {promotionDataJson?.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.gallery}>
                {promotionDataJson?.galleryItems?.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        promotionDataJson?.galleryItems?.length > 3
                          ? styles.gridItems4
                          : ""
                      }`}
                    >
                      {obj?.fileType?.startsWith("image") && (
                        <img
                          src={obj?.fileUrl}
                          alt=""
                          className="h-full w-full object-cover"
                          onClick={() =>
                            obj?.type == "product_purchase" &&
                            setGallaryClick(obj)
                          }
                          // className="min-w-[264px] md:min-w-[100%] md:w-[100%]  md:h-[100%] aspect-square w-[264px] h-[100%] object-cover "
                        />
                      )}
                      {obj.fileType?.startsWith("video") && (
                        <div
                          className="h-full w-full object-cover"
                          // className="min-w-[264px] md:min-w-[100%] md:w-[100%] md:h-[100%] w-[264px] aspect-square h-[100%] object-cover "
                        >
                          <ReactPlayer
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload",
                                },
                              },
                              youtube: { playerVars: { disablekb: 1 } },
                            }}
                            // playing={true}
                            controls={true}
                            width="100%"
                            light={obj?.thumbnailUrl ? obj?.thumbnailUrl : true}
                            height="100%"
                            url={obj?.fileUrl}
                            className={styles.reactPlayer}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}

                {promotionDataJson?.contactUrl && (
                  <div
                    className={`${styles.more} ${
                      promotionDataJson?.galleryItems?.length % 2 == 0
                        ? styles.span2
                        : styles.span1
                    }`}
                  >
                    <h1 style={{ color: colors?.text1 }}>
                      Excited to see more from{" "}
                      {promotionDataJson?.eventHeader?.promoterName}
                    </h1>
                    {viewMoreButton()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
  }
  return <>{promotionPageJSX}</>;
}
