import React, { useEffect, useState } from "react";
import styles from "./ParticipationCertificate.module.css";
import closebutton from "../../assests/closebutton.svg";
import { useNavigate } from "react-router";
import ShareInShare from "../Share/ShareInShare";
import { useUserAuth } from "../../context/UserAuthContext";

export default function ParticipationCertificate({
  campaignTemplateData,
  handleReport,
  reportLoading,
}) {
  const [imageLoadError, setImageLoadError] = useState(false);
  const [enter, setEnter] = useState(false);
  const [share, setShare] = useState(false);
  const { currentUser } = useUserAuth();

  const navigate = useNavigate();
  const handleImageLoadError = () => {
    setImageLoadError(true);
  };
  useEffect(() => {
    setEnter(true);
  }, []);
  const loggedInUserId = localStorage?.getItem("userId");
  const tinyUrl = window.location.pathname.split("/")[1]?.replace(/\.html$/, "");;

  const userId = tinyUrl.split("u").pop().split("o")[0];
  const orgId = tinyUrl.split("o").pop().split("cp")[0];
  const campaignId = tinyUrl.split("cp").pop().split("cn")[0];
  const contestId = tinyUrl.split("cn").pop().split("e")[0];
  const entryId = tinyUrl.split("e").pop();

  let data = { userId, orgId, campaignId, contestId, entryId };
  const imageUrl = `  https://storage.googleapis.com/goviral_certificates/${userId}/${orgId}/${campaignId}/${contestId}/${entryId}/certificate.png`;

  const handleDownload = (e) => {
    fetch(imageUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "certificate.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    let shortUrl = "";
    if (loggedInUserId) {
      shortUrl =
        "vidu" +
        loggedInUserId +
        "o" +
        orgId +
        "cp" +
        campaignId +
        "cn" +
        contestId +
        "e" +
        entryId;
    } else {
      shortUrl =
        "vidu" +
        userId +
        "o" +
        orgId +
        "cp" +
        campaignId +
        "cn" +
        contestId +
        "e" +
        entryId;
    }

    navigate(`/${shortUrl}`);
  };
  const handleShare = () => {
    setShare((prevState) => !prevState);
  };
  const contestDetailsBg = {
    background: `linear-gradient(42deg, ${campaignTemplateData?.color1}fa 0%, ${campaignTemplateData?.color2} 76.12%)`,
  };

  return (
    <>
      {share && <ShareInShare showShare={handleShare} fullData={data} />}
      <div className={styles.container}>
        <div className={`${styles.parentDiv} ${enter ? styles.enter : ""}`}>
          <div className="flex justify-between items-center h-full w-[100%] px-[20px]">
            <p className="text-regular-bold text-[#CDCDCD]"></p>
            <div
              onClick={handleClose}
              className="bg-[#252525] p-[12px] rounded-full"
            >
              <img
                src={closebutton}
                className=" hover:cursor-pointer w-[15px] h-[17px]"
              />
            </div>
          </div>
          <div className=" w-full h-[60vh] max-h-[340px] md:max-h-[530px] ">
            <div className="w-full h-full flex items-center justify-center overflow-hidden">
              {!imageLoadError ? (
                <img
                  src={imageUrl}
                  className="w-auto h-full object-contain"
                  onError={handleImageLoadError}
                />
              ) : (
                <div className="py-[20px] gap-[12px] flex flex-col items-center">
                  <svg
                    height="40px"
                    version="1.1"
                    viewBox="0 0 32 32"
                    width="40px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                    >
                      <g fill="#ffffff" id="icon-138-certificate">
                        <path
                          d="M13,19.9494914 L13,22 L25.9975383,22 C26.5511826,22 27,21.5510798 27,20.992516 L27,8.00748397 C27,7.45106594 26.5605417,7 25.9975383,7 L6.00246167,7 C5.44881738,7 5,7.44892021 5,8.00748397 L5,20.992516 C5,21.5489341 5.43945834,22 6.00246167,22 L8,22 L8,19.9494914 C7.38140648,19.3182229 7,18.4536526 7,17.5 C7,15.5670033 8.56700328,14 10.5,14 C12.4329967,14 14,15.5670033 14,17.5 C14,18.4536526 13.6185935,19.3182229 13,19.9494914 L13,19.9494914 L13,19.9494914 Z M9,20.6631845 L9,24.5999756 L10.5,23.1000061 L12,24.5999756 L12,20.6631845 C11.5453723,20.8791545 11.0367987,21 10.5,21 C9.96320134,21 9.45462768,20.8791545 9,20.6631845 L9,20.6631845 L9,20.6631845 Z M8,23 L6.00494659,23 C4.89702623,23 4,22.104522 4,20.9998938 L4,8.00010618 C4,6.89005841 4.89764516,6 6.00494659,6 L25.9950534,6 C27.1029738,6 28,6.89547804 28,8.00010618 L28,20.9998938 C28,22.1099416 27.1023548,23 25.9950534,23 L13,23 L13,27 L10.5,24.5 L8,27 L8,23 L8,23 Z M7,11 L7,12 L25,12 L25,11 L7,11 L7,11 Z M16,14 L16,15 L25,15 L25,14 L16,14 L16,14 Z M19,17 L19,18 L25,18 L25,17 L19,17 L19,17 Z M10.5,20 C11.8807119,20 13,18.8807119 13,17.5 C13,16.1192881 11.8807119,15 10.5,15 C9.11928806,15 8,16.1192881 8,17.5 C8,18.8807119 9.11928806,20 10.5,20 L10.5,20 Z"
                          id="certificate"
                        />
                      </g>
                    </g>
                  </svg>
                  <p>Certificate yet to be issued</p>
                  {currentUser && (
                    <button
                      className={styles.reportButton}
                      onClick={handleReport}
                      disabled={reportLoading}
                    >
                      {reportLoading ? "Reported" : "Report"}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="px-[20px] pt-[20px] w-full flex gap-[10px] justify-between ">
            {!imageLoadError && (
              <button className={styles.button} onClick={handleClose}>
                View Entry
              </button>
            )}
            <div className="flex gap-[10px] flex-wrap justify-end">
              {!imageLoadError && (
                <button className={styles.button} onClick={handleShare}>
                  Share
                </button>
              )}
              {!imageLoadError && (
                <button className={styles.button} onClick={handleDownload}>
                  Download
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
