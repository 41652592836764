import React from "react";
import styles from "./styles/QueueeasyHomepage.module.css";
import logo from "../assests/longLogo.png";
import QUseCases from "../Components/QUseCases/QUseCases";
import { Link } from "react-router-dom";

export default function QueueeasyHomepage() {
  const data = {
    heading: "Skip the Long Queues with Queueeasy ",
    description:
      "Standing in long queues, whether for cinema tickets, food orders, or temple offerings, can be one of the most unproductive things in the world. But now, Queueeasy is here to simplify your temple experience by eliminating the need to wait in long lines for offerings",
    graphics:
      "https://storage.googleapis.com/home_page_data/medias/graphics.png",
    uses: [
      {
        heading: "Queueeasy for Temples",
        description:
          "Simplify Your Temple Experience with Queueeasy\nBook offerings online, skip the queues, and focus on your spiritual journey.",
        steps: [
          {
            heading: "Scan QR/Open Weblink",
            image:
              "https://storage.googleapis.com/home_page_data/medias/step1.png",
          },
          {
            heading: "Open weblink and book offering",
            image:
              "https://storage.googleapis.com/home_page_data/medias/step2.png",
          },
          {
            heading: "Take the receipt got in whatsapp as PDF to temple ",
            image:
              "https://storage.googleapis.com/home_page_data/medias/step3.png",
          },
        ],
      },
    ],
  };
  return (
    <div className="bg-white">
      <div className={styles.header}>
        <div className="max-w-[1280px] py-[20px] w-full px-[20px] lg:px-[78px] mx-auto ">
          <img src={logo} />
        </div>
      </div>
      <div className={styles.landing}>
        <div
          className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto grid grid-cols-1 sm:grid-cols-2 bg-[#ecf1f8] rounded-[24px] md:rounded-[50px]"
          // style={{
          //   backgroundColor: data?.bgColor,
          //   backgroundImage: `url(${data?.graphics})`,
          //   backgroundPosition: "calc(100% - 20px) calc(100% - 20px)",
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "contain",
          // }}
        >
          <div className="h-full flex flex-col justify-center md:pb-[35px] pt-[20px] md:pt-[54px]">
            <h1 className="mb-[12px] sm:mb-[16px]">{data?.heading}</h1>
            <p className="mb-[24px]">{data?.description}</p>
          </div>
          <div className="flex justify-end gap-[27px] md:pb-[35px] md:pt-[54px]">
            <img
              src={data?.graphics}
              className=" h-full aspect-square h-[50vh] object-contain"
            />
          </div>
        </div>
      </div>
      <div>
        {data?.uses?.map((obj) => (
          <QUseCases data={obj} />
        ))}
      </div>
      <div className={styles.footer}>
        <div className="max-w-[1280px] py-[20px] w-full px-[20px] lg:px-[78px] mx-auto ">
          <div className="flex flex-col md:flex-row gap-[50px] ">
            <div className="md:w-[30%]">
              <img src={logo} className="mb-[30px]" />
              <p>
                simplify setup on your place and visitors or customers can
                easily book a slot from anywhere. Queueeasy can end queues for
                ever
              </p>
            </div>
            <div className="flex gap-[40px] flex-col md:flex-row">
              <div className="shrink-0">
                <h3>Queueeasy</h3>
                {/* <button>About Us</button> */}
                <Link to="/pricing">
                  <button>Pricing</button>
                </Link>

                {/* <button>Services</button> */}
                {/* <button>Supports</button> */}
              </div>
              {/* <div className="shrink-0">
                <h3>Our Company</h3>
                <button>About Us</button>
                <button>Mission</button>
                <button>Story</button>
                <button>Contact Us</button>
              </div> */}
              <div className="shrink-0">
                <h3>Policy</h3>
                <Link to="/privacypolicy">
                  <button>Privacy Policy</button>
                </Link>
                <Link to="/refund">
                  <button>Cancellation and Refund Policy</button>
                </Link>
                <Link to="/shipping">
                  <button>Shipping and Delivery Policy</button>
                </Link>
                <Link to="/tandc">
                  <button>Terms and Conditions</button>
                </Link>
              </div>
            </div>
          </div>
          <p className={styles.copyright}>© 2024 . All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
