import React, { useEffect } from "react";
import styles from "./UploadPopup.module.css";
import Uploader from "../../Components/lottie/lottie/loading";
import PopupWrapper from "../../Layout/PopupWrapper";

const UploadPopup = ({ progress, message }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <PopupWrapper>
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          <Uploader progress={progress} message={message} />
        </div>
      </div>
    </PopupWrapper>
  );
};

export default UploadPopup;
