import React from "react";
import uploadanimation from "./upload-animation.json";
import Lottie from "react-lottie-player";
import ProgressBand from "../../ProgressBand/ProgressBand";
import { isEmpty } from "lodash";
function loading({ progress, message }) {
  return (
    <>
      <div className="flex items-center justify-center h-[100vh] w-[100%] flex-col">
        <Lottie
          loop
          animationData={uploadanimation}
          play
          style={{ width: 173, height: 173 }}
        />
        <p className="text-black text-regular mb-[10px]">
          {message ||
            "Registration in progress- Please wait , Proceeding to Payment ."}
        </p>
        <p className="text-black w-[300px] text-small  text-center">
          Your registration may take some time to finish, please don't close the
          window or refresh
        </p>
        {!isEmpty(progress) &&
          progress?.map((individualFile, index) => {
            return (
              <div className="w-full px-[20px]">
                <div className="mt-[22px]">
                  <p className="text-regular mb-[10px] text-black">
                    Upload File {index + 1}
                  </p>
                  <ProgressBand value={individualFile} />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default loading;
