import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template9.module.css";

export default function Template9({
  carousel,
  handleOpenForm,
  handleOpenDetails,
  handleLinkClick,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto">
        {carousel.heading && (
          <div className="flex items-center mb-[16px] w-full">
            <p
              className={` text-[22px] md:text-[32px] leading-[38px]   ${styles.heading}
             pl-[16px] md:pl-0 whitespace-nowrap`}
              style={{ fontFamily: carousel?.font }}
            >
              {carousel.heading}
            </p>
            <div
              className="w-full h-[0.5px] mx-[16px] border-0 border-none opacity-50"
              style={{ background: carousel?.textColor }}
            />
          </div>
        )}
        <div
          className={`flex flex-wrap box  md:gap-[20px] pr-[16px] h-full md:grid md:grid-cols-2  pl-[16px] md:pl-0`}
        >
          {carousel &&
            carousel?.items &&
            carousel?.items.map((add, index) => {
              return (
                <div className="w-full">
                  <div
                    className=" max-h-[200px] md:max-h-[400px] w-full flex flex-row shrink-0"
                    onClick={() => {
                      if (add.goto) {
                        if (add.goto === "form") {
                          handleOpenForm(add);
                        } else if (add.goto == "details") {
                          handleOpenDetails(add);
                        } else {
                          handleLinkClick(add);
                        }
                      }
                    }}
                  >
                    {add?.fileType?.startsWith("image") && (
                      <img
                        src={add.fileUrl}
                        alt=""
                        className="min-w-[190px] md:min-w-[10px] md:w-[50%] w-[190px] md:h-auto  h-[190px] object-cover rounded-[5px] shrink-0"
                      />
                    )}
                    {add.fileType?.startsWith("video") && (
                      <div className="min-w-[190px] md:min-w-[10px] md:w-[50%] w-[190px] md:h-auto  h-[190px] object-cover ">
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                            youtube: { playerVars: { disablekb: 1 } },
                          }}
                          // playing={true}
                          controls={true}
                          width="100%"
                          light={add?.thumbnailUrl ? add?.thumbnailUrl : true}
                          height="100%"
                          url={add?.fileUrl}
                          className={styles.reactPlayer}
                        />
                      </div>
                    )}

                    <div className="flex flex-col justify-between pl-[8px] py-[5px] w-full">
                      <div className={add?.description ? "" : "my-auto"}>
                        <p
                          className="text-[16px] md:text-[24px] font-semibold tracking-[-0.32px] uppercase leading-[140%]"
                          style={{
                            color: add?.textColor,
                            fontFamily: carousel?.secondaryFont,
                          }}
                        >
                          {add.shopbuttton}
                        </p>
                        <p
                          className="text-[16px] md:text-[18px] leading-[140%] tracking-[-0.48px] opacity-75"
                          style={{
                            color: add?.textColor,
                            fontFamily: carousel?.secondaryFont,
                          }}
                        >
                          {add.shopbutttonDescription}
                        </p>
                      </div>
                      {add?.description && (
                        <div
                          className="text-[12px] md:text-[16px] leading-[140%]  w-full"
                          style={{
                            color: add?.textColor,
                            fontFamily: carousel?.secondaryFont,
                          }}
                        >
                          <p className=" text_4Line opacity-75 ">
                            {add.description}
                          </p>
                          <p className=" flex items-baseline gap-[2px] w-fit font-semibold">
                            Read More
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="4"
                              height="7"
                              viewBox="0 0 4 7"
                              fill="none"
                            >
                              <path
                                d="M0.165111 0.155358C0.382567 -0.0517864 0.735166 -0.0517864 0.952617 0.155358L3.67418 2.75052C4.10875 3.16486 4.10858 3.83632 3.67384 4.2505L0.950613 6.84463C0.733162 7.05179 0.380563 7.05179 0.163101 6.84463C-0.0543669 6.63747 -0.0543669 6.30161 0.163101 6.09445L2.49387 3.87414C2.71138 3.66699 2.71138 3.33111 2.49387 3.12396L0.165111 0.905532C-0.0523568 0.698388 -0.0523568 0.362555 0.165111 0.155358Z"
                                fill="white"
                              />
                            </svg>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${
                      carousel?.items?.length == index + 1 ? "hidden" : ""
                    } w-full py-[20px] md:hidden`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="357"
                      height="2"
                      viewBox="0 0 357 2"
                      fill="none"
                    >
                      <path
                        d="M1 1H356.013"
                        stroke="#595959"
                        stroke-opacity="0.9"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
