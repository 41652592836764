import React, { useState } from "react";
import styles from "./PriceBox.module.css";
import { isEmpty } from "lodash";
import axios from "axios";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

export default function PriceBox({ data, formValues, setFormValues }) {
  const [loading, setLoading] = useState(false);
  const submitFormApi = async () => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://asia-south1-queueeasy.cloudfunctions.net/users/api/queueeasy/users/test/order_pay`,
      };
      const submitPay = await axios(config);
      if (submitPay?.data?.status == "success") {
        let paymentRedirectUrl = submitPay?.data?.paymentRedirectUrl;
        let url = submitPay?.data?.paymentUrl;
        handleRedirectToLink(url, paymentRedirectUrl);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.queueeasy.com/thirdpartyPayment";
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  }
  return (
    <div
      className={`w-full max-w-[600px] rounded-[16px] border-[1px] border-[#4E04C6] p-[16px] shrink-0 flex flex-col cursor-pointer ${styles.priceWrapper}`}
      onClick={() => {
        setFormValues({
          ...formValues,
          packDetails: {
            packName: data?.packName,
            type: data?.type,
          },
        });
      }}
    >
      {!isEmpty(formValues) && (
        <div className="flex items-center">
          <input
            type="radio"
            name="packDetails"
            checked={
              formValues?.packDetails &&
              formValues?.packDetails["type"] == data?.type
            }
          />
          <p>{data?.packName}</p>
        </div>
      )}
      <div className="w-[100%]  mt-[32px] mb-[20px]">
        <h4 className="mb-[16px]">{data?.heading}</h4>
        <p className={styles.packDescription}>{data?.subTitle}</p>
      </div>
      {!isEmpty(data?.rules) && (
        <div>
          <p className={styles.packDescription}>Features</p>
          <ul className="mt-[8px]">
            {data?.rules?.map((el) => {
              return <li className={styles.packFeatures}>{el}</li>;
            })}
          </ul>
        </div>
      )}
      {!isEmpty(data?.extraCost) && (
        <div className="mt-[20px]">
          <p className={styles.packDescription}>Extra Costs</p>
          <ul className="mt-[8px]">
            {data?.extraCost?.map((el) => {
              return <li className={styles.packFeatures}>{el}</li>;
            })}
          </ul>
        </div>
      )}
      <div className="flex items-center justify-between">
        <h4 className="mt-[16px]">{data?.price}</h4>
        <button
          className={styles.button}
          onClick={submitFormApi}
          disabled={loading}
        >
          {loading ? <LoadingCircle /> : "Pay Now"}
        </button>
      </div>
    </div>
  );
}
