import React from "react";
import "./LoadingCircle.css";

export default function LoadingCircle() {
  return (
    <div className="loading">
      <div className="loading-circle"></div>
    </div>
  );
}
