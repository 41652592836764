import { ContextProvider } from "./context/Context";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import SignupPage from "./Pages/SignupPage";
import "./App.css";
import TinySharePage from "../src/Components/Share/TinySharePage";
import { Route, Routes } from "react-router-dom";

import ChatForHelp from "./Components/HelpButton/ChatForHelp";
import CertificatePage from "./Pages/CertificatePage";
import InstallButton from "./Components/InstallButton/InstallButton";
import InstallPage from "./Pages/InstallPage/InstallPage";
import RegistrationSuccess from "./Pages/RegistrationSuccess";
import RegistrationDetailsPage from "./Pages/RegistrationDetailsPage";
import RegistrationListingPage from "./Pages/RegistrationListingPage";
import PurchaseListingPage from "./Pages/PurchaseListingPage";
import PurchaseDetailsPage from "./Pages/PurchaseDetailsPage";
import MetaDataLoader from "./utils/MetaDataLoader";
import PaymentThirdParty from "./Pages/PaymentThirdParty";
import QueueeasyHomepage from "./Pages/QueueeasyHomepage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Refund from "./Pages/Refund";
import Shipping from "./Pages/Shipping";
import TandC from "./Pages/TandC";
import PricingPage from "./Pages/PricingPage";
function App() {
  return (
    <div className="App ">
      <div>
        <UserAuthContextProvider>
          <ContextProvider>
            {/* <ChatForHelp /> */}
            {/* {<InstallButton />} */}
            <Routes>
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/" element={<QueueeasyHomepage />} />
              <Route
                path="/:tinyUrl"
                element={<MetaDataLoader Component={TinySharePage} />}
              />
              <Route
                path="/:tinyUrl/payment/:regId"
                element={<MetaDataLoader Component={RegistrationSuccess} />}
              />
              <Route
                path="/:tinyUrl/details"
                element={<MetaDataLoader Component={RegistrationListingPage} />}
              />
              <Route
                path="/:tinyUrl/details/:regId"
                element={<MetaDataLoader Component={RegistrationDetailsPage} />}
              />
              <Route
                path="/:tinyUrl/purchase"
                element={<MetaDataLoader Component={PurchaseListingPage} />}
              />
              <Route
                path="/:tinyUrl/purchase/:regId"
                element={<MetaDataLoader Component={PurchaseDetailsPage} />}
              />
              <Route
                path="/:tinyUrl/certificate"
                element={<CertificatePage />}
              />
              <Route path="/install" element={<InstallPage />} />
              <Route
                path="/thirdpartyPayment"
                element={<PaymentThirdParty />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refund" element={<Refund />} />
              <Route path="/shipping" element={<Shipping />} />
              <Route path="/tandc" element={<TandC />} />
              <Route path="/pricing" element={<PricingPage />} />
            </Routes>
          </ContextProvider>
        </UserAuthContextProvider>
      </div>
    </div>
  );
}

export default App;
