import React, { useEffect, useState } from "react";
import styles from "./CouponCart.module.css";

export default function CopyCoupons({ setCopied }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 h-[100%] w-[100%] z-[102] p-[20px] ${
        styles.popupBackground
      } overflow-y-auto no-scrollbar transform transition-opacity ease-in-out duration-300
      ${show ? "opacity-1" : "opacity-0"}`}
    >
      <div className={styles.copyCoupons}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="55"
          viewBox="0 0 55 55"
          fill="none"
        >
          <path
            d="M27.5 0C12.3475 0 0 12.3475 0 27.5C0 42.6525 12.3475 55 27.5 55C42.6525 55 55 42.6525 55 27.5C55 12.3475 42.6525 0 27.5 0ZM40.645 21.175L25.0525 36.7675C24.6675 37.1525 24.145 37.3725 23.595 37.3725C23.045 37.3725 22.5225 37.1525 22.1375 36.7675L14.355 28.985C13.5575 28.1875 13.5575 26.8675 14.355 26.07C15.1525 25.2725 16.4725 25.2725 17.27 26.07L23.595 32.395L37.73 18.26C38.5275 17.4625 39.8475 17.4625 40.645 18.26C41.4425 19.0575 41.4425 20.35 40.645 21.175Z"
            fill="#55A226"
          />
        </svg>
        <h3>Coupon Codes Copied!</h3>
        <button onClick={() => setCopied(false)}>OK</button>
      </div>
    </div>
  );
}
