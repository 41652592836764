import React, { useEffect, useState } from "react";
import PriceBox from "../Components/PriceBox/PriceBox";
import axios from "axios";
import styles from "./styles/PricingPage.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function PricingPage() {
  const [priceDetails, setPriceDetails] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadPrice();
  }, []);

  const loadPrice = async () => {
    try {
      const data = await axios.get(`/packDetails.json`);
      setPriceDetails(data?.data?.pack);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="bg-white w-[100%] min-h-[60vh]">
      <div className="max-w-[1280px] mx-[auto] py-[30px] px-[20px] md:px-[80px]">
        <div className=" h-[79px] navbar flex items-center">
          <p
            onClick={() => {
              navigate("/");
            }}
            className="underline cursor-pointer text-black"
          >
            Back
          </p>
        </div>
        <div className="mt-[20px] flex gap-[10px] flex-wrap overflow-auto">
          {priceDetails?.map((obj) => (
            <PriceBox data={obj} />
          ))}
        </div>
      </div>
    </div>
  );
}
