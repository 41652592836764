import axios from 'axios'

//Checking Likes For a video
// export const LikeChecker = async (
//   campaignId,
//   contestId,
//   videoId,
//   token,
//   likeState,
//   setLikeState,
//   likesCount,
//   setLikesCount,
// ) => {
//   try {
//     let config1 = {
//       method: 'post',
//       url: `${baseUrl}/festivityVideoLikes/api/festivityday/videoLikes/check/${campaignId}/${contestId}/${videoId}`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//     }
//     const response1 = await axios(config1)
//     setLikeState(response1.data)
//   } catch (error) {
//     console.log('error', error)
//   }
// }

//Posting view For a video
export const ViewReporter = async (
  campaignId,
  contestId,
  videoId,
  token,
  organiserId,
) => {
  try {
    let config2 = {
      method: 'post',
      url: `${process.env.REACT_APP_VIEW}/${organiserId}/${campaignId}/${contestId}/${videoId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
    await axios(config2)
  } catch (error) {
    console.log('error', error)
  }
}

//Handle like of the video
export const LikeHandle = async (
  campaignId,
  contestId,
  videoId,
  token,
  likeState,
  setLikeState,
  likesCount,
  setLikesCount,
) => {
  try {
    let config3 = {
      method: 'post',
      url: `${process.env.REACT_APP_LIKE}/${campaignId}/${contestId}/${videoId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
    await axios(config3)
    likeState === true ? setLikeState(false) : setLikeState(true)
    likeState === true
      ? setLikesCount(likesCount - 1)
      : setLikesCount(likesCount + 1)
  } catch (error) {
    console.log('error', error)
  }
}

//for single profile file
export const getSingleProfieFile = async (
  campaignId,
  contestId,
  videoId,
  token,
  orgId,
) => {
  try {
    let cancel
    let config2 = {
      method: 'GET',
      url: `${process.env.REACT_APP_SINGLE_PROFILE_ENTRY}/${orgId}/${campaignId}/${contestId}/${videoId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    }
    const response = await axios(config2)
    return response.data.data[0]
  } catch (error) {
    console.log('error', error)
  }
}

//for single profile file
export const deleteProfileVideo = async (
  campaignId,
  contestId,
  videoId,
  token,
  profileContent,
  setShowPreview,
  setShowDelete,
  orgId,
  setDeleteLoading,
) => {
  try {
    setDeleteLoading(true)
    let cancel
    let config4 = {
      method: 'PATCH',
      url: `${process.env.REACT_APP_PROFILE_DELETE_ENTRY}/${orgId}/${campaignId}/${contestId}/${videoId}
      `,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    }
    const response4 = await axios(config4)
    if (response4.status === 200) {
      const deletionIndex = await profileContent.findIndex(
        (el) => el.videoId === videoId,
      )
      if (deletionIndex > -1) {
        await profileContent.splice(deletionIndex, 1)
        setDeleteLoading(false)
        setShowPreview(false)
        setShowDelete(false)
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}
