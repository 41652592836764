import styles from "./styles/PopUp.module.css";
// import Right from "../../assests/leftme.svg";
// import Left from "../../assests/right.svg";
// import close from "../../assests/close.svg";
import ReactPlayer from 'react-player'


function PopUp({ indexNumber, mixture, onTouchStart, onTouchMove, onTouchEnd, popnowImgOrVideo, setpopnow, handleNext, handlePrevious }) {
    return (
        <div className={styles.popup}>
            <div
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                className={styles.imgmodel}>
                {/* {popnowImgOrVideo?.fileType === "video" && <ReactPlayer
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    playing={true}
                    controls={true}
                    // muted={true}
                    // className={styles.videoplayback}
                    url={popnowImgOrVideo?.fileUrl}
                />}
                {popnowImgOrVideo?.fileType === "image" && <img className={styles.theimg} src={mixture + popnowImgOrVideo?.fileUrl} alt="" />} */}
                <img src="https://plus.unsplash.com/premium_photo-1661499507227-4f2cacb1b8c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" alt="" />
            </div>
            {/* <img className={styles.previous} onClick={() => handleNext(indexNumber)} src={Right} alt="" />
            <img className={styles.next} onClick={() => handlePrevious(indexNumber)} src={Left} alt="" /> */}
        </div>
    );
}

export default PopUp;