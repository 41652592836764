import React, { useEffect, useState } from "react";
import styles from "./styles/PosterGenerated.module.css";
import downloadIcon from "../assests/posterCreation/downloadIcon.svg";

import facebook from "../assests/share/fbLogo.svg";
import whatsapp from "../assests/share/whatsApp.svg";
import twitter from "../assests/posterCreation/twitterX.svg";
import email from "../assests/share/email.svg";
import axios from "axios";

function PosterPage() {
  const tinyUrl = window.location.pathname.split("/")[1]?.replace(/\.html$/, "");;
  const occasion = tinyUrl.split("posb").pop().split("fn")[0];
  const generatedImageName = tinyUrl.split("fn").pop();
  const [copied, setCopied] = useState(false);
  let generatedPosterUrl = `https://storage.googleapis.com/goviral_posters/${occasion}/${generatedImageName}.jpg`;
  let shareUrl = window.location.href;
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
    getCampaigns();
  }, []);
  const getCampaigns = async () => {
    const data = await axios.get(
      `https://storage.googleapis.com/goviral_posters/campaigns.json`
    );
    setCampaigns(data?.data?.campaigns);
  };

  const downloadImage = (e) => {
    fetch(generatedPosterUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.jpeg"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const whatsapplink = () => {
    window.open("whatsapp://send?text= " + shareUrl);
  };
  const facebooklink = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`);
    return false;
  };
  const shareToTwitter = () => {
    window.open(`http://twitter.com/share?url=${shareUrl}`);
  };
  const shareToEmail = () => {
    window.location.href = `mailto:?body=${shareUrl}`;
  };

  function shareLink() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, [1000]);
    navigator.clipboard.writeText(shareUrl);
    window.document.execCommand("copy");
    // alert(shareUrl + "\n copied to clipboard")
  }

  const buttonStyle = (cardColour) => {
    const rgbaColor = `rgba(${parseInt(
      cardColour?.slice(1, 3),
      16
    )}, ${parseInt(cardColour?.slice(3, 5), 16)}, ${parseInt(
      cardColour?.slice(5, 7),
      16
    )}, 0.6)`;
    const buttonStyle = {
      backgroundColor: cardColour,
      filter: `drop-shadow(4px 4px 0px ${rgbaColor})`,
    };

    return buttonStyle;
  };

  return (
    <div className={styles.posterGeneratedContainer}>
      <div className="flex px-[24px] mt-[20px] mb-[24px] justify-start w-full">
        {/* <a href={`/poster/${occasion}`}>
          
          <p className="text-black text-[13px] underline opacity-0.7">Home</p>
        </a> */}
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="mt-[24px] mb-[8px] px-[24px]">
          <img
            src={generatedPosterUrl}
            alt=""
            className=" object-cover aspect-square rounded-[16px] shadow-[0px 0px 32px 0px rgba(0, 0, 0, 0.20)]"
          />
        </div>
        {/* <p className='text-black text-[14px] mb-[8px] px-[24px] text-center font-medium'>I enjoyed Aqua Fantasy in Underwater tunnel aquarium</p> */}
        <div className="w-full mb-[16px] px-[40px]">
          <div className=" h-[1px] bg-[#161616] opacity-[0.1] "></div>
        </div>

        <a
          href={`https://app.goviral.world/poster/${occasion}`}
          target="_blank"
        >
          <button className={styles.button}>Create Poster</button>
        </a>
        <div className="w-full my-[16px] px-[40px]">
          <div className=" h-[1px] bg-[#161616] opacity-[0.1] "></div>
        </div>
        {campaigns?.map((campaign) => {
          return (
            <a href={campaign?.url} target="_blank" className="mb-[24px]">
              <div className="px-[16px] relative">
                <img
                  src={campaign?.coverImage}
                  alt=""
                  className="rounded-[16px] "
                />
                <button
                  className={styles.contestButtonChandrayan}
                  style={
                    campaign?.buttonColor && buttonStyle(campaign?.buttonColor)
                  }
                >
                  View contest
                </button>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default PosterPage;
