import React, { useContext, useEffect, useRef, useState } from "react";
import MessagePopup from "../MessagePopup/MessagePopup";
import successTick from "../../assests/successTick.svg";
import regFailed from "../../assests/regFailed.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import _, { isEmpty } from "lodash";
import axios from "axios";
import { idProofs } from "../../utils/common";
import uploadIcon from "../../assests/uploadIcon.svg";
import styles from "./Styles/CollegeTemplate.module.css";
import { ref, uploadBytesResumable } from "firebase/storage";
import { leadsStorage } from "../../store/firebase-config";
import { useNavigate } from "react-router";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import UploadPopup from "../UploadPopup/UploadPopup";
import SubmitFormPopup from "../SubmitFormPopup/SubmitFormPopup";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

export default function CollegeTemplate({
  setShowTerms,
  promotionData,
  setServiceForm,
  mail,
  service,
}) {
  const [leadsSubmitted, setLeadsSubmitted] = useState(false);
  const {
    currentUser,
    setPreviousLocation,
    setShowLogin,
    reqForLeadsSubmit,
    setReqLeadsSubmit,
    userDetailsFromApi,
  } = useUserAuth();
  const { userIdToken, campaignInfId, tinyUrlFromMetaData } =
    useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const organizerId = tinyUrl.split("o").pop().split("c")[0];
  const campaignId = tinyUrl.split("p").pop().split("c")[0];
  let infId = tinyUrl.split("inf")[1];
  let promotion = tinyUrl.startsWith("pro");
  let promotionOrgId = promotionData?.organizationId;
  let promotionEntryId = promotionData?._id;
  const [referralCoupon, setReferralCoupon] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponResponse, setCouponResponse] = useState({});
  const [registerResponse, setRegisterRespose] = useState("");
  const [paymentPayload, setPaymentPayload] = useState({});
  const [submitFormPopup, setSubmitFormPopup] = useState(false);
  const [submitFormPopupFac, setSubmitFormPopupFac] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const faculty = searchParams?.get("faculty");

  const initialValues = {
    name: currentUser?.displayName || "",
    userEmail: currentUser?.email || "",
    email: mail,
    phone: "",
    tc: false,
    promotion: promotion,
    shareUrl: tinyUrl,
    infId: campaignInfId,
    leadType: "REGISTER",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const imageUrlRef = useRef();
  const collegeIdRef = useRef();
  const aadharIdRef = useRef();
  const [source, setSource] = useState("");
  const [file, setFile] = useState([]);
  const [progress, setProgress] = useState([]);
  useEffect(() => {
    if (
      infId &&
      (formValues?.regType == "outsider" || formValues?.regType == "general")
    ) {
      checkCoupon(infId, "inf");
    }
  }, [formValues?.regType]);
  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhone = (number) => {
    let regexmobile =
      // eslint-disable-next-line no-useless-escape
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (number.match(regexmobile)) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    let validateResponse = {};
    if (faculty) {
      validateResponse = validateFaculty(formValues);
    } else {
      validateResponse = validate(formValues);
    }
    setFormErrors(validateResponse);
    if (
      _.isEmpty(validateResponse) &&
      source &&
      !_.isEmpty(formValues.name) &&
      formValues.tc === true
    ) {
      if (faculty) {
        setSubmitFormPopupFac(true);
        return;
      }
      if (promotionData?.showMoreHeader?.regFee) {
        setSubmitFormPopup(true);
      } else {
        const response = await uploadGoviralMedias();
        submitFormApi(response);
      }
    }
  };

  const submitAndPay = async () => {
    const responseFromUpload = await uploadGoviralMedias();
    if (responseFromUpload.error) {
      setErrorMessage("Error while uploading, Please try again");
      return;
    }
    submitFormApiPhonePe(responseFromUpload, paymentPayload);
    setSubmitFormPopup(false);
    setSubmitFormPopupFac(false);
  };

  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    localStorage.setItem("previousLocationLocal", window.location.pathname);
    setShowLogin(true);
    setReqLeadsSubmit(true);
  };
  const buttonColor = () => {
    if (promotion) {
      return {
        background: promotionData?.btnColor,
        color: promotionData?.btnTextColor,
      };
    } else {
      return { background: service?.btnColor, color: service?.btnTextColor };
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values?.regType) {
      errors.regType = "This field is required";
    }
    if (_.isEmpty(values.name)) {
      errors.name = "This field is required";
    }
    if (!source?.imageUrl) {
      errors.imageUrl = "Please upload your photo";
    }
    if (_.isEmpty(values.collegeIdNumber)) {
      errors.collegeIdNumber = "This field is required";
    }
    if (!source?.collegeIdImage) {
      errors.collegeIdImage = "Upload Collge Id Card";
    }
    if (!values?.collegeName && values?.regType !== "insider") {
      errors.collegeName = "Please enter collge Name";
    }
    if (!values?.idProofNumber && values?.regType !== "insider") {
      errors.idProofNumber = "Please enter id number";
    }
    if (!source?.idProofImage && values?.regType !== "insider") {
      errors.idProofImage = "Please upload Aadhaar Card";
    }
    if (!values?.wardName && values?.regType == "general") {
      errors.wardName = "Please enter your ward name";
    }
    if (!values?.relation && values?.regType == "general") {
      errors.relation = "Please enter your relationship with the ward";
    }
    if (!validateEmail(values.userEmail)) {
      errors.userEmail = "Please enter a valid Email";
    }
    if (!validatePhone(values.phone)) {
      errors.phone = "Please enter a valid phone number";
    }

    if (values.tc === false) {
      errors.tc = "Please accept the terms and conditions ";
    }
    return errors;
  };

  const validateFaculty = (values) => {
    const errors = {};

    if (_.isEmpty(values.name)) {
      errors.name = "This field is required";
    }
    if (!source?.imageUrl) {
      errors.imageUrl = "Please upload your photo";
    }
    if (_.isEmpty(values.collegeIdNumber)) {
      errors.collegeIdNumber = "This field is required";
    }
    if (!source?.collegeIdImage) {
      errors.collegeIdImage = "Upload Collge Id Card";
    }
    if (!values?.collegeName) {
      errors.collegeName = "Please enter collge Name";
    }
    if (!validateEmail(values.userEmail)) {
      errors.userEmail = "Please enter a valid Email";
    }
    if (!validatePhone(values.phone)) {
      errors.phone = "Please enter a valid phone number";
    }
    if (values.tc === false) {
      errors.tc = "Please accept the terms and conditions ";
    }
    return errors;
  };

  const paymentHandlerRazorpay = async (e) => {
    try {
      setLoading(true);

      let payload = {
        referralCoupon,
      };
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_INITIATE_PAYMENT}/${organizerId}/${promotionEntryId}?type=${formValues?.regType}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: payload,
      };
      const response = await axios(config);
      setLoading(false);
      const paymentPayload = response?.data?.paymentPayload;
      var options = {
        key: process.env.REACT_APP_RAZOR_ID,
        amount: paymentPayload?.amount,
        currency: paymentPayload?.currency,
        name: "Goviral",
        image: "https://share.goviral.world/favicon.ico",
        order_id: paymentPayload?.id,
        handler: async function (response) {
          const responseFromUpload = await uploadGoviralMedias();
          submitFormApi(responseFromUpload, paymentPayload);
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.log(response.error);
      });
      rzp1.open();
      e.preventDefault();
    } catch (error) {
      console.log("error", error);
    }
  };

  const paymentHandler = async (e) => {
    try {
      setLoading(true);
      let payload = {
        referralCoupon,
      };
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_INITIATE_PAYMENT_OPEN_MONEY}/${organizerId}/${promotionEntryId}?type=${formValues?.regType}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: payload,
      };
      const response = await axios(config);
      setLoading(false);
      const paymentPayload = response?.data?.paymentPayload;

      new window.Layer.checkout(
        {
          token: paymentPayload?.id,
          accesskey: process.env.REACT_APP_OPEN_MONEY_KEY,
          theme: {
            logo: "https://share.goviral.world/favicon.ico",
            color: "#3d9080",
            error_color: "#ff2b2b",
          },
        },
        async function (response) {
          if (response.status == "captured") {
            const responseFromUpload = await uploadGoviralMedias();

            submitFormApi(responseFromUpload, paymentPayload);
          } else if (response.status == "created") {
          } else if (response.status == "pending") {
          } else if (response.status == "failed") {
          } else if (response.status == "cancelled") {
          }
        },
        function (err) {
          //integration errors
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  const uploadGoviralMedias = async () => {
    setLoading(true);
    try {
      let medias = {};
      for (let i = 0; i < file.length; i++) {
        const fileData = file[i];
        let randomId = Math.floor(Date.now() * Math.random());
        const folderPath = `/${promotionEntryId}/${userDetailsFromApi?.userId}_${randomId}`;
        const storageRef = ref(leadsStorage, folderPath);
        const uploadTask = uploadBytesResumable(storageRef, fileData?.fileData);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const uploadprogress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[i] = uploadprogress;

              return newProgress;
            });

            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            throw error;
          }
        );
        // Wait for the upload task to complete before proceeding to the next file
        await uploadTask;

        let fileUrl = "https://storage.googleapis.com/leads_media" + folderPath;
        medias[fileData?.id] = fileUrl;
      }
      return medias;
    } catch (error) {
      console.error(error);
      setLoading(false);
      setProgress([]);
      return { error: true };
    }
  };
  const submitFormApi = async (uplodedMedia, paymentPayload) => {
    setLoading(true);

    try {
      let dataToSubmit = {};
      if (_.isEmpty(formValues?.phone)) {
        dataToSubmit = {
          ...formValues,
          ...uplodedMedia,
          phone: currentUser?.phoneNumber,
          finalAmt:
            promotionData?.showMoreHeader?.regFee -
            (couponResponse?.discountAmt || 0),
          referralCoupon,
        };
      } else {
        dataToSubmit = {
          ...formValues,
          ...uplodedMedia,
          finalAmt:
            promotionData?.showMoreHeader?.regFee -
            (couponResponse?.discountAmt || 0),
          referralCoupon,
        };
      }
      let config = {
        method: "post",
        url: promotion
          ? `${process.env.REACT_APP_REGISTER}/${promotionOrgId}/${promotionEntryId}/${paymentPayload?.id}`
          : `${process.env.REACT_APP_REGISTER}/${organizerId}/${campaignId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSubmit,
      };
      const response = await axios(config);
      setRegisterRespose(response?.data?.regId);
      setLoading(false);
      setProgress([]);
      setLeadsSubmitted(true);
      // setServiceForm(false);
      navigate(`/${tinyUrl}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };
  const submitFormApiPhonePe = async (uplodedMedia, paymentPayload) => {
    setLoading(true);

    try {
      let dataToSubmit = {};
      if (_.isEmpty(formValues?.phone)) {
        dataToSubmit = {
          ...formValues,
          ...uplodedMedia,
          phone: currentUser?.phoneNumber,
          finalAmt:
            promotionData?.showMoreHeader?.regFee -
            (couponResponse?.discountAmt || 0),
          referralCoupon,
          tinyUrl,
        };
      } else {
        dataToSubmit = {
          ...formValues,
          ...uplodedMedia,
          finalAmt:
            promotionData?.showMoreHeader?.regFee -
            (couponResponse?.discountAmt || 0),
          referralCoupon,
          tinyUrl,
        };
      }
      let config = {};
      if (faculty) {
        dataToSubmit = { ...dataToSubmit, regType: "faculty" };
        config = {
          method: "post",
          url: `${process.env.REACT_APP_REGISTER_FREE}/${promotionOrgId}/${promotionEntryId}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: dataToSubmit,
        };
      } else {
        config = {
          method: "post",
          url: `${process.env.REACT_APP_REGISTER_PHONEPE}/${promotionOrgId}/${promotionEntryId}?type=${formValues?.regType}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: dataToSubmit,
        };
      }
      const response = await axios(config);
      if (response?.data?.status == "success") {
        if (faculty) {
          navigate(
            `/${tinyUrl}/payment/${response?.data?.regId}?code=PAYMENT_SUCCESS`
          );
        } else {
          let paymentRedirectUrl = response?.data?.paymentRedirectUrl;
          let url =
            response?.data?.paymentPayload?.data?.instrumentResponse
              ?.redirectInfo?.url;
          handleRedirectToLink(url, paymentRedirectUrl);
        }
      }
      if (response?.data?.status == "fail") {
        setErrorMessage(response?.data?.msg);
      }

      setLoading(false);
      setProgress([]);
      // setLeadsSubmitted(true);
      // setServiceForm(false);
      // navigate(`/${tinyUrl}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };
  const checkCoupon = async (coupon, couponType) => {
    setCouponLoading(true);
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_APPLY_COUPONCODE}/${promotionEntryId}?couponType=${couponType}&type=${formValues?.regType}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          referralCoupon: coupon || referralCoupon,
        },
      };
      const response = await axios(config);
      setCouponResponse({ ...response?.data, referralCoupon });
      setReferralCoupon(response?.data?.appliedCoupon);
      setCouponLoading(false);
    } catch (error) {
      console.log(error.message);
      setCouponLoading(false);
    }
  };

  const handleMediaSelect = async (event) => {
    const image = new FormData();
    let id = event.target.id;
    const imagedata = event.target.files[0];
    image.append("image", imagedata);
    let url = URL.createObjectURL(imagedata);
    setFile((prevState) => [...prevState, { id, fileData: imagedata }]);
    setSource((prevState) => ({ ...prevState, [id]: url }));
  };

  const handleChoose = async () => {
    imageUrlRef.current.click();
  };
  const handleChooseCollegeId = async () => {
    collegeIdRef.current.click();
  };
  const handleChooseIdProofImage = async () => {
    aadharIdRef.current.click();
  };

  const handleCollectUserInputs = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleWhatappStausChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      whatsAppNumber: !prevState?.whatsAppNumber,
    }));
  };
  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.shootmates.com/thirdpartyPayment";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
  }
  return (
    <>
      {!isEmpty(progress) && <UploadPopup progress={progress} />}

      {errorMessage && (
        <MessagePopup
          logo={regFailed}
          setPopup={setErrorMessage}
          setMainPopup={setErrorMessage}
          heading={"Something went Wrong!"}
          message={errorMessage}
        />
      )}
      {leadsSubmitted && (
        <MessagePopup
          logo={successTick}
          setPopup={setLeadsSubmitted}
          setMainPopup={setServiceForm}
          heading={
            registerResponse && `Your Reference ID : ${registerResponse}`
          }
          message={
            promotionData?.regSuccessResponse ||
            `Request successfully submitted. We'll get back to you soon!`
          }
        />
      )}
      {submitFormPopup && (
        <SubmitFormPopup
          logo={successTick}
          setPopup={setSubmitFormPopup}
          heading={"Pay to Register"}
          message={
            "Entry pass for the event will only be issued after the payment has been completed, subject to verification by the admin."
          }
          buttonText={"Pay to Register"}
          handleSubmit={submitAndPay}
          loading={loading}
        />
      )}
      {submitFormPopupFac && (
        <SubmitFormPopup
          logo={successTick}
          setPopup={setSubmitFormPopupFac}
          heading={"Register"}
          message={
            "Entry pass for the event will only be issued after verification by the admin."
          }
          buttonText={"Register"}
          handleSubmit={submitAndPay}
          loading={loading}
        />
      )}
      <div className="h-[100%] w-[100%] text-black px-[16px] md:px-[12%] py-[3%]">
        <Link
          to={{
            pathname: "details",
            search: `${searchParams ? createSearchParams(searchParams) : ""}`,
          }}
        >
          <p className="text-right mb-[10px] text-small cursor-pointer">
            Already Registered?
            <span className="ml-[5px]  underline text-[#3075FC]">
              Show My Registration
            </span>
          </p>
        </Link>
        {faculty ? (
          <>
            <p className="leadcardtext mt-[25px]">
              Please fill out the following information to register.
            </p>
            {/* NAME */}
            <div className="mt-[25px]">
              <p className="text-regular-medium">
                Faculty Name <span className="text-[#FA6B6B]">*</span>
              </p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                  formErrors.name ? styles.errorOutline : ""
                }`}
                type="text"
                id="name"
                onChange={handleCollectUserInputs}
                value={formValues?.name}
              />
              {formErrors.name && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.name}
                </span>
              )}
            </div>
            {/* PHOTO */}
            <div className="flex flex-col gap-[10px] my-[20px]">
              <label htmlFor="imageUrl" className="text-regular-medium">
                <input
                  ref={imageUrlRef}
                  className="hidden"
                  type="file"
                  onChange={handleMediaSelect}
                  accept="image/*"
                  id="imageUrl"
                />
                Upload Your Photo <span className="text-red-600">*</span>
              </label>

              <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                <div
                  className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                   `}
                  onClick={handleChoose}
                >
                  {source?.imageUrl ? (
                    <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                      <div className="h-[100%] w-auto overflow-hidden mx-auto">
                        <img
                          src={source?.imageUrl}
                          className="outline-none w-auto h-[100%] object-cover"
                        />
                      </div>
                      <p>Change Photo</p>
                    </div>
                  ) : (
                    <div>
                      <img src={uploadIcon} className="h-[80px] mx-auto" />
                      <p>Select Photo</p>
                    </div>
                  )}
                </div>
              </div>
              {formErrors.imageUrl && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.imageUrl}
                </span>
              )}
            </div>
            {/* COLLEGE ID */}
            <div className="mt-[20px]">
              <p className="text-regular-medium">
                Emp. Code <span className="text-[#FA6B6B]">*</span>
              </p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                  formErrors.collegeIdNumber ? styles.errorOutline : ""
                }`}
                type="text"
                id="collegeIdNumber"
                value={formValues?.collegeIdNumber}
                onChange={handleCollectUserInputs}
              />
              {formErrors.collegeIdNumber && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.collegeIdNumber}
                </span>
              )}
            </div>
            {/* COLLEGE ID UPLOAD */}
            <div className="flex flex-col gap-[10px] my-[20px]">
              <label htmlFor="collegeIdImage" className="text-regular-medium">
                <input
                  ref={collegeIdRef}
                  className="hidden"
                  type="file"
                  onChange={handleMediaSelect}
                  accept="image/*"
                  id="collegeIdImage"
                />
                Faculty ID Card Image <span className="text-red-600">*</span>
              </label>

              <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                <div
                  className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                  onClick={handleChooseCollegeId}
                >
                  {source?.collegeIdImage ? (
                    <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                      <div className="h-[100%] w-auto overflow-hidden mx-auto">
                        <img
                          src={source?.collegeIdImage}
                          className="outline-none w-auto h-[100%] object-cover"
                        />
                      </div>
                      <p>Change Photo</p>
                    </div>
                  ) : (
                    <div>
                      <img src={uploadIcon} className="h-[80px] mx-auto" />
                      <p>Select Photo</p>
                    </div>
                  )}
                </div>
              </div>
              {formErrors.collegeIdImage && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.collegeIdImage}
                </span>
              )}
            </div>
            {/*COLLEGE NAME */}
            <div className="mt-[25px]">
              <p className="text-regular-medium">
                College Name <span className="text-[#FA6B6B]">*</span>
              </p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                  formErrors.collegeName ? styles.errorOutline : ""
                }`}
                type="text"
                id="collegeName"
                value={formValues?.collegeName}
                onChange={handleCollectUserInputs}
              />
              {formErrors.collegeName && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.collegeName}
                </span>
              )}
            </div>
            {/* EMAIL  */}
            <div className="mt-[25px]">
              <p className="text-regular-medium">
                Email <span className="text-[#FA6B6B]">*</span>
              </p>

              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                  formErrors.userEmail ? styles.errorOutline : ""
                } ${styles.placeholder}`}
                type="email"
                id="userEmail"
                placeholder="Enter email to receive additional information"
                onChange={handleCollectUserInputs}
                value={formValues?.userEmail}
              />
              {formErrors.userEmail && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.userEmail}
                </span>
              )}
            </div>
            {/* PHONE NUMBER  */}
            <div className="mt-[25px]">
              <p className="text-regular-medium">
                Phone Number<span className="text-[#FA6B6B]">*</span>
              </p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
                  ${styles.placeholder} ${
                  formErrors.phone ? styles.errorOutline : ""
                }`}
                type="text"
                placeholder="Share your number"
                id="phone"
                value={formValues?.phone}
                onChange={handleCollectUserInputs}
              />
              {formErrors.phone && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.phone}
                </span>
              )}
            </div>
            <div className="flex items-center gap-[10px] mt-[10px] cursor-pointer">
              <input
                type="checkbox"
                name="whatsAppNumber"
                id="whatsAppNumber"
                onClick={handleWhatappStausChange}
                checked={formValues?.whatsAppNumber}
                className={styles.whatsapp}
              />
              <label
                htmlFor="whatsAppNumber"
                className="text-small text-left cursor-pointer"
              >
                WhatsApp number
              </label>
            </div>
            <div>
              <div className="flex justify-start gap-[10px] mt-[25px]">
                <input
                  type="checkbox"
                  id="tc"
                  name="tc"
                  value=""
                  checked={formValues?.tc}
                  onClick={() =>
                    setFormValues((prev) => ({ ...prev, tc: !prev?.tc }))
                  }
                  className="w-[30px] h-[30px] rounded-[5px] "
                ></input>
                <label
                  htmlFor="tc"
                  className="text-[#171717] font-Inter font-[400] text-[14px] leading-[24px]"
                >
                  Yes I agree to the{" "}
                  <span
                    className="text-blue-500 hover:cursor-pointer"
                    onClick={() => setShowTerms(true)}
                  >
                    Terms & Conditions
                  </span>{" "}
                  . Hereby agree to be contacted for enquiry purpose of the
                  same.
                </label>
              </div>
              {formErrors.tc && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.tc}
                </span>
              )}
            </div>
            <div className="w-[100%] flex justify-center mt-[30px]">
              {loading ? (
                <button
                  className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center "
                  disabled
                  style={buttonColor()}
                >
                  Submiting..
                </button>
              ) : (
                <button
                  className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center"
                  onClick={
                    currentUser === null ? () => handleLogin() : handleSubmit
                  }
                  style={buttonColor()}
                >
                  Register
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="mt-[25px] w-full">
            <div className="mt-[20px]">
              <p className="text-regular-medium">
                Register as
                <span className="text-[#FA6B6B]">*</span>
              </p>
              <div className=" gap-[10px] py-[10px]">
                <div className="flex items-center ">
                  <input
                    type="radio"
                    id="insider"
                    name="regType"
                    onChange={() => {
                      setCouponResponse({});
                      setFormValues((prev) => ({
                        ...prev,
                        regType: "insider",
                      }));
                    }}
                    className="w-[20px] h-[20px] py-[10px]"
                    checked={formValues?.regType == "insider"}
                  />
                  <label htmlFor="insider" className="pl-[10px] py-[10px]">
                    {promotionData?.contactTitle} student
                  </label>
                </div>
                <div className="flex items-center ">
                  <input
                    type="radio"
                    id="outsider"
                    name="regType"
                    onChange={() => {
                      setCouponResponse({});

                      setFormValues((prev) => ({
                        ...prev,
                        regType: "outsider",
                      }));
                    }}
                    className="w-[20px] h-[20px]"
                    checked={formValues?.regType == "outsider"}
                  />
                  <label htmlFor="outsider" className="pl-[10px] py-[10px]">
                    Non {promotionData?.contactTitle} student
                  </label>
                </div>
                <div className="flex items-center ">
                  <input
                    type="radio"
                    id="general"
                    name="regType"
                    onChange={() => {
                      setCouponResponse({});
                      setFormValues((prev) => ({
                        ...prev,
                        regType: "general",
                      }));
                    }}
                    className="w-[20px] h-[20px]"
                    checked={formValues?.regType == "general"}
                  />
                  <label htmlFor="general" className="pl-[10px] py-[10px]">
                    Guardian/Parent Registration
                  </label>
                </div>
              </div>
              {formErrors?.regType && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.regType}
                </span>
              )}
            </div>
            {formValues?.regType == "outsider" && (
              <>
                <p className="leadcardtext mt-[25px]">
                  Please fill out the following information to register.
                </p>
                {/* NAME */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Name <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.name ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="name"
                    value={formValues?.name}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.name && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.name}
                    </span>
                  )}
                </div>
                {/* PHOTO */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={imageUrlRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="imageUrl"
                    />
                    Upload Your Photo <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                   `}
                      onClick={handleChoose}
                    >
                      {source?.imageUrl ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.imageUrl}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.imageUrl && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.imageUrl}
                    </span>
                  )}
                </div>
                {/*COLLEGE NAME */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    College Name <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.collegeName ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="collegeName"
                    value={formValues?.collegeName}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.collegeName && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeName}
                    </span>
                  )}
                </div>
                {/* COLLEGE ID */}
                <div className="mt-[20px]">
                  <p className="text-regular-medium">
                    College ID Number <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.collegeIdNumber ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="collegeIdNumber"
                    value={formValues?.collegeIdNumber}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.collegeIdNumber && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeIdNumber}
                    </span>
                  )}
                </div>
                {/* COLLEGE ID UPLOAD */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={collegeIdRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="collegeIdImage"
                    />
                    Upload College Id <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                      onClick={handleChooseCollegeId}
                    >
                      {source?.collegeIdImage ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.collegeIdImage}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.collegeIdImage && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeIdImage}
                    </span>
                  )}
                </div>
                {/* ID */}
                {/* <div className="mt-[20px]">
                <p className="text-regular-medium">
                  ID <span className="text-[#FA6B6B]">*</span>
                </p>
                <select
                  className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                    formErrors.idProofType ? styles.errorOutline : ""
                  }`}
                  id="idProofType"
                  onChange={handleCollectUserInputs}
                  value={formValues?.idProofType}
                >
                  <option selected disabled>
                    Please Select an ID
                  </option>
                  {idProofs?.map((obj) => (
                    <option value={obj?.id}>{obj?.displayText}</option>
                  ))}
                </select>
                <p className="text-small">
                  Please verify this ID card upon entering the event.
                </p>
                {formErrors.idProofType && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.idProofType}
                  </span>
                )}
              </div> */}
                {/* ID PROOF NUMBER */}
                <div className="mt-[20px]">
                  <p className="text-regular-medium">
                    Aadhaar Card Number{" "}
                    <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.idProofNumber ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="idProofNumber"
                    value={formValues?.idProofNumber}
                    onChange={handleCollectUserInputs}
                  />
                  <p className="text-small">
                    Please verify this ID card upon entering the event.
                  </p>
                  {formErrors.idProofNumber && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.idProofNumber}
                    </span>
                  )}
                </div>
                {/* AAdhar ID UPLOAD */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={aadharIdRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="idProofImage"
                    />
                    Upload Aadhaar Card <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.idProofImage ? "border-[#fa6b6b]" : ""}
                   `}
                      onClick={handleChooseIdProofImage}
                    >
                      {source?.idProofImage ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.idProofImage}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.idProofImage && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.idProofImage}
                    </span>
                  )}
                </div>
                {/* EMAIL  */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Email <span className="text-[#FA6B6B]">*</span>
                  </p>

                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                      formErrors.userEmail ? styles.errorOutline : ""
                    } ${styles.placeholder}`}
                    type="email"
                    id="userEmail"
                    placeholder="Enter email to receive additional information"
                    onChange={handleCollectUserInputs}
                    value={formValues?.userEmail}
                  />
                  {formErrors.userEmail && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.userEmail}
                    </span>
                  )}
                </div>
                {/* PHONE NUMBER  */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Phone Number <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
                  ${styles.placeholder} ${
                      formErrors.phone ? styles.errorOutline : ""
                    }`}
                    type="text"
                    placeholder="Share your number"
                    id="phone"
                    value={formValues?.phone}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.phone && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.phone}
                    </span>
                  )}
                </div>
                <div className="flex items-center gap-[10px] mt-[10px] cursor-pointer">
                  <input
                    type="checkbox"
                    name="whatsAppNumber"
                    id="whatsAppNumber"
                    onClick={handleWhatappStausChange}
                    checked={formValues?.whatsAppNumber}
                    className={styles.whatsapp}
                  />
                  <label
                    htmlFor="whatsAppNumber"
                    className="text-small text-left cursor-pointer"
                  >
                    WhatsApp number
                  </label>
                </div>
              </>
            )}

            {formValues?.regType == "insider" && (
              <>
                <p className="leadcardtext mt-[25px]">
                  Please fill out the following information to register.
                </p>
                {/* NAME */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Name <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.name ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="name"
                    onChange={handleCollectUserInputs}
                    value={formValues?.name}
                  />
                  {formErrors.name && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.name}
                    </span>
                  )}
                </div>
                {/* PHOTO */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={imageUrlRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="imageUrl"
                    />
                    Upload Your Photo <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                    ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                    `}
                      onClick={handleChoose}
                    >
                      {source?.imageUrl ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.imageUrl}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.imageUrl && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.imageUrl}
                    </span>
                  )}
                </div>
                {/* COLLEGE ID */}
                <div className="mt-[20px]">
                  <p className="text-regular-medium">
                    College ID Number <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.collegeIdNumber ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="collegeIdNumber"
                    value={formValues?.collegeIdNumber}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.collegeIdNumber && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeIdNumber}
                    </span>
                  )}
                </div>
                {/* COLLEGE ID UPLOAD */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={collegeIdRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="collegeIdImage"
                    />
                    Upload College Id <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                      onClick={handleChooseCollegeId}
                    >
                      {source?.collegeIdImage ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.collegeIdImage}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.collegeIdImage && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeIdImage}
                    </span>
                  )}
                </div>
                {/* EMAIL  */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Email <span className="text-[#FA6B6B]">*</span>
                  </p>

                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                      formErrors.userEmail ? styles.errorOutline : ""
                    } ${styles.placeholder}`}
                    type="email"
                    id="userEmail"
                    placeholder="Enter email to receive additional information"
                    onChange={handleCollectUserInputs}
                    value={formValues?.userEmail}
                  />
                  {formErrors.userEmail && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.userEmail}
                    </span>
                  )}
                </div>
                {/* PHONE NUMBER  */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Phone Number<span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
                  ${styles.placeholder} ${
                      formErrors.phone ? styles.errorOutline : ""
                    }`}
                    type="text"
                    placeholder="Share your number"
                    id="phone"
                    value={formValues?.phone}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.phone && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.phone}
                    </span>
                  )}
                </div>
                <div className="flex items-center gap-[10px] mt-[10px] cursor-pointer">
                  <input
                    type="checkbox"
                    name="whatsAppNumber"
                    id="whatsAppNumber"
                    onClick={handleWhatappStausChange}
                    checked={formValues?.whatsAppNumber}
                    className={styles.whatsapp}
                  />
                  <label
                    htmlFor="whatsAppNumber"
                    className="text-small text-left cursor-pointer"
                  >
                    WhatsApp number
                  </label>
                </div>
              </>
            )}

            {formValues?.regType == "general" && (
              <>
                <p className="leadcardtext mt-[25px]">
                  Please fill out the following information to register.
                </p>
                {/* NAME */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Guardian Name <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.name ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="name"
                    value={formValues?.name}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.name && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.name}
                    </span>
                  )}
                </div>
                {/* OCCUPATION */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">Guardian Occupation</p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.occupation ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="occupation"
                    value={formValues?.occupation}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.occupation && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.occupation}
                    </span>
                  )}
                </div>
                {/* PHOTO */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={imageUrlRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="imageUrl"
                    />
                    Upload Your Photo <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                   `}
                      onClick={handleChoose}
                    >
                      {source?.imageUrl ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.imageUrl}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.imageUrl && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.imageUrl}
                    </span>
                  )}
                </div>
                {/* ID PROOF NUMBER */}
                <div className="mt-[20px]">
                  <p className="text-regular-medium">
                    Aadhaar Card Number{" "}
                    <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.idProofNumber ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="idProofNumber"
                    value={formValues?.idProofNumber}
                    onChange={handleCollectUserInputs}
                  />
                  <p className="text-small">
                    Please verify this ID card upon entering the event.
                  </p>
                  {formErrors.idProofNumber && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.idProofNumber}
                    </span>
                  )}
                </div>
                {/* AAdhar ID UPLOAD */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={aadharIdRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="idProofImage"
                    />
                    Upload Aadhaar Card <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.idProofImage ? "border-[#fa6b6b]" : ""}
                   `}
                      onClick={handleChooseIdProofImage}
                    >
                      {source?.idProofImage ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.idProofImage}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.idProofImage && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.idProofImage}
                    </span>
                  )}
                </div>

                {/*WARD NAME */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Ward Name <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.wardName ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="wardName"
                    value={formValues?.wardName}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.wardName && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.wardName}
                    </span>
                  )}
                </div>
                {/*Relation eith ward */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Relationship with Ward
                    <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.relation ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="relation"
                    value={formValues?.relation}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.relation && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.relation}
                    </span>
                  )}
                </div>
                {/*COLLEGE NAME */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    College Name <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.collegeName ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="collegeName"
                    value={formValues?.collegeName}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.collegeName && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeName}
                    </span>
                  )}
                </div>
                {/* COLLEGE ID */}
                <div className="mt-[20px]">
                  <p className="text-regular-medium">
                    Ward College ID Number{" "}
                    <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                      formErrors.collegeIdNumber ? styles.errorOutline : ""
                    }`}
                    type="text"
                    id="collegeIdNumber"
                    value={formValues?.collegeIdNumber}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.collegeIdNumber && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeIdNumber}
                    </span>
                  )}
                </div>
                {/* COLLEGE ID UPLOAD */}
                <div className="flex flex-col gap-[10px] my-[20px]">
                  <label htmlFor="imageUrl" className="text-regular-medium">
                    <input
                      ref={collegeIdRef}
                      className="hidden"
                      type="file"
                      onChange={handleMediaSelect}
                      accept="image/*"
                      id="collegeIdImage"
                    />
                    Upload Ward College ID{" "}
                    <span className="text-red-600">*</span>
                  </label>

                  <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                    <div
                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                      onClick={handleChooseCollegeId}
                    >
                      {source?.collegeIdImage ? (
                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                            <img
                              src={source?.collegeIdImage}
                              className="outline-none w-auto h-[100%] object-cover"
                            />
                          </div>
                          <p>Change Photo</p>
                        </div>
                      ) : (
                        <div>
                          <img src={uploadIcon} className="h-[80px] mx-auto" />
                          <p>Select Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {formErrors.collegeIdImage && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.collegeIdImage}
                    </span>
                  )}
                </div>

                {/* EMAIL  */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Email <span className="text-[#FA6B6B]">*</span>
                  </p>

                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                      formErrors.userEmail ? styles.errorOutline : ""
                    } ${styles.placeholder}`}
                    type="email"
                    id="userEmail"
                    placeholder="Enter email to receive additional information"
                    onChange={handleCollectUserInputs}
                    value={formValues?.userEmail}
                  />
                  {formErrors.userEmail && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.userEmail}
                    </span>
                  )}
                </div>
                {/* PHONE NUMBER  */}
                <div className="mt-[25px]">
                  <p className="text-regular-medium">
                    Phone Number <span className="text-[#FA6B6B]">*</span>
                  </p>
                  <input
                    className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
                  ${styles.placeholder} ${
                      formErrors.phone ? styles.errorOutline : ""
                    }`}
                    type="text"
                    placeholder="Share your number"
                    id="phone"
                    value={formValues?.phone}
                    onChange={handleCollectUserInputs}
                  />
                  {formErrors.phone && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.phone}
                    </span>
                  )}
                </div>
                <div className="flex items-center gap-[10px] mt-[10px] cursor-pointer">
                  <input
                    type="checkbox"
                    name="whatsAppNumber"
                    id="whatsAppNumber"
                    onClick={handleWhatappStausChange}
                    checked={formValues?.whatsAppNumber}
                    className={styles.whatsapp}
                  />
                  <label
                    htmlFor="whatsAppNumber"
                    className="text-small text-left cursor-pointer"
                  >
                    WhatsApp number
                  </label>
                </div>
              </>
            )}
          </div>
        )}

        {formValues?.regType && (
          <>
            {!isEmpty(promotionData?.showMoreHeader?.regFee) && (
              <>
                <div className="seperator-black mt-[30px] mb-[20px]" />
                {formValues?.regType !== "insider" && (
                  <div className="mt-[25px]">
                    <p className="text-regular-medium">Coupon Code</p>
                    <div className="flex gap-[10px] items-center mt-[10px]">
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] outline-none text-[14px]
                          ${styles.placeholder} ${
                          couponResponse.invalidReferral
                            ? styles.errorOutline
                            : ""
                        }`}
                        type="text"
                        placeholder="Enter the code and check eligibility."
                        id="referralCoupon"
                        value={referralCoupon}
                        onChange={(e) => setReferralCoupon(e.target.value)}
                      />
                      <button
                        style={buttonColor()}
                        className="text-small whitespace-nowrap h-full w-fit  px-[20px] py-[15px] rounded-md"
                        onClick={() => checkCoupon("", "")}
                        disabled={couponLoading || loading}
                      >
                        {couponLoading ? <LoadingCircle /> : "Apply Coupon"}
                      </button>
                    </div>
                    {couponResponse.invalidReferral ? (
                      <span className={styles.error}>
                        <span className="text-red-600">*</span>
                        You entered an invalid code. Please check the code and
                        try again!
                      </span>
                    ) : (
                      <span className={styles.error}>
                        {couponResponse?.discountAmt &&
                          `You're saving ${couponResponse?.discountAmt} on this registration`}
                      </span>
                    )}
                  </div>
                )}
                <div className="mt-[25px] p-[25px] bg-[#eee]">
                  <p className="text-regular-medium">Summary</p>
                  <table className={styles.table}>
                    <tr>
                      <td className="text-small">Original Price:</td>
                      <td className="text-small">
                        ₹
                        {promotionData?.showMoreHeader?.regFee &&
                          promotionData?.showMoreHeader?.regFee[
                            formValues?.regType
                          ]}
                      </td>
                    </tr>

                    {couponResponse?.discountAmt && (
                      <tr>
                        <td className="text-small">Discounts:</td>
                        <td className="text-small">
                          -₹{couponResponse?.discountAmt}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td style={{ padding: 0 }}>
                        <div className="seperator-black w-full" />
                      </td>
                      <td style={{ padding: 0 }}>
                        <div className="seperator-black w-full" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-small-bold">Total:</td>
                      <td className="text-small-bold">
                        ₹
                        {promotionData?.showMoreHeader?.regFee &&
                          promotionData?.showMoreHeader?.regFee[
                            formValues?.regType
                          ] - (couponResponse?.discountAmt || 0)}
                      </td>
                    </tr>
                  </table>
                  <p className="text-smallest-bold w-full text-right">
                    *Amount paid is not refundable
                  </p>
                </div>
                <div className="seperator-black mt-[30px] mb-[20px]" />
              </>
            )}

            <div>
              <div className="flex justify-start gap-[10px] mt-[25px]">
                <input
                  type="checkbox"
                  id="tc"
                  name="tc"
                  value=""
                  checked={formValues?.tc}
                  onClick={() =>
                    setFormValues((prev) => ({ ...prev, tc: !prev?.tc }))
                  }
                  className="w-[30px] h-[30px] rounded-[5px] "
                ></input>
                <label
                  htmlFor="tc"
                  className="text-[#171717] font-Inter font-[400] text-[14px] leading-[24px]"
                >
                  Yes I agree to the{" "}
                  <span
                    className="text-blue-500 hover:cursor-pointer"
                    onClick={() => setShowTerms(true)}
                  >
                    Terms & Conditions
                  </span>{" "}
                  . Hereby agree to be contacted for enquiry purpose of the
                  same.
                </label>
              </div>
              {formErrors.tc && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.tc}
                </span>
              )}
            </div>
            <div className="w-[100%] flex justify-center mt-[30px]">
              {loading ? (
                <button
                  className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center "
                  disabled
                  style={buttonColor()}
                >
                  Submiting..
                </button>
              ) : (
                <button
                  className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center"
                  onClick={
                    currentUser === null ? () => handleLogin() : handleSubmit
                  }
                  style={buttonColor()}
                >
                  {!isEmpty(promotionData?.showMoreHeader?.regFee) && "Pay to"}{" "}
                  Register
                </button>
              )}
            </div>
            {/* <p className="text-small text-center mt-[5px] text-[#5d5d5d]">
              ~managed by Memshots Pvt. Ltd.
            </p> */}
          </>
        )}
      </div>
    </>
  );
}
