import React, { useEffect, useState } from "react";
import styles from "./CouponDetails.module.css";
import CouponCode from "../../assests/CouponCode";
import { Link } from "react-router-dom";

export default function CouponDetails({
  setShowPopup,
  data,
  handleSelectCoupon,
  hideCart,
}) {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  let couponCodeStyle = {
    color: "#55A226",
    textTransform: "uppercase",
  };
  function copyLink() {
    let copyText = "";
    if (data?.type == "GENERATE") {
      copyText = data?.generatedCoupon;
    } else {
      copyText = data?.couponCode;
    }
    setCopied(true);
    setTimeout(() => {
      let onlineStore = data?.cpnUsability?.find((el) => el?.id == "online");
      if (onlineStore?.isUsable && onlineStore?.link) {
        window.open(onlineStore?.link);
      }
      setCopied(false);
    }, [1000]);
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");
    // alert(copyText + "\n copied to clipboard")
  }
  return (
    <div
      className={`fixed top-0 left-0 h-[100%] w-[100%] z-[100] p-[20px] ${
        styles.popupBackground
      } overflow-y-auto no-scrollbar transform transition-opacity ease-in-out duration-300
        ${show ? "opacity-1" : "opacity-0"}`}
    >
      <div className={styles.parentDiv}>
        <div className="w-full flex flex-col items-center ">
          <CouponCode />
          <h1 className="mb-[20px] mt-[8px]">Coupon Details</h1>
          <div className="flex gap-[3px] w-full mb-[20px]">
            <h2 style={couponCodeStyle}>
              {data?.type == "GENERATE"
                ? data?.generatedCoupon
                : data?.couponCode}
            </h2>
            <button className={styles.copy} onClick={copyLink}>
              {copied ? "Copied" : " Copy code"}
            </button>
          </div>
        </div>
        <div className="mb-[20px] w-full">
          {data?.cpnApplicableProduct && (
            <>
              <h3>Coupon applicable for the following products:</h3>
              <p className="mb-[10px]">{data?.cpnApplicableProduct}</p>
            </>
          )}
          <div className="mb-[10px]">
            <h3>Coupon usability:</h3>
            {data?.cpnUsability?.map((obj) => {
              return (
                <div className="flex items-center gap-[20px]">
                  <div className="flex items-center gap-[5px]">
                    {obj?.isUsable ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                      >
                        <path
                          d="M1 6L5.667 11L15 1"
                          stroke="#55A226"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.292923 1.70714C-0.0976171 1.31663 -0.0976448 0.683464 0.292863 0.292923C0.683371 -0.0976175 1.31654 -0.0976446 1.70708 0.292863L5.60509 4.19054L9.5031 0.292863C9.89364 -0.0976446 10.5268 -0.0976175 10.9173 0.292923C11.3078 0.683464 11.3078 1.31663 10.9173 1.70714L7.01936 5.60469L10.9172 9.50218C11.3077 9.89269 11.3077 10.5259 10.9172 10.9164C10.5267 11.3069 9.89357 11.307 9.50303 10.9165L5.60509 7.01885L1.70715 10.9165C1.31661 11.307 0.68344 11.3069 0.292933 10.9164C-0.0975747 10.5259 -0.0975477 9.89269 0.292993 9.50218L4.19081 5.60469L0.292923 1.70714Z"
                          fill="#F85959"
                        />
                      </svg>
                    )}
                    <p>{obj?.type}</p>
                  </div>
                  {obj?.isUsable && obj?.link && (
                    <Link
                      className="flex items-center gap-[5px] text-[#56565d]"
                      to={obj?.link}
                      target="_blank"
                    >
                      {obj?.id == "online" && "go to store"}
                      {obj?.id == "offline" && " view address"}
                      <svg
                        width="15px"
                        height="15px"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.8333 12.5C16.2789 12.5 12.5 16.2789 12.5 20.8333V79.1667C12.5 83.7211 16.2789 87.5 20.8333 87.5H79.1667C83.7211 87.5 87.5 83.7211 87.5 79.1667V50H79.1667V79.1667H20.8333V20.8333H50V12.5H20.8333ZM58.3333 12.5V20.8333H73.2747L34.554 59.554L40.446 65.446L79.1667 26.7253V41.6667H87.5V12.5H58.3333Z"
                          fill="#56565d"
                        />
                      </svg>
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
          {data?.note && (
            <h3>
              Note: <span style={{ fontWeight: 400 }}>{data?.note}</span>
            </h3>
          )}
        </div>
        <div className="w-full">
          {!hideCart ? (
            <button
              className="mb-[8px]"
              style={{
                background: data?.selected ? "#c81a1a" : "#55A226",
                color: "#FFF",
              }}
              onClick={() => handleSelectCoupon(data)}
            >
              {data?.selected
                ? "Remove coupon from cart"
                : "Add coupon to cart"}
            </button>
          ) : (
            <button
              className="mb-[8px]"
              style={{
                background: data?.selected ? "#c81a1a" : "#55A226",
                color: "#FFF",
              }}
              onClick={copyLink}
            >
              Go to store
            </button>
          )}
          <button onClick={() => setShowPopup(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
}
