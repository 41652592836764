import React from "react";
import styles from "./LoadingFromCenter.module.css";

export default function LoadingFromCenter() {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingBbar}></div>
    </div>
  );
}
