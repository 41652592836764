import axios from "axios";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Context from "../../context/Context";
import { useUserAuth } from "../../context/UserAuthContext";
import "./Share.css";
import styles from "./Share.module.css";
import MessagePopup from "../MessagePopup/MessagePopup";
import successTick from "../../assests/successTick.svg";
const ServiceFormInput = ({
  setServiceForm,
  mail,
  setShowTerms,
  promotionData,
  service,
  type,
}) => {
  // const [name, setName] = useState('')
  // const [email, setEmail] = useState('')
  // const [phone, setPhone] = useState("")
  // const [requirment, setRequirement] = useState("")
  // const [tc, setTc] = useState(false)
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    currentUser,
    setPreviousLocation,
    setShowLogin,
    reqForLeadsSubmit,
    setReqLeadsSubmit,
  } = useUserAuth();
  const { userIdToken, campaignInfId, tinyUrlFromMetaData } =
    useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [leadsSubmitted, setLeadsSubmitted] = useState(false);
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  // console.log("tinyUrl", tinyUrl);
  const organizerId = tinyUrl.split("o").pop().split("c")[0];
  const campaignId = tinyUrl.split("p").pop().split("c")[0];

  let promotion = tinyUrl.startsWith("pro");
  let promotionOrgId = promotionData?.organizationId;
  let promotionEntryId = promotionData?._id;
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    userEmail: "",
    email: mail,
    phone: "",
    requirment: "",
    tc: false,
    promotion: promotion,
    shareUrl: tinyUrl,
    infId: campaignInfId,
    leadType: "LEAD",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [filterChips, setFilterChips] = useState([]);
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = async () => {
    try {
      const data = await axios.get(
        "https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/courses.json"
      );
      setFilterChips(data?.data?.courses);
    } catch (error) {}
  };

  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhone = (number) => {
    let regexmobile =
      // eslint-disable-next-line no-useless-escape
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (number.match(regexmobile)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (reqForLeadsSubmit) {
      handleSubmit();
      setReqLeadsSubmit(false);
    }
  }, [currentUser]);

  const submitFormApi = async () => {
    setLoading(true);
    try {
      let dataToSubmit = {};
      if (_.isEmpty(formValues?.phone)) {
        dataToSubmit = { ...formValues, phone: currentUser?.phoneNumber };
      } else {
        dataToSubmit = formValues;
      }
      let config = {
        method: "post",
        url: promotion
          ? `${process.env.REACT_APP_SHARE_FORM_DATA}/${promotionOrgId}/${promotionEntryId}`
          : `${process.env.REACT_APP_SHARE_FORM_DATA}/${organizerId}/${campaignId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSubmit,
      };
      const response = await axios(config);
      setLoading(false);
      setLeadsSubmitted(true);
      // setServiceForm(false);
      navigate(`/${tinyUrl}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    let validateResponse = validate(formValues);
    setFormErrors(validateResponse);

    if (
      _.isEmpty(validateResponse) &&
      !_.isEmpty(formValues.name) &&
      !_.isEmpty(formValues.requirment) &&
      formValues.tc === true
    ) {
      submitFormApi();
    }
  };
  const validate = (values) => {
    const errors = {};
    if (_.isEmpty(values.name)) {
      errors.name = "This field is required";
    }
    // if (!validateEmail(values.userEmail)) {
    //   errors.userEmail = "Please enter a valid Email";
    // }
    // if (!validatePhone(values.phone)) {
    //   errors.phone = "Please enter a valid phone number";
    // }
    if (_.isEmpty(values.requirment)) {
      errors.requirment = "This field is required  ";
    }
    if (!values.course && type == "STUDENTS_ENQUIRY") {
      errors.course = "This field is required  ";
    }
    if (values.tc === false) {
      errors.tc = "Please accept the terms and conditions ";
    }
    return errors;
  };

  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    localStorage.setItem("previousLocationLocal", window.location.pathname);
    setShowLogin(true);
    setReqLeadsSubmit(true);
    // navigate(`/signup`, { state: { submitForm: true } });
  };
  const buttonColor = () => {
    if (promotion) {
      return {
        background: promotionData?.btnColor,
        color: promotionData?.btnTextColor,
      };
    } else {
      return { background: service?.btnColor, color: service?.btnTextColor };
    }
  };
  const handleChangeInput = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const message = "Request successfully submitted. We'll get back to you soon!";

  return (
    <>
      {leadsSubmitted && (
        <MessagePopup
          logo={successTick}
          setPopup={setLeadsSubmitted}
          setMainPopup={setServiceForm}
          message={message}
        />
      )}
      <div className="h-[100%] w-[100%] text-black px-[16px] md:px-[12%] py-[3%]">
        <p className="leadcardtext">
          For any enquiries please feel free to complete form down below and
          we'll get back to you soon
        </p>
        {type == "STUDENTS_ENQUIRY" ? (
          <div className="mt-[25px]">
            <div className="mt-[20px]">
              <p>
                Name <span className="text-[#FA6B6B]">*</span>
              </p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                  formErrors.name ? styles.errorOutline : ""
                }`}
                type="text"
                id="name"
                onChange={handleChangeInput}
              />
              {formErrors.name && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.name}
                </span>
              )}
            </div>
            <div className="mt-[25px]">
              <p>Email</p>

              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                  formErrors.userEmail ? styles.errorOutline : ""
                } ${styles.placeholder}`}
                type="text"
                id="userEmail"
                placeholder="Provide your email for updates on this enquiry"
                onChange={handleChangeInput}
              />
              {formErrors.userEmail && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.userEmail}
                </span>
              )}
            </div>

            <div className="mt-[25px]">
              <p>Phone Number</p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
          ${styles.placeholder} ${formErrors.phone ? styles.errorOutline : ""}
                  `}
                type="text"
                id="phone"
                placeholder="Share your number for a callback."
                // value={participantName}
                onChange={handleChangeInput}
              />
              {formErrors.phone && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.phone}
                </span>
              )}
            </div>
            <div className={`mt-[25px] ${styles.form}`}>
              <div className="flex items-center relative">
                <p className={`mt-[10px]  ${styles.formheadingtwo}`}>
                  Select Course<span className="text-red-600">*</span>
                </p>
              </div>
              <select
                value={formValues?.course}
                onChange={handleChangeInput}
                id="course"
              >
                <option value="">Select Course</option>
                {filterChips?.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {formErrors.course && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.course}
                </span>
              )}
            </div>
            <div className="mt-[25px]">
              <p>
                Requirement <span className="text-[#FA6B6B]">*</span>
              </p>
              <textarea
                placeholder="Please enter your message"
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] h-[100px] mt-[10px] outline-none ${
                  formErrors.requirment ? styles.errorOutline : ""
                }`}
                type="text"
                id="requirment"
                onChange={handleChangeInput}
              />
              {formErrors.requirment && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.requirment}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="mt-[25px]">
            <div className="mt-[20px]">
              <p>
                Name <span className="text-[#FA6B6B]">*</span>
              </p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                  formErrors.name ? styles.errorOutline : ""
                }`}
                type="text"
                id="name"
                onChange={handleChangeInput}
              />
              {formErrors.name && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.name}
                </span>
              )}
            </div>
            <div className="mt-[25px]">
              <p>Email</p>

              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                  formErrors.userEmail ? styles.errorOutline : ""
                } ${styles.placeholder}`}
                type="text"
                id="userEmail"
                placeholder="Provide your email for updates on this enquiry"
                onChange={handleChangeInput}
              />
              {formErrors.userEmail && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.userEmail}
                </span>
              )}
            </div>

            <div className="mt-[25px]">
              <p>Phone Number</p>
              <input
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
                 ${styles.placeholder} ${
                  formErrors.phone ? styles.errorOutline : ""
                }
                  `}
                type="text"
                id="phone"
                placeholder="Share your number for a callback."
                // value={participantName}
                onChange={handleChangeInput}
              />
              {formErrors.phone && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.phone}
                </span>
              )}
            </div>

            <div className="mt-[25px]">
              <p>
                Requirement <span className="text-[#FA6B6B]">*</span>
              </p>
              <textarea
                placeholder="Please enter your message"
                className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] h-[100px] mt-[10px] outline-none ${
                  formErrors.requirment ? styles.errorOutline : ""
                }`}
                type="text"
                id="requirment"
                onChange={handleChangeInput}
              />
              {formErrors.requirment && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.requirment}
                </span>
              )}
            </div>
          </div>
        )}
        <div>
          <div className="flex justify-start gap-[10px] mt-[25px]">
            <input
              type="checkbox"
              id="tc"
              name="tc"
              value=""
              // checked={true}
              onClick={() =>
                formValues.newsletterRecipient === true
                  ? setFormValues({
                      ...formValues,
                      tc: false,
                    })
                  : setFormValues({
                      ...formValues,
                      tc: true,
                    })
              }
              className="w-[30px] h-[30px] rounded-[5px] "
            ></input>
            <label
              htmlFor="tc"
              className="text-[#171717] font-Inter font-[400] text-[14px] leading-[24px]"
            >
              Yes I agree to the{" "}
              <span
                className="text-blue-500 hover:cursor-pointer"
                onClick={() => setShowTerms(true)}
              >
                Terms & Conditions
              </span>{" "}
              . Hereby agree to be contacted for enquiry purpose of the same.
            </label>
          </div>
          {formErrors.tc && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.tc}
            </span>
          )}
        </div>
        <div className="w-[100%] flex justify-center mt-[30px]">
          {loading ? (
            <button
              className="h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center "
              disabled
              style={buttonColor()}
            >
              Submiting..
            </button>
          ) : (
            <button
              className="h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center"
              onClick={
                currentUser === null ? () => handleLogin() : handleSubmit
              }
              style={buttonColor()}
            >
              Submit Request
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceFormInput;
