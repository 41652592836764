import React, { useEffect } from "react";
import styles from "./MessagePopup.module.css";
import PopupWrapper from "../../Layout/PopupWrapper";

const MessagePopup = ({ logo, setPopup, heading, message, setMainPopup }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <PopupWrapper>
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          <img src={logo} alt="" className="w-[56px] h-[56px] mb-[24px]  " />
          <p className="text-regular-medium px-[20px] mb-[16px]">{heading}</p>
          <pre className="text-small text-[#CDCDCD] px-[20px]">{message}</pre>
          <div className="seperator-black mb-[20px]" />
          <div className="flex flex-col gap-[16px] w-[100%] cursor-pointer">
            <button
              className={`text-regular-medium ${styles.button}`}
              onClick={() => {
                setPopup(false);
                setMainPopup(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default MessagePopup;
