export const idProofs = [
  {
    displayText: "Aadhaar card",
    id: "aadhaar",
  },
  {
    displayText: "Driving licence",
    id: "licence",
  },
];
