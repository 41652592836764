import React, { useContext, useEffect, useState } from "react";
import styles from "./RetryLoginPopup.module.css";
import icon from "../../assests/signOut.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import axios from "axios";

const RetryLoginPopup = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    handleReport();
  }, []);
  const [loading, setLoading] = useState(false);
  const { userIdToken } = useContext(Context);
  const { userCheck, currentUser, setRetryCreateUser, errorData } =
    useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  const handleRefresh = async () => {
    setLoading(true);
    let otpResponse = { _tokenResponse: { idToken: token } };
    await userCheck(otpResponse);
    // setRetryCreateUser(false);
    setLoading(false);
  };
  const handleReport = async () => {
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ERROR_REPORT}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: errorData,
      };
      const response = await axios(config);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className={styles.mainDiv}>
      <div className={styles.container}>
        <img src={icon} alt="" className="w-[56px] h-[56px] mb-[24px]  " />
        {/* <p className="text-regular-medium px-[20px] mb-[16px]">
          Option to dislike is disabled
        </p> */}
        <p className="text-small text-[#CDCDCD] px-[20px]">
          It's not you, Its US !We faced some issues while letting you IN :({" "}
          <br />
          please click the refresh button below
        </p>
        <div className="seperator my-[20px]" />
        <div className="flex flex-col gap-[16px] w-[100%] cursor-pointer">
          <button
            className={`text-regular-medium bg-[#439288] ${styles.button}`}
            onClick={handleRefresh}
            disabled={loading}
          >
            {loading ? "Loading" : "Refresh"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RetryLoginPopup;
