import React, { useEffect, useState } from "react";
import styles from "./CouponLoginScreen.module.css";
import couponOffer from "../../assests/couponOffer.png";

export default function CouponLoginScreen({ setShowPopup, data, handleLogin }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 h-[100%] w-[100%] z-10 p-[20px] ${
        styles.popupBackground
      } overflow-y-auto no-scrollbar transform transition-opacity ease-in-out duration-300
        ${show ? "opacity-1" : "opacity-0"}`}
    >
      <div className={styles.parentDiv}>
        <div className={styles.banner}>
          <img src={couponOffer} />
        </div>
        <div className="w-full p-[20px]">
          <h1 className="mb-[10px]">This coupon is exclusively for you!</h1>
          <p className="mb-[30px]">
            Login to access this coupon & save more on your purchases
          </p>
          <div className="flex gap-[16px] w-full items-center">
            <button onClick={() => setShowPopup(false)}>Cancel</button>
            <button
              style={{
                background: "#55A226",
                color: "#FFF",
                border: "none",
              }}
              onClick={() => {
                handleLogin(data);
              }}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
