import styles from "./styles/UserLinkGenration.module.css";
import React, { useContext, useEffect, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";

function UserLinkGenration({
  generateLinkLoading,
  handleLogin,
  handleShareLink,
  setPromotorShare,
  promotionDataJson,
}) {
  const { currentUser, reqForPromoLink, setReqForPrmoLink } = useUserAuth();
  useEffect(() => {
    if (reqForPromoLink) {
      handleShareLink();
      setReqForPrmoLink(false);
    }
  }, [currentUser]);
  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex?.slice(1, 3), 16);
    const g = parseInt(hex?.slice(3, 5), 16);
    const b = parseInt(hex?.slice(5, 7), 16);
    if (hex) {
      return `drop-shadow(rgba(${r}, ${g}, ${b}, ${alpha})
       4px 4px 0px)`;
    } else {
      return "";
    }
  };
  let colors = promotionDataJson?.themeColors;

  return (
    <div
      className="bg-[#035BDE] flex flex-col md:flex-row gap-[20px] md:gap-[120px] justify-center items-start md:items-center md:py-[32px] py-[24px] px-[20px]"
      style={{ background: colors?.secondary }}
    >
      <div className={styles.content}>
        <h2 style={{ color: colors?.text1 }}>
          {/* Want to be an <br />
          influencer on */}
          Would you like to promote this contest?
        </h2>
        {/* <h1>Goviral</h1> */}
        {/* <button> What is this?</button> */}
      </div>
      <button
        onClick={() => setPromotorShare(true)}
        className={styles.genrateLink}
        disabled={generateLinkLoading}
        style={{
          color: colors?.text2,
          background: colors?.button1,
          filter: hexToRGBA(colors?.button1, 0.6),
        }}
      >
        How To Promote?
      </button>
    </div>
  );
}

export default UserLinkGenration;
