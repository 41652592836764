import { useContext, useEffect, useState } from "react";

import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import * as _ from "lodash";
import axios from "axios";
import { useNavigate } from "react-router";
import ServiceForm from "../Share/ServiceForm";
import ShareInShare from "../Share/ShareInShare";
import PromotorsDetials from "../PromotorsDetials/PromotorsDetials";
import DetailPage from "../DetailPage/DetailPage";
import shareWithoutBg from "../../assests/shareWithoutBg.svg";

import { authentication } from "../../store/firebase-config";
import NormalPromotion from "../PromotionTemplate/NormalPromotion";
import CampaignPromotion from "../PromotionTemplate/CampaignPromotion";
import PromotorsDetialsCampaign from "../PromotorsDetials/PromotorsDetialsCampaign";
import Lottie from "../../Components/lottie/finalupload/finalUploadloading";
import CouponDetails from "../CouponDetails/CouponDetails";
import RegistrationForm from "../Share/RegistrationForm";
import PurchasePopup from "../Share/PurchasePopup";
let cancelTokenSource = null;

export default function PromotionBanner({
  promotionDataJson,
  setPromotionDataJson,
  promotionData,
  setPromotionData,
  setads,
  setCoupons,
  serviceForm,
  setServiceForm,
  registerForm,
  setRegisterForm,
  service,
  handleClickCounterPromotion,
  loadInfluencerAdsDetails,
  inflencerDetails,
}) {
  const {
    tinyUrlFromMetaData,
    verifyUser,
    userIdToken,
    setWhatsUpLink,
    openDetail,
    setOpenDetail,
  } = useContext(Context);
  const tinyUrlCustom =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const tinyUrl = tinyUrlCustom.replace(/c/g, "");

  const {
    currentUser,
    setPreviousLocation,
    setShowLogin,
    reqForLike,
    setReqForLike,
    setCustomLogin,
    reqForRegister,
    setReqForRegister,
  } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;
  const navigate = useNavigate();
  const initialValues = {
    name: currentUser?.displayName ? currentUser?.displayName : "",
    email: currentUser?.email ? currentUser?.email : "",
    phoneNumber: currentUser?.phoneNumber ? currentUser?.phoneNumber : "",
  };
  const [influencerFormValue, setInfluencerFormValue] = useState(initialValues);

  const organizationId = tinyUrl.split("o").pop().split("ent")[0];
  const entryId = tinyUrl.split("ent").pop().split("inf")[0];
  const promotorId = tinyUrl.split("inf").pop().split("c")[0];
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const [share, setShare] = useState(false);
  const [promotorShare, setPromotorShare] = useState(false);
  const [newPromotorId, setNewPromotorId] = useState();
  const [loading, setLoading] = useState(false);
  const [likeState, setLikeState] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [likeLoading, setLikeLoading] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);
  useEffect(() => {
    verifyUser();
    if (tinyUrlCustom?.endsWith("c")) {
      loadInfluencerAdsDetails(organizationId, entryId, promotorId);
    } else {
      loadJsonDetails();
    }
  }, []);
  useEffect(() => {
    if (reqForRegister && currentUser) {
      handleOpenRegistration(promotionDataJson);
      setReqForRegister(false);
    }
  }, [currentUser]);
  useEffect(() => {
    loadDetails();
  }, [currentUser]);
  const scrollDown = () => {
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
  };
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (!_.isEmpty(promotionDataJson)) {
      scrollDown();
      setTimeout(() => {
        scrollUp();
      }, "2000");
    }
  }, [promotionDataJson]);
  const loadJsonDetails = async () => {
    try {
      setLoading(true);

      const data = await axios.get(
        `${process.env.REACT_APP_GET_EVENT_JSON}/${organizationId}/${entryId}/eventDetails.json`
      );

      const promotionDataJson = data.data;
      setPromotionDataJson(promotionDataJson);
      setads(promotionDataJson?.ads?.ads);
      setCoupons(promotionDataJson?.discountCouponsF);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };
  const loadDetails = async () => {
    try {
      setLoading(true);
      setLikeLoading(true);
      const data = await axios.get(
        `${process.env.REACT_APP_PROMOTION_ENTRY_DETAILS}/${entryId}`
      );
      setPromotionData(data?.data?.data);
      const promotionData = data?.data?.data;
      setLikeCount(promotionData?.likesCount);
      setLoading(false);

      if (token) {
        let config1 = {
          method: "get",
          url: `${process.env.REACT_APP_PROMOTOR_LIKECHECK}/${promotionData?._id}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        const response1 = await axios(config1);
        setLikeState(response1.data?.liked);
        if (reqForLike) {
          likeHandle();
          setReqForLike(false);
        }
        ViewReporter(token);
      }
      setLikeLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    // navigate(`/signup`, { state: { promotionLogin: true } });
    setShowLogin(true);
  };
  function ShareHandle() {
    setShare(share ? false : true);
  }

  let promotorDetails = promotionData?.promotors?.find(
    (el) => el.promoterUserId == promotorId
  );
  const promotorDpOrName = () => {
    if (_.isEmpty(promotorDetails?.dop)) {
      return promotorDetails?.promotorName?.charAt(0);
    } else {
      return (
        <img
          src={promotorDetails?.dop}
          alt=""
          className="h-[72px] w-[72px] rounded-full object-cover"
        />
      );
    }
  };
  const isPromotor = () => {
    if (tinyUrl === promotorId) {
      return false;
    } else {
      return true;
    }
  };
  const ViewReporter = async (token) => {
    try {
      let config2 = {
        method: "post",
        url: `${process.env.REACT_APP_PROMOTOR_VIEW}/${tinyUrl}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      await axios(config2);
    } catch (error) {
      console.log("error", error);
    }
  };

  const likeHandle = () => {
    likeState == true ? setLikeState(false) : setLikeState(true);
    let likeCountLocal = likeCount;
    if (!likeState) {
      likeCountLocal = likeCountLocal + 1;
    } else {
      if (likeCountLocal == 0) {
        likeCountLocal = 0;
      } else {
        likeCountLocal = likeCountLocal - 1;
      }
    }
    setLikeCount(likeCountLocal);

    LikeApi();
  };
  const LikeApi = async () => {
    try {
      // Cancel the previous request (if any)
      if (cancelTokenSource !== null) {
        cancelTokenSource.cancel("Request canceled due to button click.");
      }

      // Create a new cancel token source

      cancelTokenSource = axios.CancelToken.source();
      let url = "";
      if (likeState) {
        url = `${process.env.REACT_APP_PROMOTOR_LIKE}/${tinyUrl}?command=disLike`;
      } else {
        url = `${process.env.REACT_APP_PROMOTOR_LIKE}/${tinyUrl}?command=like`;
      }
      let config3 = {
        method: "post",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        cancelToken: cancelTokenSource.token,
      };
      const response3 = await axios(config3);
      promotionData.isLiked = response3.data.isLiked;
    } catch (error) {
      console.log(error.message);

      //navigate("/error")
    }
  };
  const handlePromotorShareClick = (data) => {
    navigate(
      `/prou${promotionDataJson?.createdUserId}ent${promotionDataJson?._id}inf${data?.promoterUserId}`
    );
  };
  const [admin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (tinyUrl == promotorId) {
      setIsAdmin(true);
    }
  }, []);
  const handleOpenLink = (promotionData) => {
    let link = "";
    if (!admin) {
      link = `${promotionData?.showMoreHeader?.url}?p=${promotorId}`;
    } else {
      link = `${promotionData?.showMoreHeader?.url}`;
    }
    window.open(link);
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  const handleOpenForm = (promotionData) => {
    setServiceForm(true);
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  const handleOpenCoupon = (promotionData) => {
    setShowCoupon(true);
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  const handleProductPurchase = (promotionData) => {
    setShowPurchase(true);
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  const handleOpenRegistration = (promotionData) => {
    if (promotionData?.templateType == "COLLEGE") {
      if (currentUser) {
        setRegisterForm(true);
      } else {
        setReqForRegister(true);
        setShowLogin(true);
        setCustomLogin(promotionData?.loginBanner);
      }
    } else {
      setRegisterForm(true);
    }
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  const hanldeOpenCampaign = () => {
    let link = "";
    if (!admin) {
      link = `https://contest.goviral.world/${promotionDataJson?.campaignDetails?.orgDomain}/${promotionDataJson?.campaignDetails?.campaignUniqueText}.html?p=${promotorId}`;
    } else {
      link = `https://contest.goviral.world/${promotionDataJson?.campaignDetails?.orgDomain}/${promotionDataJson?.campaignDetails?.campaignUniqueText}.html`;
    }
    window.open(link);
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "knowMoreButton",
      "knowMoreButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  let colors = promotionDataJson?.themeColors;

  return (
    <>
      {serviceForm && (
        <ServiceForm
          service={service}
          setServiceForm={setServiceForm}
          promotionData={promotionDataJson}
        />
      )}
      {registerForm && (
        <RegistrationForm
          service={service}
          setServiceForm={setRegisterForm}
          promotionData={promotionDataJson}
        />
      )}
      {openDetail && (
        <DetailPage
          promotionData={promotionDataJson}
          setOpenDetail={setOpenDetail}
          handleOpenCoupon={handleOpenCoupon}
          handleProductPurchase={handleProductPurchase}
          handleClickCounterPromotion={handleClickCounterPromotion}
          handleOpenRegistration={handleOpenRegistration}
        />
      )}
      {showCoupon && (
        <CouponDetails
          setShowPopup={setShowCoupon}
          data={promotionDataJson?.showMoreHeader?.discountCoupons}
          hideCart={true}
        />
      )}
      {showPurchase && (
        <PurchasePopup
          setShowPopup={setShowPurchase}
          promotionData={promotionDataJson}
        />
      )}
      {share && <ShareInShare showShare={ShareHandle} />}
      {loading ? (
        <Lottie />
      ) : (
        <>
          {!openDetail && !_.isEmpty(promotionDataJson) && (
            <div
              className="bg-[#0C0C0D] w-full "
              style={{ background: colors?.secondary }}
            >
              {/* <button onClick={() => authentication.signOut()}>Logout</button> */}
              {promotionDataJson?.campaignPromotion ? (
                <CampaignPromotion
                  promotionDataJson={promotionDataJson}
                  setShare={setShare}
                  setOpenDetail={setOpenDetail}
                  likeState={likeState}
                  likeCount={likeCount}
                  handleLogin={handleLogin}
                  likeHandle={likeHandle}
                  likeLoading={likeLoading}
                  handleOpenLink={handleOpenLink}
                  handleOpenForm={handleOpenForm}
                  hanldeOpenCampaign={hanldeOpenCampaign}
                  inflencerDetails={inflencerDetails}
                  handleOpenCoupon={handleOpenCoupon}
                  handleProductPurchase={handleProductPurchase}
                />
              ) : (
                <NormalPromotion
                  promotionDataJson={promotionDataJson}
                  setShare={setShare}
                  setOpenDetail={setOpenDetail}
                  likeState={likeState}
                  likeCount={likeCount}
                  handleLogin={handleLogin}
                  likeHandle={likeHandle}
                  likeLoading={likeLoading}
                  handleOpenLink={handleOpenLink}
                  handleOpenForm={handleOpenForm}
                  handleOpenCoupon={handleOpenCoupon}
                  handleProductPurchase={handleProductPurchase}
                  handleOpenRegistration={handleOpenRegistration}
                />
              )}
            </div>
          )}
          {!openDetail &&
            !_.isEmpty(promotionDataJson) &&
            promotionDataJson?.galleryPlacement !== "bottom" &&
            !promotionDataJson?.hideGallery &&
            (promotionDataJson?.campaignPromotion ? (
              <>
                {promotionDataJson?.templateType == "DEFAULT" ? (
                  <PromotorsDetials
                    promotionDataJson={promotionDataJson}
                    setServiceForm={setServiceForm}
                    handleClickCounterPromotion={handleClickCounterPromotion}
                  />
                ) : (
                  <div className="template-company-bg w-full ">
                    <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] pb-[32px] md:pb-[140px] pt-[32px] md:pt-[60px] mx-auto">
                      <PromotorsDetialsCampaign
                        promotionDataJson={promotionDataJson}
                        setServiceForm={setServiceForm}
                        handleClickCounterPromotion={
                          handleClickCounterPromotion
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <PromotorsDetials
                promotionDataJson={promotionDataJson}
                setServiceForm={setServiceForm}
                handleClickCounterPromotion={handleClickCounterPromotion}
              />
            ))}
        </>
      )}
    </>
  );
}
