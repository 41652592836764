import React from "react";
import styles from "./PageNotFound.module.css";
import notFoundImage from "../../assests/notFound.png";
import { useSearchParams } from "react-router-dom";

export default function PageNotFound({ status }) {
  const [searchParams] = useSearchParams();
  const app = searchParams?.get("from") == "app";

  return (
    <div className={styles.mainDiv}>
      <div className={styles.container}>
        <div className="grid grid-cols-2 hidden sm:grid">
          <div className="flex flex-col gap-[50px] items-center justify-center">
            <h4>
              {app && status !== "USER_REMOVED"
                ? "Almost There!"
                : "Result Not Found"}
            </h4>
            <p>
              {app && status !== "USER_REMOVED"
                ? "We’re finalizing things for you. Please check back shortly!"
                : "The entry you’re looking for has been removed or deleted"}
            </p>
          </div>
          <div>
            <img src={notFoundImage} />
          </div>
        </div>
        <div className=" sm:hidden">
          <div className="flex flex-col gap-[20px] items-center justify-center">
            <h4>
              {app && status !== "USER_REMOVED"
                ? "Almost There!"
                : "Result Not Found"}
            </h4>
            <img src={notFoundImage} />
            <p>
              {app && status !== "USER_REMOVED"
                ? "We’re finalizing things for you. Please check back shortly!"
                : "The entry you’re looking for has been removed or deleted"}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
