import React, { useState } from "react";
import styles from "../PromotionBanner/PromotionBanner.module.css";
import ReactPlayer from "react-player";
import heart from "../../assests/heart.svg";
import * as _ from "lodash";
import whiteHeart from "../../assests/whiteHeart.svg";
import Play from "../../../src/assests/play.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import { Link } from "react-router-dom";

export default function DanceTemplate({
  promotionDataJson,
  setShare,
  setOpenDetail,
  likeState,
  likeCount,
  handleLogin,
  likeLoading,
  likeHandle,
  handleOpenForm,
  handleOpenLink,
}) {
  const [play, setPlay] = useState(false);
  const { currentUser, setReqForLike } = useUserAuth();
  const formImageRender = (innerWidth) => {
    if (
      !_.isEmpty(
        promotionDataJson?.bannerMobile || promotionDataJson?.bannerWeb
      )
    ) {
      if (innerWidth <= 600) {
        return promotionDataJson?.bannerMobile;
      } else {
        return promotionDataJson?.bannerWeb;
      }
    } else {
      return promotionDataJson?.fileUrl;
    }
  };
  return (
    <>
      <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto">
        <div className="md:h-[90vh] max-h-[790px] md:max-h-[686px]  relative overflow-hidden">
          <div className={styles.layout}>
            <div className={styles.left}>
              <div className="flex flex-col gap-[28px]">
                {/* <div className="flex items-center gap-[10px] md:gap-[6.3%]">
                <img
                  src={promotionDataJson?.eventHeader?.promoterLogo}
                  alt=""
                  className={styles.logo}
                />
                <div>
                  <p className={`text-[#CF0] ${styles.callout1}`}>
                    {promotionDataJson?.eventHeader?.promoterName}
                  </p>
                  <p className={`md:mt-[7px] ${styles.subtextMedium}`}>
                    {promotionDataJson?.eventHeader?.type}
                  </p>
                </div>
              </div> */}
                <div className=" mr-auto">
                  <img
                    src={promotionDataJson?.eventHeader?.promoterLogo}
                    alt=""
                    className="pro-logo"
                  />
                </div>
                <div className="flex md:flex-col gap-[28px] md:items-start items-center">
                  <h1 className="text-white pro-heading1">
                    {promotionDataJson?.eventHeader?.eventName}
                  </h1>
                  {!promotionDataJson?.campaignPromotion &&
                    promotionDataJson?.showMoreHeader?.display && (
                      <button
                        onClick={() =>
                          promotionDataJson?.showMoreHeader?.type == "link"
                            ? handleOpenLink(promotionDataJson)
                            : handleOpenForm(promotionDataJson)
                        }
                        className={`ml-0 md:ml-[0px] text-black ${styles.registerButton} ${styles.buttonText}`}
                      >
                        {promotionDataJson?.showMoreHeader?.name}
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div className="pro-right ">
              {promotionDataJson?.topMoreDetails && (
                <button
                  className={`${styles.buttonText} ${styles.moreDetailsOnTop} px-[20px] md:px-[39px] md:py-[12px] py-[7px]  rounded-[5px] absolute top-[30%] left-[30%]  md:hidden`}
                  onClick={() => setOpenDetail(true)}
                >
                  <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                    View Details
                  </p>
                </button>
              )}
              <div className="pro-right-innerDiv w-full h-[84%] md:h-full md:ml-[-3%] flex justify-center">
                {promotionDataJson?.fileType?.startsWith("video") ? (
                  (!play && (
                    <>
                      <div
                        onClick={() => setPlay(true)}
                        className="flex justify-center items-center  overflow-hidden"
                      >
                        <img
                          src={promotionDataJson?.thumbReplaceUrl}
                          alt=""
                          className="md:h-full h-auto w-full md:w-auto  object-contain "
                        />
                        <img
                          className="absolute h-[60px] w-[60px] cursor-pointer"
                          src={Play}
                          alt=""
                        />
                      </div>
                    </>
                  )) ||
                  (play && (
                    <div className="flex justify-center items-center w-[100%] md:px-[100px] h-[100%] overflow-hidden">
                      <ReactPlayer
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing={true}
                        controls={true}
                        width="100%"
                        height="100%"
                        playIcon={promotionDataJson?.thumbReplaceUrl}
                        url={promotionDataJson?.fileUrl}
                        className="object-contain height-[100%] abovethousand"
                      />
                      <ReactPlayer
                        config={{
                          file: {
                            attributes: { controlsList: "nodownload" },
                          },
                        }}
                        playing={true}
                        controls={true}
                        width="100%"
                        height="100%"
                        playIcon={promotionDataJson?.thumbReplaceUrl}
                        url={promotionDataJson?.fileUrl}
                        className="object-contain height-[100%] belowthousand"
                      />
                    </div>
                  ))
                ) : (
                  <img
                    src={formImageRender(window.innerWidth)}
                    className="md:h-full h-auto w-full md:w-auto  object-contain "
                  />
                )}
              </div>
              {(!promotionDataJson?.hideLike ||
                !promotionDataJson?.hideViewDetails) && (
                <div className="h-fit pt-[4%] px-[3%] flex gap-[3%] items-center justify-end">
                  {!promotionDataJson?.hideLike && (
                    <button
                      className={`${
                        styles.buttonText
                      }  flex gap-[16px] rounded-[5px] items-center justify-center px-[20px] md:px-[39px] md:min-w-[190px] min-w-[130px] md:py-[12px] py-[7px] transition duration-300 ease-in ${
                        likeState
                          ? "text-white bg-[#FF7F5B]"
                          : "text-black bg-[#CF0]"
                      }`}
                      onClick={
                        currentUser
                          ? () => {
                              setReqForLike(true);
                              likeHandle();
                            }
                          : () => handleLogin()
                      }
                      disabled={likeLoading}
                    >
                      {!likeLoading && (
                        <>
                          <span className="flex gap-[5px] items-center">
                            <p className={styles.callout1}>{likeCount} </p>

                            <img
                              src={likeState ? whiteHeart : heart}
                              className="w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
                            />
                          </span>
                          <span>{likeState ? "Liked" : "Like"}</span>
                        </>
                      )}
                      {likeLoading && <span className={styles.loader} />}
                    </button>
                  )}
                  {promotionDataJson?.topMoreDetails &&
                    !promotionDataJson?.hideViewDetails && (
                      <button
                        className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px] hidden md:block`}
                        onClick={() => setOpenDetail(true)}
                      >
                        <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                          View Details
                        </p>
                      </button>
                    )}
                  {!promotionDataJson?.topMoreDetails &&
                    !promotionDataJson?.hideViewDetails && (
                      <button
                        className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px] `}
                        onClick={() => setOpenDetail(true)}
                      >
                        <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                          View Details
                        </p>
                      </button>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {promotionDataJson?.enqueryDetails && (
        <div className="pro-enquiry">
          <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto flex flex-col gap-[15px] items-center">
            <div>
              <h2>{promotionDataJson?.enqueryDetails?.heading}</h2>
              <pre>{promotionDataJson?.enqueryDetails?.subHeading}</pre>
            </div>
            <h3>
              {promotionDataJson?.enqueryDetails?.subHeading1}{" "}
              <span>{promotionDataJson?.enqueryDetails?.subHeading2}</span>
            </h3>
            <Link
              to={promotionDataJson?.enqueryDetails?.whatsApp}
              target="_blank"
            >
              <button>
                <img src={promotionDataJson?.enqueryDetails?.btnIcon} />
                {promotionDataJson?.enqueryDetails?.btnText}
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
