import React from "react";
import styles from "./OfferBanner.module.css";
import starIcon from "./starIcon.svg";

export default function OfferBanner({ bgColor }) {
  return (
    <div className={styles.banner} style={{ backgroundColor: bgColor }}>
      <div className={styles["content-wrapper"]}>
        <div className={styles.content}>
          Exclusive Offers & Contents for you
        </div>
        <img className={styles.content} src={starIcon} />
        <div className={styles.content}>
          Exclusive Offers & Contents for you
        </div>{" "}
        <img className={styles.content} src={starIcon} />
        <div className={styles.content}>
          Exclusive Offers & Contents for you
        </div>{" "}
        <img className={styles.content} src={starIcon} />
        <div className={styles.content}>
          Exclusive Offers & Contents for you
        </div>{" "}
        <img className={styles.content} src={starIcon} />
        <div className={styles.content}>
          Exclusive Offers & Contents for you
        </div>{" "}
        <img className={styles.content} src={starIcon} />
        <div className={styles.content}>
          Exclusive Offers & Contents for you
        </div>{" "}
        <img className={styles.content} src={starIcon} />
      </div>
    </div>
  );
}
