import React, { useEffect } from "react";
import styles from "./MessagePopupReg.module.css";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { isEmpty } from "lodash";
import Insta from "../../assests/instagram.png";
import fbLogo from "../../assests/share/fbLogo.svg";
import twitterLogo from "../../assests/share/twitter.svg";

const MessagePopupReg = ({
  logo,
  loading,
  handleClick,
  heading,
  message,
  buttonText,
  socialMedias,
}) => {
  return (
    <div className="bg-white">
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          <img src={logo} alt="" className="w-[56px] h-[56px] mb-[20px]  " />
          <p className="text-regular-medium px-[20px] mb-[16px]">{heading}</p>
          <pre className="text-small text-[#CDCDCD] px-[20px]">{message}</pre>
          {!isEmpty(socialMedias) && (
            <>
              <div className="seperator my-[20px]" />
              <div className="flex gap-[50px] items-center">
                <p className="text-regular">Please give us a like on:</p>
                <div className="flex gap-[10px]">
                  {socialMedias?.fb && (
                    <a href={socialMedias?.fb} target="_blank">
                      <img
                        className=" cursor-pointer object-cover w-[24px] h-[24px]"
                        src={fbLogo}
                        alt=""
                      />
                    </a>
                  )}
                  {socialMedias?.instagram && (
                    <a href={socialMedias?.instagram} target="_blank">
                      <img
                        className=" cursor-pointer object-cover w-[24px] h-[24px]"
                        src={Insta}
                        alt=""
                      />
                    </a>
                  )}
                  {socialMedias?.twitter && (
                    <a href={socialMedias?.twitter} target="_blank">
                      <img
                        className=" cursor-pointer object-cover w-[24px] h-[24px]"
                        src={twitterLogo}
                        alt=""
                      />
                    </a>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="seperator my-[20px]" />
          <div className="flex flex-col gap-[16px] w-[100%] cursor-pointer">
            <button
              className={`text-regular-medium ${styles.button} disabled:opacity-40`}
              onClick={() => {
                handleClick();
              }}
              disabled={loading}
            >
              {loading ? (
                <div className="flex justify-center">
                  <div className="w-[25px] h-[25px] ">
                    <LoadingCircle />
                  </div>
                </div>
              ) : (
                buttonText || "OK"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePopupReg;
