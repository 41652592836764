import React, { useEffect, useState } from "react";
import styles from "./Carousel.module.css";
// import Right from "../../assests/popup/ryt.svg";
// import Left from "../../assests/popup/lftw.svg";
import ReactPlayer from "react-player";
import Right from "../../assests/moreDetailsRight.svg";
import Left from "../../assests/moreDetailsLeft.svg";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../assests/loading.json";

function Carousel({ items, index }) {
  const [currentItem, setCurrentItem] = useState(index ? index : 0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    setIsFading(true);
    const fadeTimeout = setTimeout(() => {
      setIsFading(false);
    }, 500); // Adjust the duration to your preference (in milliseconds)
    return () => clearTimeout(fadeTimeout);
  }, [items?.[currentItem]?.fileUrl]);

  const prevItem = () => {
    setCurrentItem((prev) => (prev === 0 ? items?.length - 1 : prev - 1));
  };

  const nextItem = () => {
    setCurrentItem((prev) => (prev === items?.length - 1 ? 0 : prev + 1));
  };

  const isPrevDisabled = currentItem === 0;
  const isNextDisabled = currentItem === items?.length - 1;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (currentItem != 0) {
      setIsLoading(true);
    }
  }, [currentItem]);
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselSlide}>
        {isLoading &&
          items &&
          items[currentItem]?.fileType.startsWith("image") && (
            <div className="w-full h-full flex items-center justify-center">
              <Lottie
                loop
                animationData={loadingAnimation}
                play
                style={{ width: 100, height: 100 }}
              />
            </div>
          )}
        {items && items[currentItem]?.fileType.startsWith("image") && (
          <img
            src={items[currentItem]?.fileUrl}
            alt={`Image `}
            className={`${styles.active} ${isFading ? styles.fadeOut : ""}`}
            style={{
              display: isLoading ? "none" : "block",
            }}
            onLoad={handleImageLoad}
          />
        )}
        {items && items[currentItem]?.fileType.startsWith("video") && (
          <ReactPlayer
            config={{
              file: { attributes: { controlsList: "nodownload" } },
              youtube: {
                playerVars: { controls: 1 },
              },
            }}
            playing={false}
            controls={true}
            width="100%"
            height="100%"
            url={items[currentItem]?.fileUrl}
            className={`${styles.active} ${isFading ? styles.fadeOut : ""}`}
          />
        )}
      </div>

      <button
        onClick={prevItem}
        disabled={isPrevDisabled}
        className={`left-0 ${styles.button}`}
      >
        <img src={Left} />
      </button>
      <button
        onClick={nextItem}
        disabled={isNextDisabled}
        className={`right-0 ${styles.button}`}
      >
        <img src={Right} />
      </button>
    </div>
  );
}

export default Carousel;
