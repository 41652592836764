import React, { useContext, useEffect, useState } from "react";
import regSuccess from "../assests/regSuccess.svg";
import regFailed from "../assests/regFailed.svg";
import { useParams } from "react-router";
import axios from "axios";
import MessagePopupReg from "../Components/MessagePopupReg/MessagePopupReg";
import { useUserAuth } from "../context/UserAuthContext";
import Context from "../context/Context";
import { useSearchParams } from "react-router-dom";
import SignupPage from "./SignupPage";

export default function RegistrationSuccess() {
  const { userIdToken, tinyUrlFromMetaData } = useContext(Context);
  const domain = window.location.href.split("/")[2];
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const organizationId = tinyUrl.split("o").pop().split("ent")[0];
  const entryId = tinyUrl.split("ent").pop().split("inf")[0];
  const [loading, setLoading] = useState(false);
  const [promotionDataJson, setPromotionDataJson] = useState({});
  const [paymenStatus, setPaymentStatus] = useSearchParams();
  let paymentCode = paymenStatus.get("code");
  const { regId } = useParams();

  // let paymenStatus = "PAYMENT_SUCCESS";
  const {
    currentUser,
    setPreviousLocation,
    showLogin,
    setCustomLogin,
    setShowLogin,
    retryPayment,
    setRetryPayment,
  } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;
  useEffect(() => {
    if (retryPayment) {
      setRetryPayment(false);
      initiatePayment();
    }
  }, [currentUser]);
  useEffect(() => {
    const gatewayRespInput = document.getElementById("gatewayresp");
    if (gatewayRespInput) {
      const gatewayRespValue = gatewayRespInput.value;
      if (paymentCode) {
        setPaymentStatus({ code: paymentCode });
      } else {
        setPaymentStatus({ code: gatewayRespValue });
      }
    }
  }, []);

  useEffect(() => {
    loadJsonDetails();
  }, []);

  const loadJsonDetails = async () => {
    try {
      setLoading(true);
      const data = await axios.get(
        `${process.env.REACT_APP_GET_EVENT_JSON}/${organizationId}/${entryId}/eventDetails.json`
      );
      const promotionDataJson = data.data;
      setPromotionDataJson(promotionDataJson);

      setLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    setShowLogin(true);
    setRetryPayment(true);
    setCustomLogin(promotionDataJson?.loginBanner);
  };

  const initiatePayment = async () => {
    try {
      setLoading(true);
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_INITIATE_PAYMENT_PHONEPE}/${organizationId}/${entryId}/${regId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        let paymentRedirectUrl = response?.data?.paymentRedirectUrl;
        let url =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo
            ?.url;
        handleRedirectToLink(url, paymentRedirectUrl);

        // window.open(url, "_self", "noopener,noreferrer");
      }
      setLoading(false);

      setLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleRetryPayment = () => {
    if (currentUser) {
      initiatePayment();
    } else {
      handleLogin();
    }
  };
  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.shootmates.com/thirdpartyPayment";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
  }
  const handleNavigate = () => {
    window.location.href = `https://${domain}/${tinyUrl}`;
  };

  let jsx = "";
  switch (paymentCode) {
    case "PAYMENT_SUCCESS":
      jsx = (
        <MessagePopupReg
          logo={regSuccess}
          handleClick={handleNavigate}
          heading={regId && `Your Reference ID : ${regId}`}
          message={
            promotionDataJson?.regSuccessResponse ||
            `Request successfully submitted. We'll get back to you soon!`
          }
          buttonText="OK"
          socialMedias={promotionDataJson?.socialMediaLinks}
        />
      );
      break;

    default:
      jsx = (
        <MessagePopupReg
          logo={regFailed}
          loading={loading}
          handleClick={handleRetryPayment}
          heading={regId && `Your Reference ID : ${regId}`}
          message={
            promotionDataJson?.regPendingResponse ||
            `Request successfully submitted. Make Payment to complete your registration!`
          }
          buttonText="Retry Payment"
          socialMedias={promotionDataJson?.socialMediaLinks}
        />
      );
      break;
  }
  return (
    <div>
      {showLogin && <SignupPage />}
      {jsx}
    </div>
  );
}
