import React, { useEffect, useState } from "react";
import axios from "axios";
import back from "../Assets/Back.svg";
import { useNavigate } from "react-router";

const ServiceTerms = ({ setSettings, setShowTerms }) => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  let count = 0;

  useEffect(() => {
    loadDetails();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const loadDetails = async () => {
    try {
      const data = await axios.get(
        // `${process.env.REACT_APP_TERMSANDCONDITION}.json`
        `/tandc.json`
      );
      setData(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full z-[1000]">
      <div className="overflow-x-hidden overflow-y-auto box absolute top-0 z-[1000] h-[1030px]  w-[100%]">
        <div className="flex flex-col text-[17px] bg-white px-[16px] pt-[30px] pb-[400px]">
          <img
            src={back}
            alt=""
            onClick={() => {
              setShowTerms(false);
            }}
            className=" h-[50px] w-[50px] mb-[30px] hover:cursor-pointer"
          />
          <span className="text-[21px] text-black pb-[17px]">
            {data?.heading}
          </span>
          <span className="pb-[10px] text-black">{data?.question}</span>
          <span className="pb-[17px] text-[#5073EF] text-[14px]">
            {data?.date}
          </span>
          <div className="flex flex-col text-[#787878]">
            {data &&
              data.termsAndConditions &&
              data.termsAndConditions.map((obj) => {
                count++;
                return (
                  <div className="flex">
                    <span>{count}.</span>
                    <span className="pb-[10px] pl-2">{obj.tandc}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTerms;
