import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { authentication } from "../store/firebase-config";
const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [contestImageGalleryContents, setContestImageGalleryContents] =
    useState([]);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [paymentFormDetails, setPaymentFormDetails] = useState({});
  const [paymentData, setpaymentData] = useState({});
  const ctstId = window.location.pathname.split("/")[3];
  const cmpId = window.location.pathname.split("/")[2];
  const [details, setDetails] = useState([]);
  const [contestContent, setContestContent] = useState([]);
  const [singleContestDetails, setSingleContestDetails] = useState({});
  const [campaignId, setcampaignId] = useState(cmpId);
  const [contestId, setContestId] = useState(ctstId);
  const [contestData, setContestData] = useState();
  const [showCase, setShowCase] = useState([]);
  const [data, setData] = useState("");
  const [pinData, setPinData] = useState({});
  const [terms, setTerms] = useState(false);
  const [contestRules, setContestRules] = useState(false);
  const [uploadSuccess, setUploadSucccess] = useState(false);
  const [campaignClosed, setCampaignClosed] = useState(false);
  const [campaignEntryClosed, setCampaignEntryClosed] = useState(false);
  const [uploadSuccessFile, setUploadSucccessFile] = useState({});
  const [isPrivate, setIsPrivate] = useState();
  const [campaignType, setCampaignType] = useState();
  const [openDetail, setOpenDetail] = useState(false);
  const [participantName, setParticipantName] = useState("");
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [campaignInfId, setCampaignInfId] = useState("");
  const [update, setUpdate] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [gallaryClick, setGallaryClick] = useState({});
  const [tinyUrlFromMetaData, setTinyUrlFromMetaData] = useState("");
  //const organizerName = window.location.href.split('/')[2].split('.')[0]
  const [whatsUpLink, setWhatsUpLink] = useState(
    "https://wa.me/message/6LCH37NEKLYIE1"
  );
  const organizerName = window.location.href.split("/")[3];
  const contestName = window.location.pathname.split("/")[3];
  const campaignName = window.location.pathname.split("/")[2];
  const campaignMetaData = {
    organizerName,
    campaignName,
    contestName,
  };
  useEffect(() => {
    setInterval(() => {
      verifyUser();
    }, 600000);
  }, []);

  // const organizerName = 'kalavathyteacher'
  const url = window.location.href.split("/")[3];
  const eventCheker = (obj) => {
    setContestData(obj);
  };

  const [limit, setLimit] = useState(3);
  const [userIdToken, setUserIdToken] = useState();

  const verifyUser = async () => {
    try {
      const idToken = await authentication.currentUser.getIdToken(true);
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Context.Provider
      value={{
        paymentFormDetails,
        contestImageGalleryContents,
        setContestImageGalleryContents,
        setPaymentFormDetails,
        contestData,
        setContestData,
        details,
        showCase,
        verifyUser,
        userIdToken,
        paymentData,
        setpaymentData,
        contestContent,
        singleContestDetails,
        setSingleContestDetails,
        setContestId,
        contestId,
        eventCheker,
        data,
        transactionDetails,
        setTransactionDetails,
        setData,
        terms,
        contestRules,
        limit,
        setLimit,
        setContestContent,
        uploadSuccess,
        setUploadSucccess,
        campaignClosed,
        setShowCase,
        uploadSuccessFile,
        setUploadSucccessFile,
        organizerName,
        setPinData,
        pinData,
        isPrivate,
        campaignType,
        campaignMetaData,
        whatsUpLink,
        setWhatsUpLink,
        campaignEntryClosed,
        openDetail,
        setOpenDetail,
        participantName,
        setParticipantName,
        selectedCoupons,
        setSelectedCoupons,
        campaignInfId,
        setCampaignInfId,
        update,
        setUpdate,
        successMessage,
        setSuccessMessage,
        gallaryClick,
        setGallaryClick,
        tinyUrlFromMetaData,
        setTinyUrlFromMetaData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
