import React, { useEffect, useRef, useState } from "react";
import styles from "./AddPersonPopup.module.css";
import PopupWrapper from "../../Layout/PopupWrapper";
import { isEmpty } from "lodash";
import prodBg from "../../assests/prodBg.png";
import Lottie from "react-lottie-player";
import animation from "../../assests/doneAnimation.json";
const AddPersonPopup = ({
  value,
  setPopup,
  handleAddNewPerson,
  familyName,
  handleChangeAmount,
  cartMap,
}) => {
  const [personData, setPersonData] = useState({
    customerName: "",
    birthStar: "",
    familyName: "",
  });
  const dropdownRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filterdNames, setFilteredNames] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const storedpersonData = JSON.parse(localStorage.getItem("personData")) || [];
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const storedpersonData =
      JSON.parse(localStorage.getItem("personData")) || [];
    setFilteredNames(storedpersonData);
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  console.log("filterdNames", filterdNames);
  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const handleChangeName = (event) => {
    const inputValue = event.target.value;
    // Filter country codes based on user input
    const newFilteredNames = storedpersonData?.filter((name) =>
      name?.customerName?.includes(inputValue)
    );
    setFilteredNames(newFilteredNames);
    setPersonData((prevState) => ({
      ...prevState,
      customerName: event.target.value,
    }));
    // Update the dropdown visibility
    setIsDropdownOpen(newFilteredNames.length > 0);

    // When the input is cleared, close the dropdown
    if (!inputValue) {
      setIsDropdownOpen(false);
    }
  };
  const handleSelectName = (data) => {
    setPersonData(data);
    setIsDropdownOpen(false);
  };
  const handleCollectUserInputs = (e) => {
    setPersonData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const product = value?.value;
  const productPrice = cartMap?.get(product?.productId)?.price;

  const handleAdd = () => {
    const errors = {};
    if (!personData.customerName) {
      errors.customerName = "This field is required";
    }
    if (!personData.birthStar && !familyName) {
      errors.birthStar = "Please Select a Birth Star";
    }
    if (familyName && (productPrice <= 0 || !productPrice)) {
      errors.price = "Please Enter a amount";
    }
    setFormErrors(errors);
    if (isEmpty(errors)) {
      localStorage.setItem(
        "personData",
        JSON.stringify([...storedpersonData, personData])
      );
      handleAddNewPerson(personData);
    }
  };

  const birthStars = [
    "അശ്വതി",
    "ഭരണി",
    "കാർത്തിക",
    "രോഹിണി",
    "മകയിരം",
    "തിരുവാതിര",
    "പുണർതം",
    "പൂയം",
    "ആയില്യം",
    "മകം",
    "പൂരം",
    "ഉത്രം",
    "അത്തം",
    "ചിത്തിര",
    "ചോതി",
    "വിശാഖം",
    "അനിഴം",
    "തൃക്കേട്ട",
    "മൂലം",
    "പൂരാടം",
    "ഉത്രാടം",
    "തിരുവോണം",
    "അവിട്ടം",
    "ചതയം",
    "പൂരുരുട്ടാതി",
    "ഉത്രട്ടാതി",
    "രേവതി",
  ];
  const prodCover = {
    backgroundImage: `url(${prodBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <PopupWrapper>
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          <div className={styles.header} style={prodCover}>
            <h2>Add details to book pooja</h2>
            <button
              onClick={() => {
                setPopup(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          {value?.added ? (
            <div className="flex flex-col items-center py-[30px] px-[30px] text-regular-bold">
              {animation && (
                <Lottie
                  loop
                  animationData={animation}
                  play
                  style={{ width: 50, height: 50 }}
                />
              )}
              <p className="mt-[20px]">Added to Cart</p>
            </div>
          ) : (
            <div className="p-[16px]">
              <form>
                {familyName && (
                  <div className="mb-[20px]">
                    <label>Amount</label>
                    <div className="grid grid-cols-4 gap-[5px] mt-[10px]">
                      {product?.priceArray?.map((el) => (
                        <button
                          className={`w-full p-[5px] border-2 border-[#6666661A] rounded-md ${
                            el == productPrice && "bg-[#6666661A]"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleChangeAmount(product, el);
                          }}
                        >
                          ₹ {el}
                        </button>
                      ))}
                    </div>
                    <input
                      className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                        formErrors?.price ? styles.errorOutline : ""
                      } ${styles.placeholder}`}
                      type="number"
                      id={product?.productId}
                      min="0"
                      placeholder="Enter Price Manually"
                      value={productPrice}
                      onChange={(e) => {
                        e.preventDefault();
                        handleChangeAmount(product, e.target.value);
                      }}
                    />
                    {formErrors?.price && (
                      <span className={styles.error}>
                        <span className="text-red-600">*</span>
                        {formErrors?.price}
                      </span>
                    )}
                  </div>
                )}
                <div>
                  <label>Name</label>
                  <div
                    className="w-full relative"
                    ref={dropdownRef}
                    onClick={toggleOpen}
                  >
                    <input
                      type="text"
                      id="customerName"
                      value={personData?.customerName}
                      className={`${
                        formErrors.customerName ? styles.errorOutline : ""
                      }`}
                      onChange={handleChangeName}
                      autoComplete="off"
                    />
                    {isDropdownOpen && (
                      <div className={styles.dropdownList}>
                        {filterdNames?.map((name) => (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectName(name);
                            }}
                            className="text-small-bold"
                          >
                            {name?.customerName} - {name?.birthStar}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {formErrors.customerName && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.customerName}
                    </span>
                  )}
                </div>
                {familyName && (
                  <div className="mt-[20px]">
                    <label>Family Name</label>
                    <input
                      type="text"
                      id="familyName"
                      value={personData?.familyName}
                      className={`${
                        formErrors.familyName ? styles.errorOutline : ""
                      }`}
                      onChange={handleCollectUserInputs}
                    />
                    {formErrors.familyName && (
                      <span className={styles.error}>
                        <span className="text-red-600">*</span>
                        {formErrors.familyName}
                      </span>
                    )}
                  </div>
                )}
                <div className="mt-[20px]">
                  <label>Birth Star</label>
                  <select
                    onChange={handleCollectUserInputs}
                    value={personData?.birthStar}
                    id="birthStar"
                    className={`${
                      formErrors.birthStar ? styles.errorOutline : ""
                    }`}
                  >
                    <option value="" disabled selected>
                      Select Birth Star
                    </option>
                    {birthStars?.map((el) => (
                      <option key={el} value={el} className="malayalam">
                        {el}
                      </option>
                    ))}
                  </select>
                  {formErrors.birthStar && (
                    <span className={styles.error}>
                      <span className="text-red-600">*</span>
                      {formErrors.birthStar}
                    </span>
                  )}
                </div>
              </form>
              <div className="mt-[20px] flex gap-[16px] w-[100%] cursor-pointer">
                <button
                  className={`text-regular-medium ${styles.button}`}
                  onClick={handleAdd}
                >
                  Add Pooja to your Cart
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </PopupWrapper>
  );
};

export default AddPersonPopup;
