import React, { useContext, useEffect, useRef, useState } from "react";
import MessagePopup from "../MessagePopup/MessagePopup";
import successTick from "../../assests/successTick.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import regFailed from "../../assests/regFailed.svg";
import Context from "../../context/Context";
import _, { isEmpty } from "lodash";
import axios from "axios";
import { idProofs } from "../../utils/common";
import uploadIcon from "../../assests/uploadIcon.svg";
import styles from "./Styles/DefaultTemplate.module.css";
import { leadsStorage } from "../../store/firebase-config";
import { ref, uploadBytesResumable } from "firebase/storage";
import { useNavigate } from "react-router";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import SubmitFormPopup from "../SubmitFormPopup/SubmitFormPopup";
import UploadPopup from "../UploadPopup/UploadPopup";

export default function DefaultTemplate({
  setShowTerms,
  promotionData,
  setServiceForm,
  mail,
  service,
}) {
  const [leadsSubmitted, setLeadsSubmitted] = useState(false);
  const {
    currentUser,
    setPreviousLocation,
    setShowLogin,
    reqForLeadsSubmit,
    setReqLeadsSubmit,
    userDetailsFromApi,
  } = useUserAuth();
  const { userIdToken, campaignInfId, tinyUrlFromMetaData } =
    useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const organizerId = tinyUrl.split("o").pop().split("c")[0];
  const campaignId = tinyUrl.split("p").pop().split("c")[0];
  let promotion = tinyUrl.startsWith("pro");
  let promotionOrgId = promotionData?.organizationId;
  let promotionEntryId = promotionData?._id;
  const navigate = useNavigate();
  const [referralCoupon, setReferralCoupon] = useState("");
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponResponse, setCouponResponse] = useState({});
  const [submitFormPopup, setSubmitFormPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState([]);
  console.log("tinyUrl", tinyUrl);

  const initialValues = {
    name: currentUser?.displayName || "",
    userEmail: currentUser?.email || "",
    email: mail,
    phone: "",
    tc: false,
    promotion: promotion,
    shareUrl: tinyUrl,
    infId: campaignInfId,
    leadType: "REGISTER",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const inputRef = useRef();
  const [source, setSource] = useState("");
  const [file, setFile] = useState();
  useEffect(() => {
    if (reqForLeadsSubmit) {
      handleSubmit();
      setReqLeadsSubmit(false);
    }
  }, [currentUser]);
  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhone = (number) => {
    let regexmobile =
      // eslint-disable-next-line no-useless-escape
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (number.match(regexmobile)) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    let validateResponse = validate(formValues);

    setFormErrors(validateResponse);
    if (
      _.isEmpty(validateResponse) &&
      source &&
      !_.isEmpty(formValues.name) &&
      formValues.tc === true
    ) {
      if (promotionData?.showMoreHeader?.regFee) {
        setSubmitFormPopup(true);
      } else {
        const response = await uploadGoviralMedias();
        submitFormApi(response);
      }
    }
  };

  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    localStorage.setItem("previousLocationLocal", window.location.pathname);
    setShowLogin(true);
    setReqLeadsSubmit(true);
  };

  const buttonColor = () => {
    if (promotion) {
      return {
        background: promotionData?.btnColor,
        color: promotionData?.btnTextColor,
      };
    } else {
      return { background: service?.btnColor, color: service?.btnTextColor };
    }
  };
  const validate = (values) => {
    const errors = {};
    if (_.isEmpty(values.name)) {
      errors.name = "This field is required";
    }
    if (!source) {
      errors.imageUrl = "Please upload your photo";
    }
    if (!values.idProofType) {
      errors.idProofType = "Please select a id";
    }
    if (!values?.idProofNumber) {
      errors.idProofNumber = "Please enter id number";
    }
    if (!validateEmail(values.userEmail)) {
      errors.userEmail = "Please enter a valid Email";
    }
    // if (!validatePhone(values.phone)) {
    //   errors.phone = "Please enter a valid phone number";
    // }

    if (values.tc === false) {
      errors.tc = "Please accept the terms and conditions ";
    }
    return errors;
  };
  const uploadGoviralMedias = async () => {
    setLoading(true);

    try {
      // add goviralMediaFiles path
      let randomId = Math.floor(Date.now() * Math.random());
      const folderPath = `/${promotionEntryId}/${userDetailsFromApi?.userId}_${randomId}`;
      const storageRef = ref(leadsStorage, folderPath);

      const uploadTask = uploadBytesResumable(storageRef, file);

      // Wait for the upload task to complete before proceeding to the next file
      await uploadTask;

      let fileUrl = "https://storage.googleapis.com/leads_media" + folderPath;
      return fileUrl;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const submitFormApi = async (uplodedMedia) => {
    setLoading(true);
    try {
      let dataToSubmit = {};
      if (_.isEmpty(formValues?.phone)) {
        dataToSubmit = {
          ...formValues,
          imageUrl: uplodedMedia,
          phone: currentUser?.phoneNumber,
        };
      } else {
        dataToSubmit = { ...formValues, imageUrl: uplodedMedia };
      }
      let config = {
        method: "post",
        url: promotion
          ? `${process.env.REACT_APP_REGISTER}/${promotionOrgId}/${promotionEntryId}`
          : `${process.env.REACT_APP_REGISTER}/${organizerId}/${campaignId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSubmit,
      };
      const response = await axios(config);
      setLoading(false);
      setLeadsSubmitted(true);
      // setServiceForm(false);
      navigate(`/${tinyUrl}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };
  const paymentHandler = async (e) => {
    try {
      setLoading(true);

      let payload = {};
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_INITIATE_PAYMENT}/${organizerId}/${promotionEntryId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: payload,
      };
      const response = await axios(config);
      setLoading(false);
      const paymentPayload = response?.data?.paymentPayload;
      var options = {
        key: process.env.REACT_APP_RAZOR_ID,
        amount: paymentPayload?.amount,
        currency: paymentPayload?.currency,
        name: "Goviral",
        image: "https://share.goviral.world/favicon.ico",
        order_id: paymentPayload?.id,
        handler: async function (response) {
          const responseFromUpload = await uploadGoviralMedias();
          submitFormApi(responseFromUpload);
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.log(response.error);
      });
      rzp1.open();
      e.preventDefault();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleMediaSelect = async (event) => {
    const image = new FormData();
    const imagedata = event.target.files[0];
    image.append("image", imagedata);
    let url = URL.createObjectURL(imagedata);
    setFile(imagedata);
    setSource(url);
  };
  const handleChoose = async () => {
    inputRef.current.click();
  };
  const message = "Request successfully submitted. We'll get back to you soon!";
  const handleCollectUserInputs = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const checkCoupon = async () => {
    setCouponLoading(true);
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_APPLY_COUPONCODE}/${promotionEntryId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          referralCoupon,
        },
      };
      const response = await axios(config);
      setCouponResponse({ ...response?.data, referralCoupon });
      setCouponLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  const submitAndPay = async () => {
    const responseFromUpload = await uploadGoviralMedias();
    if (responseFromUpload.error) {
      setErrorMessage("Error while uploading, Please try again");
      return;
    }
    submitFormApiPhonePe(responseFromUpload);
    setSubmitFormPopup(false);
  };
  const submitFormApiPhonePe = async (uplodedMedia) => {
    setLoading(true);
    try {
      let dataToSubmit = {
        ...formValues,
        imageUrl: uplodedMedia,
        finalAmt:
          promotionData?.showMoreHeader?.regFee?.general -
          (couponResponse?.discountAmt || 0),
        referralCoupon,
        tinyUrl,
      };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_REGISTER_PHONEPE}/${promotionOrgId}/${promotionEntryId}?type=general`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSubmit,
      };

      const response = await axios(config);
      if (response?.data?.status == "success") {
        let paymentRedirectUrl = response?.data?.paymentRedirectUrl;
        let url =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo
            ?.url;
        handleRedirectToLink(url, paymentRedirectUrl);
      }
      if (response?.data?.status == "fail") {
        setErrorMessage(response?.data?.msg);
      }

      setLoading(false);
      setProgress([]);
      // setLeadsSubmitted(true);
      // setServiceForm(false);
      // navigate(`/${tinyUrl}`);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.shootmates.com/thirdpartyPayment";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
  }
  return (
    <>
      {!isEmpty(progress) && <UploadPopup progress={progress} />}
      {leadsSubmitted && (
        <MessagePopup
          logo={successTick}
          setPopup={setLeadsSubmitted}
          setMainPopup={setServiceForm}
          message={message}
        />
      )}
      {submitFormPopup && (
        <SubmitFormPopup
          logo={successTick}
          setPopup={setSubmitFormPopup}
          heading={"Pay to Register"}
          message={
            "Entry pass for the event will only be issued after the payment has been completed, subject to verification by the admin."
          }
          buttonText={"Pay to Register"}
          handleSubmit={submitAndPay}
          loading={loading}
        />
      )}
      {errorMessage && (
        <MessagePopup
          logo={regFailed}
          setPopup={setErrorMessage}
          setMainPopup={setErrorMessage}
          heading={"Something went Wrong!"}
          message={errorMessage}
        />
      )}
      <div className="h-[100%] w-[100%] text-black px-[16px] md:px-[12%] py-[3%]">
        <p className="leadcardtext">
          Please fill out the following information to register.
        </p>
        <div className="mt-[25px]">
          <div className="mt-[20px]">
            <p>
              Name <span className="text-[#FA6B6B]">*</span>
            </p>
            <input
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                formErrors.name ? styles.errorOutline : ""
              }`}
              type="text"
              id="name"
              onChange={handleCollectUserInputs}
              value={formValues?.name}
            />
            {formErrors.name && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.name}
              </span>
            )}
          </div>
          {/* PHOTO */}
          <div className="flex flex-col gap-[10px] my-[20px]">
            <label htmlFor="imageUrl">
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                onChange={handleMediaSelect}
                accept="image/*"
                id="imageUrl"
              />
              Upload Your Photo <span className="text-red-600">*</span>
            </label>

            <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
              <div
                className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
          ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
          `}
                onClick={handleChoose}
              >
                {source ? (
                  <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                    <div className="h-[100%] w-auto overflow-hidden mx-auto">
                      <img
                        src={source}
                        className="outline-none w-auto h-[100%] object-cover"
                      />
                    </div>
                    <p>Change Photo</p>
                  </div>
                ) : (
                  <div>
                    <img src={uploadIcon} className="h-[80px] mx-auto" />
                    <p>Select Photo</p>
                  </div>
                )}
              </div>
            </div>
            {formErrors.imageUrl && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.imageUrl}
              </span>
            )}
          </div>
          {/* ID */}
          <div className="mt-[20px]">
            <p>
              ID <span className="text-[#FA6B6B]">*</span>
            </p>
            <select
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                formErrors.idProofType ? styles.errorOutline : ""
              }`}
              id="idProofType"
              onChange={handleCollectUserInputs}
              value={formValues?.idProofType}
            >
              <option selected disabled>
                Please Select an ID
              </option>
              {idProofs?.map((obj) => (
                <option value={obj?.id}>{obj?.displayText}</option>
              ))}
            </select>
            <p className="text-small">
              Please verify this ID card upon entering the event.
            </p>
            {formErrors.idProofType && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.idProofType}
              </span>
            )}
          </div>

          <div className="mt-[20px]">
            <p>
              ID Proof Number <span className="text-[#FA6B6B]">*</span>
            </p>
            <input
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                formErrors.idProofNumber ? styles.errorOutline : ""
              }`}
              type="text"
              id="idProofNumber"
              value={formValues?.idProofNumber}
              onChange={handleCollectUserInputs}
            />
            {formErrors.idProofNumber && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.idProofNumber}
              </span>
            )}
          </div>

          <div className="mt-[25px]">
            <p>
              Email <span className="text-[#FA6B6B]">*</span>
            </p>

            <input
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                formErrors.userEmail ? styles.errorOutline : ""
              } ${styles.placeholder}`}
              type="email"
              id="userEmail"
              value={formValues?.userEmail}
              placeholder="Enter email to receive additional information"
              onChange={handleCollectUserInputs}
            />
            {formErrors.userEmail && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.userEmail}
              </span>
            )}
          </div>
          <div className="mt-[25px]">
            <p>Phone Number</p>
            <input
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
               ${styles.placeholder} ${
                formErrors.phone ? styles.errorOutline : ""
              }
                `}
              type="text"
              placeholder="Share your number"
              id="phone"
              value={formValues?.phone}
              onChange={handleCollectUserInputs}
            />
            {formErrors.phone && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.phone}
              </span>
            )}
          </div>
        </div>

        {promotionData?.showMoreHeader?.regFee && (
          <>
            <div className="seperator-black mt-[30px] mb-[20px]" />
            <div className="mt-[25px]">
              <p className="text-regular-medium">Coupon Code</p>
              <div className="flex gap-[10px] items-center mt-[10px]">
                <input
                  className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] outline-none text-[14px]
                  ${styles.placeholder} ${
                    couponResponse.invalidReferral ? styles.errorOutline : ""
                  }`}
                  type="text"
                  placeholder="Enter the code and check eligibility."
                  id="referralCoupon"
                  value={referralCoupon}
                  onChange={(e) => setReferralCoupon(e.target.value)}
                />
                <button
                  style={buttonColor()}
                  className="text-small whitespace-nowrap h-full w-fit  px-[20px] py-[15px] rounded-md"
                  onClick={checkCoupon}
                >
                  {couponLoading ? <LoadingCircle /> : "Apply Coupon"}
                </button>
              </div>
              {couponResponse.invalidReferral ? (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  You entered an invalid code. Please check the code and try
                  again!
                </span>
              ) : (
                <span className={styles.error}>
                  {couponResponse?.discountAmt &&
                    `You're saving ${couponResponse?.discountAmt} on this registration`}
                </span>
              )}
            </div>
            <div className="mt-[25px] p-[25px] bg-[#eee]">
              <p className="text-regular-medium">Summary</p>
              <table className={styles.table}>
                <tr>
                  <td className="text-small">Original Price:</td>
                  <td className="text-small">
                    ₹{promotionData?.showMoreHeader?.regFee?.general}
                  </td>
                </tr>

                {couponResponse?.discountAmt && (
                  <tr>
                    <td className="text-small">Discounts:</td>
                    <td className="text-small">
                      -₹{couponResponse?.discountAmt}
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ padding: 0 }}>
                    <div className="seperator-black w-full" />
                  </td>
                  <td style={{ padding: 0 }}>
                    <div className="seperator-black w-full" />
                  </td>
                </tr>
                <tr>
                  <td className="text-small-bold">Total:</td>
                  <td className="text-small-bold">
                    ₹
                    {promotionData?.showMoreHeader?.regFee?.general -
                      (couponResponse?.discountAmt || 0)}
                  </td>
                </tr>
              </table>
            </div>
            <div className="seperator-black mt-[30px] mb-[20px]" />
          </>
        )}

        <div>
          <div className="flex justify-start gap-[10px] mt-[25px]">
            <input
              type="checkbox"
              id="tc"
              name="tc"
              value=""
              checked={formValues?.tc}
              onClick={() =>
                setFormValues((prev) => ({ ...prev, tc: !prev?.tc }))
              }
              className="w-[30px] h-[30px] rounded-[5px] "
            ></input>
            <label
              htmlFor="tc"
              className="text-[#171717] font-Inter font-[400] text-[14px] leading-[24px]"
            >
              Yes I agree to the{" "}
              <span
                className="text-blue-500 hover:cursor-pointer"
                onClick={() => setShowTerms(true)}
              >
                Terms & Conditions
              </span>{" "}
              . Hereby agree to be contacted for enquiry purpose of the same.
            </label>
          </div>
          {formErrors.tc && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.tc}
            </span>
          )}
        </div>
        <div className="w-[100%] flex justify-center mt-[30px]">
          {loading ? (
            <button
              className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center "
              disabled
              style={buttonColor()}
            >
              Submiting..
            </button>
          ) : (
            <button
              className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center"
              onClick={
                currentUser === null ? () => handleLogin() : handleSubmit
              }
              style={buttonColor()}
            >
              {promotionData?.showMoreHeader?.regFee && "Pay to"} Register
            </button>
          )}
        </div>
      </div>
    </>
  );
}
