import React from "react";
import styles from "./Styles/CompletionBanner.module.css";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import PopupWrapper from "../../Layout/PopupWrapper";

export default function CompletionBanner({
  promotionData,
  price,
  count,
  summaryRef,
  hideCart,
}) {
  const hostname = window.location.hostname;
  const parts = hostname?.split(".");
  const subDomain =
    parts?.length > 2 ? parts?.slice(0, parts?.length - 2).join(".") : null;
  const collection = subDomain == "collection";

  const viewSummary = () => {
    summaryRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const buttonColor = () => {
    return {
      background: promotionData?.btnColor,
      color: promotionData?.btnTextColor,
    };
  };

  const secondaryButton = () => {
    return {
      border: `1px solid ${promotionData?.btnColor}`,
      color: promotionData?.btnColor,
      background: promotionData?.btnTextColor,
    };
  };

  return (
    <PopupWrapper>
      {count > 0 && !hideCart && (
        <div className={styles.background}>
          <div className={styles.innerContainer}>
            <div className="">
              <h4>
                {count} Item{count > 1 && "s"} added{" "}
              </h4>
              <p className="mt-[4px]">Total cost: ₹{price}</p>
            </div>
            <button onClick={viewSummary}>
              View Cart
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z"
                  fill="white"
                />
              </svg>
            </button>
            {/* <div className="flex items-center gap-[5px]">
              {collection && (
                <button
                  onClick={() => onClick("COLLECTION")}
                  disabled={loading}
                  style={secondaryButton()}
                >
                  {loading ? <LoadingCircle /> : "Pay Later"}
                </button>
              )}
              <button
                onClick={() => onClick("NORMAL")}
                disabled={loading}
                style={buttonColor()}
              >
                {loading ? <LoadingCircle /> : "Pay and Submit"}
              </button>
            </div> */}
          </div>
        </div>
      )}
    </PopupWrapper>
  );
}
