import React from "react";
import styles from "./styles/Footer.module.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import logo from "../../assests/kalavedhamLogo.svg";
import FooterCampaignTemplate from "./FooterCampaignTemplate";
function Footer({ template }) {
  const BUILD_TYPE = process.env.REACT_APP_BUILD_TYPE;

  const navigate = useNavigate();
  let whatsappMessage =
    "https://wa.me/+919986619796?text=I%20would%20like%20to%20know%20more%20about%20Kalavedam";
  let renderData = <></>;
  switch (template) {
    case "DANCE":
      renderData = (
        <div className="bg-[#F9F9F9] w-full border-t-[1px] border-t-[#cdcdcd] text-[#000]">
          <div className="bg-[#F9F9F9] w-full pt-[20px] pb-[80px] md:py-[80px] max-w-[1440px] px-[20px] md:px-[8.3%] mx-auto">
            <div className="w-[100%] flex flex-col items-center gap-[20px] pro-footer">
              <div className="w-full flex flex-col items-center">
                <p>powered by</p>
                <img src={logo} />
              </div>
              <h2>
                Uniting artists, nurturing creativity, and celebrating diverse
                art forms
              </h2>

              <Link to={whatsappMessage} target="_blank">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_5262_41)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.68413 11.0132C8.43103 11.0132 10.6578 8.78638 10.6578 6.03948C10.6578 3.29259 8.43103 1.0658 5.68413 1.0658C2.93724 1.0658 0.710449 3.29259 0.710449 6.03948C0.710449 6.93151 0.945278 7.76869 1.35648 8.49257L0.710449 11.0132L3.30915 10.4106C4.01489 10.7948 4.82401 11.0132 5.68413 11.0132ZM5.68413 10.248C8.00844 10.248 9.89265 8.36379 9.89265 6.03948C9.89265 3.71521 8.00844 1.83098 5.68413 1.83098C3.35984 1.83098 1.47563 3.71521 1.47563 6.03948C1.47563 6.93691 1.75652 7.76872 2.23518 8.45179L1.85822 9.86538L3.29674 9.50575C3.9751 9.97388 4.79761 10.248 5.68413 10.248Z"
                        fill="#BFC8D0"
                      />
                      <path
                        d="M9.94746 5.68418C9.94746 8.03865 8.03877 9.94734 5.6843 9.94734C4.78627 9.94734 3.95307 9.66966 3.2659 9.19549L1.8087 9.55978L2.19056 8.12782C1.70568 7.43587 1.42114 6.59326 1.42114 5.68418C1.42114 3.3297 3.32982 1.42102 5.6843 1.42102C8.03877 1.42102 9.94746 3.3297 9.94746 5.68418Z"
                        fill="url(#paint0_linear_5262_41)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.68413 10.6579C8.43103 10.6579 10.6578 8.43115 10.6578 5.68426C10.6578 2.93736 8.43103 0.710571 5.68413 0.710571C2.93724 0.710571 0.710449 2.93736 0.710449 5.68426C0.710449 6.57629 0.945278 7.41346 1.35648 8.13735L0.710449 10.6579L3.30915 10.0553C4.01489 10.4396 4.82401 10.6579 5.68413 10.6579ZM5.68413 9.89277C8.00844 9.89277 9.89265 8.00856 9.89265 5.68426C9.89265 3.35996 8.00844 1.47575 5.68413 1.47575C3.35984 1.47575 1.47563 3.35996 1.47563 5.68426C1.47563 6.58169 1.75652 7.4135 2.23518 8.09656L1.85822 9.51016L3.29674 9.15052C3.9751 9.61865 4.79761 9.89277 5.68413 9.89277Z"
                        fill="white"
                      />
                      <path
                        d="M4.44076 3.37493C4.32253 3.13741 4.1411 3.15843 3.95785 3.15843C3.63034 3.15843 3.11963 3.55074 3.11963 4.28089C3.11963 4.87926 3.38331 5.53426 4.2718 6.51411C5.12926 7.45975 6.25595 7.94891 7.19128 7.93225C8.12662 7.91562 8.31906 7.1107 8.31906 6.83889C8.31906 6.71842 8.24428 6.65831 8.1928 6.64197C7.87406 6.48899 7.28617 6.20396 7.15242 6.15043C7.01866 6.09689 6.94881 6.16933 6.9054 6.20872C6.78411 6.32429 6.54367 6.66499 6.46132 6.74162C6.37901 6.81825 6.25626 6.77945 6.20518 6.7505C6.01721 6.67508 5.50758 6.44838 5.10138 6.05458C4.59896 5.56758 4.56948 5.4 4.47483 5.25086C4.39913 5.13153 4.45469 5.05835 4.4824 5.02634C4.59065 4.90146 4.74011 4.70863 4.80715 4.61281C4.87415 4.51696 4.82097 4.37148 4.78903 4.28089C4.65172 3.89117 4.53537 3.56493 4.44076 3.37493Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_5262_41"
                        x1="9.41456"
                        y1="2.48681"
                        x2="1.42114"
                        y2="9.94734"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#5BD066" />
                        <stop offset="1" stop-color="#27B43E" />
                      </linearGradient>
                      <clipPath id="clip0_5262_41">
                        <rect width="11.3684" height="11.3684" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Get in Touch
                </button>
              </Link>
            </div>
          </div>
        </div>
      );
      break;
    default:
      renderData = (
        <div className="bg-[#0c0c0d]">
          <div className="  w-full pt-[35px] pb-[80px] max-w-[1440px] px-[20px] md:px-[8.3%] mx-auto">
            <div className="w-[100%] flex flex-col items-center ">
              <div className="w-full text-center font-normal text-[12px] leading-[18px] text-[#787878] pb-[20px]">
                <div className="w-full">
                  <p>For similar services,contact:</p>
                  <p>Mobile/WhatsApp : +91 9986619796</p>
                  <p>
                    Email :{" "}
                    {BUILD_TYPE == "QUEUEEASY"
                      ? "hello@queueeasy.com"
                      : "support@goviral.world"}
                  </p>
                </div>
              </div>
              <div className="w-full h-[1px] bg-white opacity-[20%] my-[16px]" />
              <p className={styles.mailDesciption}>Powered By</p>
              {BUILD_TYPE == "QUEUEEASY" ? (
                <p>Queue Easy</p>
              ) : (
                <a href="https://goviral.world/" target="_blank">
                  <button className={styles.link}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M1.55556 0C0.705396 0 0 0.705396 0 1.55556V12.4444C0 13.2946 0.705396 14 1.55556 14H12.4444C13.2946 14 14 13.2946 14 12.4444V7H12.4444V12.4444H1.55556V1.55556H7V0H1.55556ZM8.55556 0V1.55556H11.3446L4.11675 8.78342L5.21658 9.88325L12.4444 2.65538V5.44444H14V0H8.55556Z"
                        fill=""
                      />
                    </svg>
                    www.goviral.world
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      );
    // <div className="bg-[#0C0C0D] w-full border-t-[1px] border-t-[#cdcdcd]">
    //   <div className="bg-[#0C0C0D] w-full py-[80px] max-w-[1440px] px-[20px] md:px-[8.3%] mx-auto">
    //     <div className="w-[100%] flex flex-col items-center gap-[27px]">
    //       <p className={styles.title}>Humanising Your Brand’s Experience</p>
    //       <p className={styles.desciption}>
    //         Entertain your customers with healthy performing art contests,
    //         celebrate the festivals with your customer family and gift them
    //         your coupons and discounts!
    //       </p>
    //       <p className={styles.viewGoviral}>support@goviral.world</p>
    //     </div>
    //   </div>
    // </div>
  }
  return <>{renderData}</>;
}

export default Footer;
