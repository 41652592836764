import React from "react";
import styles from "./QUseCases.module.css";
export default function QUseCases({ data }) {
  return (
    <div className={styles.useCases}>
      <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto ">
        <h2 className="mb-[20px]">{data?.heading}</h2>
        <pre>{data?.description}</pre>
        <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-[24px] overflow-auto hideScrollBar md:p-[20px] w-full mt-[20px]">
          {data?.steps?.map((obj, index) => (
            <div className="w-full flex flex-col justify-between gap-[30px] p-[20px] shrink-0">
              <div>
                <p className={styles.step}>STEP {index + 1}</p>
                <h3 className="mt-[10px]">{obj?.heading}</h3>
              </div>
              <div className={styles.graphics}>
                <img src={obj?.image} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
