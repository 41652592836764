import React, { useEffect, useState } from "react";
import "./../SharePopup/SharePopup.css";
import facebook from "../../assests/share/fbLogo.svg";
import whatsapp from "../../assests/share/whatsApp.svg";
import twitter from "../../assests/share/twitter.svg";
import email from "../../assests/share/email.svg";
import copyLinkImage from "../../assests/share/copyLink.svg";
import copySuccess from "../../assests/share/copySuccess.svg";
import download from "../../assests/share/download.svg";
import closebutton from "../../assests/closebutton.svg";
import LinkedIn from "../../assests/share/LinkedIn.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "./Share.module.css";
const ShareInShare = ({ showShare, fullData, type }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const userId = localStorage.getItem("userId");

  let copyText = "";
  if (userId && type == "sharepage") {
    copyText =
      "https://share3.goviral.world" +
      "/vidu" +
      userId +
      "o" +
      fullData.orgId +
      "cp" +
      fullData.campaignId +
      "cn" +
      fullData.contestId +
      "e" +
      fullData._id;
  } else {
    copyText = window.location.href;
  }

  function copyLink() {
    setCopied(true);
    setTimeout(() => {
      showShare();
    }, [1000]);
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");
    // alert(copyText + "\n copied to clipboard")
  }
  const whatsapplink = () => {
    const encodedContent = encodeURIComponent(copyText);
    const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(
      navigator.userAgent
    );
    if (isMobileDevice) {
      const url = `https://wa.me/?text=${encodedContent}`;
      window.open(url, "_blank");
    } else {
      const url = `https://web.whatsapp.com/send?text=${encodedContent}`;
      window.open(url, "_blank");
    }
  };
  const facebooklink = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${copyText}`);
    return false;
  };
  const shareToTwitter = () => {
    window.open(`http://twitter.com/share?url=${copyText}`);
  };
  const shareToEmail = () => {
    window.location.href = `mailto:?body=${copyText}`;
  };
  const shareToLinkedin = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${copyText}`
    );
  };
  const downloadQr = (e) => {
    // window.open(fullData?.qrCodeUrl)
    // e.preventDefault()

    fetch(fullData?.qrCodeUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={`fixed top-0 left-0 flex justify-center items-center h-[100vh] w-[100%] z-[999] px-[20px] ${styles.popupBackground}`}
    >
      <div className={`${styles.SharePopupParent}`}>
        <div className="relative w-[100%]">
          <div className="flex justify-between items-center w-[100%] p-[20px]">
            <p className="text-regular-bold text-[#CDCDCD]">
              Share with friends
            </p>
            <img
              src={closebutton}
              onClick={showShare}
              tooltip="Copy to clipboard"
              className=" hover:cursor-pointer"
            />
          </div>
        </div>
        <div className="seperatorBrandBg" />
        <div className="p-[20px] w-[100%]">
          <div className="flex justify-between w-[100%] text-[#CDCDCD] text-small">
            <div
              className="flex flex-col items-center gap-[6px]"
              onClick={facebooklink}
            >
              <img src={facebook} alt="" />
              <p>Facebook</p>
            </div>
            <div
              className="flex flex-col items-center gap-[6px]"
              onClick={whatsapplink}
            >
              <img src={whatsapp} alt="" />
              <p>Whatsapp</p>
            </div>
            <div
              className="flex flex-col items-center gap-[6px]"
              onClick={shareToTwitter}
            >
              <img src={twitter} alt="" />
              <p>Twitter</p>
            </div>
            <div
              className="flex flex-col items-center gap-[6px] cursor-pointer"
              onClick={shareToLinkedin}
            >
              <img src={LinkedIn} alt="" className="w-[56px] h-[56px]" />
              <p>Linkedin</p>
            </div>
          </div>
          <div className="seperator my-[24px]" />
          <div className="">
            <CopyToClipboard text={copyText}>
              <div
                className="flex flex-row items-center justify-between w-[100%] "
                onClick={copyLink}
                tooltip="Copy to clipboard"
              >
                <div className="w-[calc(100%-50px)]">
                  <p className="text-regular-medium">Copy the link </p>
                  <p className="text-small break-words text_1Line text-[#CDCDCD]">
                    {copyText}
                  </p>
                </div>
                <div className="w-[24px] h-[24px]">
                  {copied ? (
                    <img src={copySuccess} className="" />
                  ) : (
                    <img src={copyLinkImage} className="" />
                  )}
                </div>
              </div>
            </CopyToClipboard>
          </div>
          {fullData?.qrCodeUrl && (
            <div className="">
              <div className="seperator my-[24px]" />
              <div
                className="flex flex-row items-center gap-[10px] w-[100%] "
                onClick={downloadQr}
              >
                <div className="w-[24px] h-[24px]">
                  <img src={download} className="" />
                </div>
                <p className="text-regular-medium">
                  Download and share on Instagram{" "}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareInShare;
