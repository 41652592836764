import React from "react";

export default function CouponCode() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97"
      height="97"
      viewBox="0 0 97 97"
      fill="none"
    >
      <circle cx="48.5" cy="48.5" r="48.5" fill="#89C863" fill-opacity="0.34" />
      <path
        d="M64.1 63.9999H78.8V55.5999C75.23 55.5999 72.5 52.6599 72.5 49.2999C72.5 45.9399 75.23 42.9999 78.8 42.9999V34.5999H64.1"
        fill="#89C863"
        fill-opacity="0.34"
      />
      <path
        d="M64.1 63.9999H78.8V55.5999C75.23 55.5999 72.5 52.6599 72.5 49.2999C72.5 45.9399 75.23 42.9999 78.8 42.9999V34.5999H64.1"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.8 34.5999H20V42.9999C23.57 42.9999 26.3 45.9399 26.3 49.2999C26.3 52.6599 23.57 55.5999 20 55.5999V63.9999H57.8"
        fill="#89C863"
        fill-opacity="0.34"
      />
      <path
        d="M57.8 34.5999H20V42.9999C23.57 42.9999 26.3 45.9399 26.3 49.2999C26.3 52.6599 23.57 55.5999 20 55.5999V63.9999H57.8"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1 34.5999V38.7999"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1 59.7997V63.9997"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.5999 42.9999H53.5999"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.1 51.4H53.6"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.0001 22V26.2"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1 45.1004V53.5004"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.4002 26.2002L68.3002 28.3002"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.6002 26.2002L55.7002 28.3002"
        stroke="black"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
