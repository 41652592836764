import React, { useContext } from "react";
import styles from "../Share/Share.module.css";
import swipe from "../../assests/swipe-left.svg";
import Template1 from "../AdsTemplate/Template1/Template1";
import Template2 from "../AdsTemplate/Template2/Template2";
import Template3 from "../AdsTemplate/Template3/Template3";
import Template4 from "../AdsTemplate/Template4/Template4";
import Context from "../../context/Context";
import { useUserAuth } from "../../context/UserAuthContext";
import Template6 from "../AdsTemplate/Template6/Template6";
import Template5 from "../AdsTemplate/Template5/Template5";
import { isEmpty } from "lodash";
import Template7 from "../AdsTemplate/Template7/Template7";
import Template8 from "../AdsTemplate/Template8/Template8";
import Template9 from "../AdsTemplate/Template9/Template9";

const Ads = ({
  setServiceForm,
  promotion,
  ads,
  setService,
  setDetailsPopup,
  handleClickCounterPromotion,
  handleClickCounterCampaign,
  promotionEntryId,
  fontLinks,
}) => {
  const handleOpenForm = (add) => {
    setService(add);
    setServiceForm(true);
    if (promotion) {
      handleClickCounterPromotion(
        add.brandId,
        promotionEntryId,
        add.videoName,
        add.name,
        add.brandName,
        "ads",
        "promotion"
      );
    } else {
      handleClickCounterCampaign(
        add.brandId,
        add.videoName,
        add.name,
        add.brandName,
        "ads",
        "campaign"
      );
    }
  };
  const handleOpenDetails = (add) => {
    setService(add);
    setDetailsPopup(true);
    if (promotion) {
      handleClickCounterPromotion(
        add.brandId,
        promotionEntryId,
        add.videoName,
        add.name,
        add.brandName,
        "ads",
        "promotion"
      );
    } else {
      handleClickCounterCampaign(
        add.brandId,
        add.videoName,
        add.name,
        add.brandName,
        "ads",
        "campaign"
      );
    }
  };
  const handleLinkClick = (add) => {
    window.open(add.goto, "_blank");
    if (promotion) {
      handleClickCounterPromotion(
        add.brandId,
        promotionEntryId,
        add.videoName,
        add.name,
        add.brandName,
        "ads",
        "promotion"
      );
    } else {
      handleClickCounterCampaign(
        add.brandId,
        add.videoName,
        add.name,
        add.brandName,
        "ads",
        "campaign"
      );
    }
  };
  return (
    <div className={`flex flex-col justify-center   font-normal  `}>
      {fontLinks && <style>@import url({fontLinks});</style>}
      {/* so called carousel */}
      {ads &&
        ads?.map((carousel) => {
          return (
            <>
              {isEmpty(carousel.tempType) && (
                <Template1
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleLinkClick={handleLinkClick}
                />
              )}
              {carousel.tempType === "TEMP_2" && (
                <Template2
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleLinkClick={handleLinkClick}
                />
              )}

              {carousel.tempType === "TEMP_3" && (
                <Template3
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleLinkClick={handleLinkClick}
                />
              )}
              {carousel.tempType === "TEMP_4" && (
                <Template4
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleLinkClick={handleLinkClick}
                />
              )}
              {carousel.tempType === "TEMP_5" && (
                <Template5
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleLinkClick={handleLinkClick}
                />
              )}
              {carousel.tempType === "TEMP_6" && (
                <Template6
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleLinkClick={handleLinkClick}
                />
              )}
              {carousel.tempType === "TEMP_7" && (
                <Template7
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleLinkClick={handleLinkClick}
                  handleOpenDetails={handleOpenDetails}
                />
              )}
              {carousel.tempType === "TEMP_8" && (
                <Template8
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleOpenDetails={handleOpenDetails}
                  handleLinkClick={handleLinkClick}
                />
              )}
              {carousel.tempType === "TEMP_9" && (
                <Template9
                  carousel={carousel}
                  handleOpenForm={handleOpenForm}
                  handleOpenDetails={handleOpenDetails}
                  handleLinkClick={handleLinkClick}
                />
              )}
            </>
          );
        })}
    </div>
  );
};

export default Ads;
