import React from "react";

import { useNavigate } from "react-router";
function FooterCampaignTemplate() {
  const navigate = useNavigate();
  const shareToEmail = () => {
    window.location.href = `mailto:support@goviral.world`;
  };
  return (
    <div className="template-footer">
      <div className=" template-footer w-full pt-[35px] pb-[80px] max-w-[1440px] px-[20px] md:px-[8.3%] mx-auto">
        <div className="w-[100%] flex flex-col items-center ">
          <p className="template-footer-mailDesciption">Powered By</p>
          <a href="https://goviral.world/" target="_blank">
            <button className="template-footer-mail">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M1.55556 0C0.705396 0 0 0.705396 0 1.55556V12.4444C0 13.2946 0.705396 14 1.55556 14H12.4444C13.2946 14 14 13.2946 14 12.4444V7H12.4444V12.4444H1.55556V1.55556H7V0H1.55556ZM8.55556 0V1.55556H11.3446L4.11675 8.78342L5.21658 9.88325L12.4444 2.65538V5.44444H14V0H8.55556Z"
                  fill=""
                />
              </svg>
              www.goviral.world
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterCampaignTemplate;
