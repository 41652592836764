import React, { useContext, useEffect, useRef, useState } from "react";
import close from "../../assests/closebuttonblack.png";
import styles from "./EditRegistration.module.css";
import uploadIcon from "../../assests/uploadIcon.svg";
import regFailed from "../../assests/regFailed.svg";
import { isEmpty } from "lodash";
import UploadPopup from "../UploadPopup/UploadPopup";
import { ref, uploadBytesResumable } from "firebase/storage";
import { leadsStorage } from "../../store/firebase-config";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import axios from "axios";
import MessagePopup from "../MessagePopup/MessagePopup";

const EditRegistration = ({ setPopup, details }) => {
  const { userIdToken, setUpdate, setSuccessMessage, tinyUrlFromMetaData } =
    useContext(Context);

  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const [formValues, setFormValues] = useState(details || {});
  const [changedValue, setChangedValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const imageUrlRef = useRef();
  const collegeIdRef = useRef();
  const aadharIdRef = useRef();
  const [source, setSource] = useState(details || {});
  const [progress, setProgress] = useState([]);
  const [file, setFile] = useState([]);
  const { currentUser, userDetailsFromApi } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  let promotion = tinyUrl.startsWith("pro");
  const [showTerms, setShowTerms] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleMediaSelect = async (event) => {
    const image = new FormData();
    let id = event.target.id;
    const imagedata = event.target.files[0];
    image.append("image", imagedata);
    let url = URL.createObjectURL(imagedata);
    setFile((prevState) => [...prevState, { id, fileData: imagedata }]);
    setSource((prevState) => ({ ...prevState, [id]: url }));
  };

  const handleChoose = async () => {
    imageUrlRef.current.click();
  };
  const handleChooseCollegeId = async () => {
    collegeIdRef.current.click();
  };
  const handleChooseIdProofImage = async () => {
    aadharIdRef.current.click();
  };

  const handleCollectUserInputs = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const uploadGoviralMedias = async () => {
    setLoading(true);
    try {
      let medias = {};
      for (let i = 0; i < file.length; i++) {
        const fileData = file[i];
        let randomId = Math.floor(Date.now() * Math.random());
        const folderPath = `/${details?.promotionId}/${userDetailsFromApi?.userId}_${randomId}`;
        const storageRef = ref(leadsStorage, folderPath);

        const uploadTask = uploadBytesResumable(storageRef, fileData?.fileData);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const uploadprogress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[i] = uploadprogress;

              return newProgress;
            });

            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
            }
          },
          (error) => {
            throw error;
            // Handle unsuccessful uploads
          }
        );
        // Wait for the upload task to complete before proceeding to the next file
        await uploadTask;

        let fileUrl = "https://storage.googleapis.com/leads_media" + folderPath;
        medias[fileData?.id] = fileUrl;
      }
      return medias;
    } catch (error) {
      console.error(error);
      setLoading(false);
      setProgress([]);

      return { error: true };
    }
  };
  const submitFormApi = async (uplodedMedia) => {
    setLoading(true);
    try {
      let dataToSubmit = {
        ...changedValue,
        ...uplodedMedia,
      };
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_EDIT_REGISTERATION_DETAILS}/${details?.orgId}/${details?.promotionId}/${details?.regId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSubmit,
      };
      const response = await axios(config);

      setLoading(false);
      setPopup(false);
      setUpdate((prev) => prev + 1);
      setProgress([]);
      setSuccessMessage("Submitted");
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSubmit = async () => {
    const responseFromUpload = await uploadGoviralMedias();
    if (responseFromUpload.error) {
      setErrorMessage("Error while uploading, Please try again");
      return;
    }
    submitFormApi(responseFromUpload);
  };

  return (
    <>
      {errorMessage && (
        <MessagePopup
          logo={regFailed}
          setPopup={setErrorMessage}
          setMainPopup={setErrorMessage}
          heading={"Something went Wrong!"}
          message={errorMessage}
        />
      )}
      {!isEmpty(progress) && (
        <UploadPopup
          progress={progress}
          message="Submitting Details - Admin will review soon"
        />
      )}
      <div className="w-[100%] h-[100%] fixed top-0 flex flex-col items-center justify-center serviceformbg backdrop-blur-xl z-[50] overflow-x-hidden overflow-y-auto no-scrollbar">
        <div className=" absolute top-0 px-[3%] w-full">
          <div className="h-auto  box w-full w-full max-w-[800px]  bg-[#FFFFFF] rounded-[20px] my-10 mx-auto">
            <div className="w-[100%] h-[30%] relative rounded-t-[20px] overflow-hidden w-full ">
              <div
                className=" rounded-full bg-white h-[48px] w-[48px] flex justify-center items-center hover:cursor-pointer ml-auto"
                onClick={() => setPopup(false)}
              >
                <img src={close} alt="" className="h-[16px] w-[16px] " />
              </div>

              <div className="px-[20px] md:px-[25px] text-black">
                {formValues?.regType == "outsider" && (
                  <>
                    {/* NAME */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.name ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="name"
                        value={formValues?.name}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.name && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.name}
                        </span>
                      )}
                    </div>
                    {/* PHOTO */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={imageUrlRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="imageUrl"
                        />
                        Upload Your Photo{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChoose}
                        >
                          {source?.imageUrl ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.imageUrl}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.imageUrl && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.imageUrl}
                        </span>
                      )}
                    </div>
                    {/*COLLEGE NAME */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        College Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeName ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeName"
                        value={formValues?.collegeName}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeName && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeName}
                        </span>
                      )}
                    </div>
                    {/* COLLEGE ID */}
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        College ID Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeIdNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeIdNumber"
                        value={formValues?.collegeIdNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeIdNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdNumber}
                        </span>
                      )}
                    </div>
                    {/* COLLEGE ID UPLOAD */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={collegeIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="collegeIdImage"
                        />
                        Upload College Id{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseCollegeId}
                        >
                          {source?.collegeIdImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.collegeIdImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.collegeIdImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdImage}
                        </span>
                      )}
                    </div>
                    {/* ID */}
                    {/* <div className="mt-[20px]">
                <p className="text-regular-medium">
                  ID <span className="text-[#FA6B6B]">*</span>
                </p>
                <select
                  className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                    formErrors.idProofType ? styles.errorOutline : ""
                  }`}
                  id="idProofType"
                  onChange={handleCollectUserInputs}
                  value={formValues?.idProofType}
                >
                  <option selected disabled>
                    Please Select an ID
                  </option>
                  {idProofs?.map((obj) => (
                    <option value={obj?.id}>{obj?.displayText}</option>
                  ))}
                </select>
                <p className="text-small">
                  Please verify this ID card upon entering the event.
                </p>
                {formErrors.idProofType && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.idProofType}
                  </span>
                )}
              </div> */}
                    {/* ID PROOF NUMBER */}
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        Aadhaar Card Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.idProofNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="idProofNumber"
                        value={formValues?.idProofNumber}
                        onChange={handleCollectUserInputs}
                      />
                      <p className="text-small">
                        Please verify this ID card upon entering the event.
                      </p>
                      {formErrors.idProofNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofNumber}
                        </span>
                      )}
                    </div>
                    {/* AAdhar ID UPLOAD */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={aadharIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="idProofImage"
                        />
                        Upload Aadhaar Card{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.idProofImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseIdProofImage}
                        >
                          {source?.idProofImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.idProofImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.idProofImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofImage}
                        </span>
                      )}
                    </div>
                  </>
                )}

                {formValues?.regType == "insider" && (
                  <>
                    {/* NAME */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.name ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="name"
                        onChange={handleCollectUserInputs}
                        value={formValues?.name}
                      />
                      {formErrors.name && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.name}
                        </span>
                      )}
                    </div>
                    {/* PHOTO */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={imageUrlRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="imageUrl"
                        />
                        Upload Your Photo{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                    ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                    `}
                          onClick={handleChoose}
                        >
                          {source?.imageUrl ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.imageUrl}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.imageUrl && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.imageUrl}
                        </span>
                      )}
                    </div>
                    {/* COLLEGE ID */}
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        College ID Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeIdNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeIdNumber"
                        value={formValues?.collegeIdNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeIdNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdNumber}
                        </span>
                      )}
                    </div>
                    {/* COLLEGE ID UPLOAD */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={collegeIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="collegeIdImage"
                        />
                        Upload College Id{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseCollegeId}
                        >
                          {source?.collegeIdImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.collegeIdImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.collegeIdImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdImage}
                        </span>
                      )}
                    </div>
                  </>
                )}

                {formValues?.regType == "general" && (
                  <>
                    {/* NAME */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Guardian Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.name ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="name"
                        value={formValues?.name}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.name && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.name}
                        </span>
                      )}
                    </div>
                    {/* OCCUPATION */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">Guardian Occupation</p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.occupation ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="occupation"
                        value={formValues?.occupation}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.occupation && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.occupation}
                        </span>
                      )}
                    </div>
                    {/* PHOTO */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={imageUrlRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="imageUrl"
                        />
                        Upload Your Photo{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChoose}
                        >
                          {source?.imageUrl ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.imageUrl}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.imageUrl && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.imageUrl}
                        </span>
                      )}
                    </div>
                    {/* ID PROOF NUMBER */}
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        Aadhaar Card Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.idProofNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="idProofNumber"
                        value={formValues?.idProofNumber}
                        onChange={handleCollectUserInputs}
                      />
                      <p className="text-small">
                        Please verify this ID card upon entering the event.
                      </p>
                      {formErrors.idProofNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofNumber}
                        </span>
                      )}
                    </div>
                    {/* AAdhar ID UPLOAD */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={aadharIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="idProofImage"
                        />
                        Upload Aadhaar Card{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.idProofImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseIdProofImage}
                        >
                          {source?.idProofImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.idProofImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.idProofImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofImage}
                        </span>
                      )}
                    </div>

                    {/*WARD NAME */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Ward Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.wardName ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="wardName"
                        value={formValues?.wardName}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.wardName && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.wardName}
                        </span>
                      )}
                    </div>
                    {/*Relation eith ward */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Relationship with Ward
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.relation ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="relation"
                        value={formValues?.relation}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.relation && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.relation}
                        </span>
                      )}
                    </div>
                    {/*COLLEGE NAME */}
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        College Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeName ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeName"
                        value={formValues?.collegeName}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeName && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeName}
                        </span>
                      )}
                    </div>
                    {/* COLLEGE ID */}
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        Ward College ID Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeIdNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeIdNumber"
                        value={formValues?.collegeIdNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeIdNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdNumber}
                        </span>
                      )}
                    </div>
                    {/* COLLEGE ID UPLOAD */}
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={collegeIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="collegeIdImage"
                        />
                        Upload Ward College ID{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseCollegeId}
                        >
                          {source?.collegeIdImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.collegeIdImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.collegeIdImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdImage}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>

              <button
                className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px] bg-[#bb134f] mx-auto mb-[25px] rounded-full flex justify-center items-center"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Submiting.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRegistration;
