import React, { useContext, useEffect, useState } from "react";
import "./../SharePopup/SharePopup.css";

import link from "../../assests/linkblack.svg";
import facebook from "../../assests/facebookblack.svg";
import whatsapp from "../../assests/whatsappblack.svg";
import closebutton from "../../assests/closebuttonblack.png";
import loadingAnimation from "../../assests/loading.json";
import qr from "../../assests/qr.svg";
// import styles from '../SharePopup/SharePopup.module.css'
import { CopyToClipboard } from "react-copy-to-clipboard";
import proShareBg from "../../assests/proShareBg.jpg";
import axios from "axios";
import Context from "../../context/Context";
import { useUserAuth } from "../../context/UserAuthContext";
import _, { isEmpty } from "lodash";
import Lottie from "react-lottie-player";
const PromoterSharePopup = ({
  setPromotorShare,
  fullData,
  promotionData,
  influencerData,
  influencerFormValue,
  setInfluencerFormValue,
  createInfluencer,
  generateLinkLoading,
  getInfDetails,
  handleLogin,
}) => {
  const a = window.location.href.split("/")[2];
  const { userIdToken, verifyUser } = useContext(Context);
  const { currentUser } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    verifyUser();
    if (!isEmpty(currentUser)) {
      getInfDetails();
      if (influencerData?.newInfluencer) {
        loadUserDetails();
      }
    }
  }, [currentUser]);
  const loadUserDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_PROFILE_DETAILS}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      let dataFromApi = response?.data?.data;
      setInfluencerFormValue((prevState) => ({
        ...prevState,
        name: dataFromApi?.name,
        email: dataFromApi?.email,
        phone: dataFromApi?.phone,
      }));
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };

  let copyText =
    "https://" +
    a +
    "/" +
    "pro" +
    promotionData?.organizationId +
    "ent" +
    promotionData?._id +
    "inf" +
    influencerData?.userId;

  function copyLink() {
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");

    // alert(copyText + "\n copied to clipboard")
    setPromotorShare(false);
  }
  const whatsapplink = () => {
    window.open("whatsapp://send?text= " + copyText);
  };
  const facebooklink = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${copyText}`);
    return false;
  };
  const downloadQr = (e) => {
    // window.open(fullData?.qrCodeUrl)
    // e.preventDefault()
    fetch(fullData?.qrCodeUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("infl", influencerFormValue);
  const handleChange = (e) => {
    e.preventDefault();
    setInfluencerFormValue((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleChangeSocialmedialink = (e) => {
    e.preventDefault();
    setInfluencerFormValue((prevState) => ({
      ...prevState,
      socialmedialink: {
        ...prevState?.socialmedialink,
        [e.target.id]: e.target.value,
      },
    }));
  };
  const handleSubmit = async () => {
    let errors = {};

    if (_.isEmpty(influencerFormValue?.name)) {
      errors.name = "Please enter your name";
    }
    if (_.isEmpty(influencerFormValue?.email)) {
      errors.email = "Please enter your email";
    }
    if (_.isEmpty(influencerFormValue?.infOrgName)) {
      errors.infOrgName = "Please enter your / organization name";
    }
    if (_.isEmpty(influencerFormValue?.about)) {
      errors.about =
        "Please enter a short discription about yourself / organizaton";
    }
    setErrorMessage(errors);
    if (_.isEmpty(errors)) {
      await createInfluencer();
    }
  };
  return (
    <div className="fixed top-0 h-[100%] w-[100%]  backdrop-blur-xl serviceformbg overflow-y-auto">
      <div className="flex flex-col bg-[#FFFFFF] mx-auto my-[150px] shadow-lg z-[15000] text-black w-[358px] sm:w-[461px] rounded-lg items-center h-auto">
        <div
          className="relative w-[100%] rounded-lg"
          style={{ backgroundImage: "url(" + proShareBg + ")" }}
        >
          <div className="flex justify-center w-[100%] h-[60px]">
            {/* <p className="py-[30px]">Share to...</p> */}
          </div>
          <div className="absolute top-6 right-4 hover:cursor-pointer h-[32px] w-[32px] rounded-full bg-white flex items-center justify-center ">
            <img
              src={closebutton}
              onClick={() => setPromotorShare(false)}
              tooltip="Copy to clipboard"
              className="flex h-[14px] w-[14px]"
            />
          </div>
        </div>
        <div className="text-center p-[20px]">
          {promotionData?.infMessage && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${promotionData?.infMessage}`,
                }}
              />
              <div className="w-[100%] mt-[10px] h-[1px] bg-black opacity-60"></div>
            </>
          )}

          {/* <p className="font-semibold text-[18px] sm:text-[21px]">
            Dear Promoter
          </p>
          <p className="font-medium mt-[20px] text-[14px] sm:text-[16px]">
            Looks like you liked this product!
          </p> */}
          {generateLinkLoading ? (
            <div className="w-full h-full  flex items-center justify-center">
              <Lottie
                loop
                animationData={loadingAnimation}
                play
                style={{ width: 100, height: 100 }}
              />
            </div>
          ) : (
            <>
              {(influencerData?.newInfluencer ||
                influencerData?.isPromoterFound == false) && (
                <p className="mt-[20px] text-[14px] sm:text-[16px]">
                  Please fill the form below to create an affiliate link. Once
                  the request is approved by the administrator, you will receive
                  the link via email
                </p>
              )}
              {influencerData?.approvedByAdmin == false && (
                <p className="mt-[20px] text-[14px] sm:text-[16px]">
                  Thank you for submitting your request to create an affiliate
                  link. Once the request is approved by the administrator, you
                  will receive the link via email
                </p>
              )}

              {influencerData?.approvedByAdmin && (
                <p className="mt-[20px] text-[14px] sm:text-[16px]">
                  Please copy your share-url and recommend this to your friends
                  and circles so that they also get benefitted. Your
                  recommendations will be appreciated by the product and you can
                  contact us to know the benefits.
                </p>
              )}
              {!currentUser && (
                <p className="mt-[20px] text-[14px] sm:text-[16px]">
                  To promote this campaign and become an influencer, please sign
                  in to our platform.
                </p>
              )}
            </>
          )}
        </div>

        {currentUser ? (
          <>
            <div className="w-[100%] h-[1px] bg-black opacity-60"></div>
            {influencerData?.approvedByAdmin && (
              <div className="px-[16px] py-[15px] w-[100%]">
                <div className="">
                  <div className="flex items-center" onClick={facebooklink}>
                    <img
                      src={facebook}
                      alt=""
                      className="h-[24px] w-[24px] mr-[10px]"
                    />
                    <button
                      className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start"
                      tooltip="Copy to clipboard"
                    >
                      Share to Facebook
                    </button>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center" onClick={whatsapplink}>
                    <img
                      src={whatsapp}
                      alt=""
                      className="h-[24px] w-[24px] mr-[10px]"
                    />
                    <button
                      className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start"
                      tooltip="Copy to clipboard"
                    >
                      Share to Whatsapp
                    </button>
                  </div>
                </div>
                <div className="">
                  <CopyToClipboard value={copyText}>
                    <div className="flex items-center" onClick={copyLink}>
                      <img
                        src={link}
                        alt=""
                        className="h-[24px] w-[24px] mr-[10px]"
                      />
                      <button
                        className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start"
                        tooltip="Copy to clipboard"
                      >
                        Copy Link
                      </button>
                    </div>
                  </CopyToClipboard>
                </div>
                {fullData?.qrCodeUrl && (
                  <div className="">
                    <div
                      className="flex items-center hover:cursor-pointer"
                      onClick={downloadQr}
                    >
                      <img
                        src={qr}
                        alt=""
                        className="h-[24px] w-[24px] mr-[10px]"
                      />
                      <button
                        className="h-[100%] w-[100%] py-[15px] text-black items-center hover:cursor-pointer flex justify-start no-underline"
                        tooltip="Copy to clipboard"
                      >
                        Download and share in Instagram
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {(influencerData?.newInfluencer ||
              influencerData?.isPromoterFound == false) &&
              !generateLinkLoading && (
                <div className="px-[16px] pb-[15px]  w-[100%]">
                  <div className="influencerShareGrid mt-[15px]">
                    <p>
                      Name <span className="text-[#FA6B6B]">*</span>
                    </p>
                    <input
                      type="text"
                      id="name"
                      onChange={handleChange}
                      value={influencerFormValue.name}
                      className={errorMessage?.name ? "error" : ""}
                    />
                  </div>
                  {errorMessage?.name && (
                    <p className="text-[#FA6B6B] text-smallest mt-[5px]">
                      {errorMessage?.name}
                    </p>
                  )}
                  <div className="influencerShareGrid mt-[15px]">
                    <p>
                      Email <span className="text-[#FA6B6B]">*</span>
                    </p>
                    <input
                      type="text"
                      id="email"
                      onChange={handleChange}
                      value={influencerFormValue.email}
                      className={errorMessage?.email ? "error" : ""}
                    />
                  </div>
                  {errorMessage?.email && (
                    <p className="text-[#FA6B6B] text-smallest mt-[5px]">
                      {errorMessage?.email}
                    </p>
                  )}
                  <div className="mt-[15px] inputFullLength influencerShareGrid">
                    <p>
                      Name your ( Company/Marketing Agency/College
                      Ambassador/Individual ){" "}
                      <span className="text-[#FA6B6B]">*</span>
                    </p>
                    <input
                      type="text"
                      id="infOrgName"
                      onChange={handleChange}
                      value={influencerFormValue.infOrgName}
                      className={errorMessage?.infOrgName ? "error" : ""}
                    />
                  </div>
                  {errorMessage?.infOrgName && (
                    <p className="text-[#FA6B6B] text-smallest mt-[5px]">
                      {errorMessage?.infOrgName}
                    </p>
                  )}
                  <div className="influencerShareGrid mt-[15px] inputFullLength">
                    <p>
                      Tell us something about you?{" "}
                      <span className="text-[#FA6B6B]">*</span>
                    </p>
                    <div className="relative">
                      <textarea
                        type="text"
                        id="about"
                        maxlength="500"
                        onChange={handleChange}
                        value={influencerFormValue.about}
                        className={`no-scrollbar ${
                          errorMessage?.about ? "error" : ""
                        }`}
                      />
                      <span className="text-black w-[98%] absolute flex bottom-2 justify-end opacity-[.6]">
                        {influencerFormValue?.about?.length}/500
                      </span>
                    </div>
                  </div>
                  {errorMessage?.about && (
                    <p className="text-[#FA6B6B] text-smallest mt-[5px]">
                      {errorMessage?.about}
                    </p>
                  )}
                  <div className="influencerShareGrid mt-[15px]">
                    <p>Instagram</p>
                    <input
                      type="text"
                      id="instagram"
                      onChange={handleChangeSocialmedialink}
                      value={influencerFormValue?.socialmedialink?.instagram}
                    />
                  </div>
                  <div className="influencerShareGrid mt-[15px]">
                    <p>Facebook</p>
                    <input
                      type="text"
                      id="facebook"
                      onChange={handleChangeSocialmedialink}
                      value={influencerFormValue?.socialmedialink?.facebook}
                    />
                  </div>
                  <div className="influencerShareGrid mt-[15px]">
                    <p>Website</p>
                    <input
                      type="text"
                      id="website"
                      onChange={handleChangeSocialmedialink}
                      value={influencerFormValue?.socialmedialink?.website}
                    />
                  </div>
                  <div className="w-[100%] mt-[10px] flex justify-end">
                    <button
                      className="px-[12px] py-[6px] bg-black text-white rounded-2xl"
                      onClick={handleSubmit}
                      disabled={generateLinkLoading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
          </>
        ) : (
          <div className="my-[30px] w-full flex items-center justify-center">
            <button
              className="px-[12px] py-[6px] bg-black text-white rounded-2xl"
              onClick={handleLogin}
              disabled={generateLinkLoading}
            >
              Sign in to continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoterSharePopup;
