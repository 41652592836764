import React, { useEffect, useState } from "react";
import styles from "./EntryCarousel.module.css";
import Right from "../../assests/moreDetailsRight.svg";
import Left from "../../assests/moreDetailsLeft.svg";
import ReactPlayer from "react-player";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../assests/loading.json";
import FullScreenViewer from "../FullScreenViewer/FullScreenViewer";
import FullScreenIcon2 from "../../assests/svg/FullScreenIcon2";
function EntryCarousel({ items, thumbanil, currentItem, setCurrentItem }) {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const prevItem = () => {
    setCurrentItem((prev) => (prev === 0 ? items?.length - 1 : prev - 1));
  };

  const nextItem = () => {
    setCurrentItem((prev) => (prev === items?.length - 1 ? 0 : prev + 1));
  };

  const isPrevDisabled = currentItem === 0;
  const isNextDisabled = currentItem === items?.length - 1;

  // const backgroundImage = {
  //   background: `url(${
  //     items && items[currentItem]?.fileType.startsWith("video")
  //       ? thumbanil
  //       : items[currentItem]?.fileUrl
  //   }) no-repeat top center`,
  // };
  const backgroundImage = () => {
    if (
      items &&
      items[currentItem] &&
      items[currentItem]?.fileType.startsWith("video")
    ) {
      return {
        background: `url(${thumbanil}) no-repeat center center / cover`,
      };
    } else {
      return {
        background: `url(${
          items && items[currentItem] && items[currentItem]?.fileUrl
        })no-repeat center center / cover`,
      };
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (currentItem != 0) {
      setIsLoading(true);
    }
  }, [currentItem]);
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselSlide} style={backgroundImage()}>
        <div className={styles.bgBlur}>
          {isLoading &&
            items &&
            items[currentItem]?.fileType.startsWith("image") && (
              <div className="w-full h-full min-h-[50vh] flex items-center justify-center">
                <Lottie
                  loop
                  animationData={loadingAnimation}
                  play
                  style={{ width: 100, height: 100 }}
                />
              </div>
            )}
          {items && items[currentItem]?.fileType.startsWith("image") && (
            <img
              src={items[currentItem]?.fileUrl}
              alt={`Image `}
              className={styles.active}
              onLoad={handleImageLoad}
              style={{
                display: isLoading ? "none" : "block",
              }}
            />
          )}
          {items && items[currentItem]?.fileType.startsWith("video") && (
            <ReactPlayer
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              playing={false}
              controls={true}
              width="100%"
              height="100%"
              url={items[currentItem]?.fileUrl}
              className={styles.active}
            />
          )}
        </div>
      </div>

      <button
        onClick={prevItem}
        disabled={isPrevDisabled}
        className={`left-0 ${styles.button}`}
      >
        <img src={Left} />
      </button>
      <button
        onClick={nextItem}
        disabled={isNextDisabled}
        className={`right-0 ${styles.button}`}
      >
        <img src={Right} />
      </button>
      {items && items[currentItem]?.fileType.startsWith("image") && (
        <button
          className="absolute bottom-[12px] right-[20px] p-[4px] rounded-full bg-black/10 backdrop-blur-lg"
          onClick={() => setShowFullScreen(true)}
        >
          <FullScreenIcon2 />
        </button>
      )}
      {showFullScreen && (
        <FullScreenViewer
          setShowPopup={setShowFullScreen}
          items={items}
          index={currentItem}
        />
      )}
    </div>
  );
}

export default EntryCarousel;
