/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import styles from "./Searching.module.css";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../assests/loading.json";
import PopupWrapper from "../../Layout/PopupWrapper";

function Searching({ message }) {
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [seconds]);

  return (
    <PopupWrapper>
      <div className=" bg-white flex flex-col items-center w-full h-[100vh] fixed overflow-y-auto top-0 z-[100]">
        <div className="min-h-[80vh] md:min-h-[90vh] ">
          <div className="min-h-[70vh] md:min-h-[80vh] flex flex-col justify-center items-center px-[35px]">
            {seconds > 0 ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="152"
                  height="32"
                  viewBox="0 0 152 32"
                  fill="none"
                >
                  <path
                    d="M0 31.0904C0.692102 27.2868 1.3709 23.5521 2.03638 19.8863C2.27595 18.3428 2.54215 16.7442 2.83496 15.0904C3.12777 13.4091 3.40727 11.7554 3.67347 10.1292C3.93966 8.50302 4.19254 6.93196 4.43212 5.41602C4.67169 3.87253 4.88465 2.46684 5.07098 1.19897L11.5395 0.744186C11.087 2.67356 10.6344 4.68562 10.1819 6.78036C9.75598 8.87511 9.35669 10.9147 8.98402 12.8992L12.937 12.4444C13.3097 10.4875 13.6424 8.59948 13.9352 6.78036C14.228 4.93368 14.614 3.03187 15.0932 1.07494C15.7054 0.964686 16.3842 0.881999 17.1295 0.826875C17.8749 0.744187 18.567 0.675281 19.2058 0.620157C19.8447 0.565031 20.3771 0.523687 20.803 0.496125C21.2555 0.441001 21.4818 0.413439 21.4818 0.413439C21.1091 1.65375 20.7498 3.01809 20.4037 4.50646C20.0843 5.96727 19.7649 7.49699 19.4454 9.09561C19.126 10.6667 18.8199 12.2653 18.5271 13.8915C18.2342 15.5177 17.968 17.0612 17.7285 18.522C17.1428 22.0224 16.5972 25.6055 16.0914 29.2713L9.86246 30.0155C10.2085 28.3618 10.5146 26.7494 10.7808 25.1783C11.047 23.8553 11.3132 22.4772 11.5794 21.0439C11.8722 19.6107 12.0985 18.3842 12.2582 17.3643L8.38509 17.7364C8.19875 18.7838 7.97249 19.9966 7.7063 21.3747C7.46672 22.7528 7.24046 24.062 7.0275 25.3023C6.73469 26.7907 6.44188 28.2791 6.14906 29.7674L0 31.0904Z"
                    fill="#EE5E5E"
                  />
                  <path
                    d="M31.9984 30.1809C31.3329 30.2911 30.6009 30.4152 29.8023 30.553C29.0037 30.6908 28.285 30.8148 27.6461 30.9251C27.7526 30.0155 27.8591 29.1197 27.9656 28.2377C28.0454 27.466 28.112 26.6942 28.1652 25.9225C28.2451 25.1507 28.3116 24.4892 28.3649 23.938H24.891L23.2939 29.8915C22.7083 29.9742 22.0162 30.0706 21.2176 30.1809C20.419 30.2636 19.6737 30.3463 18.9816 30.4289C18.1297 30.5392 17.2912 30.6494 16.466 30.7597C17.5308 27.0939 18.5823 23.4832 19.6204 19.9276C20.0463 18.4393 20.4855 16.882 20.9381 15.2558C21.4172 13.6296 21.8831 12.0172 22.3356 10.4186C22.8147 8.79242 23.2673 7.23514 23.6932 5.74677C24.1191 4.2584 24.4918 2.89406 24.8112 1.65375C26.6213 1.51594 28.458 1.35056 30.3214 1.15762C32.2114 0.964686 34.0481 0.702843 35.8316 0.372093C35.7517 1.25409 35.6586 2.35659 35.5521 3.67959C35.4722 4.97502 35.3924 6.39449 35.3125 7.93798C35.2327 9.48148 35.1528 11.0939 35.0729 12.7752C34.9931 14.4565 34.9132 16.1103 34.8334 17.7364C34.7003 21.5401 34.5539 25.5779 34.3941 29.8501C33.5956 29.9879 32.797 30.0982 31.9984 30.1809ZM25.8493 19.8036C25.9558 19.8036 26.1422 19.8036 26.4083 19.8036C26.7012 19.7761 26.994 19.7623 27.2868 19.7623C27.6062 19.7347 27.899 19.7209 28.1652 19.7209C28.4314 19.6934 28.6178 19.6658 28.7242 19.6382C28.7242 19.4729 28.7375 19.1972 28.7642 18.8114C28.8174 18.3979 28.8573 17.9432 28.8839 17.447C28.9372 16.9233 28.9904 16.3583 29.0437 15.7519C29.0969 15.1456 29.1501 14.553 29.2034 13.9742C29.3099 12.5685 29.443 11.0525 29.6027 9.42636L29.8422 5.82946L29.2433 5.8708L28.3249 9.42636L25.8493 19.8036Z"
                    fill="#EE5E5E"
                  />
                  <path
                    d="M34.8181 31.2145C35.5634 27.3282 36.2821 23.5383 36.9742 19.845C37.2404 18.3015 37.5199 16.6891 37.8128 15.0078C38.1322 13.2989 38.425 11.6314 38.6912 10.0052C38.9574 8.35142 39.197 6.76658 39.4099 5.25065C39.6495 3.70715 39.8358 2.30147 39.9689 1.03359C40.7941 0.978467 41.5927 0.923342 42.3647 0.868218C43.0568 0.813094 43.7622 0.77175 44.4809 0.744186C45.1996 0.689062 45.8385 0.633937 46.3975 0.578811C46.7435 2.70112 47.0497 4.72696 47.3159 6.65633C47.449 7.4832 47.582 8.35142 47.7151 9.26098C47.8749 10.143 48.0213 10.9974 48.1544 11.8243C48.2875 12.6512 48.4206 13.4505 48.5537 14.2222C48.6867 14.9664 48.8198 15.6279 48.9529 16.2067C49.0594 15.6279 49.1792 14.9664 49.3123 14.2222C49.4454 13.4505 49.5918 12.6512 49.7515 11.8243C49.9112 10.9699 50.071 10.1016 50.2307 9.21964C50.417 8.33764 50.59 7.4832 50.7497 6.65633C51.149 4.72696 51.5616 2.7149 51.9875 0.620157L56.8589 0C55.9538 4.13437 55.1553 8.04823 54.4631 11.7416C54.1437 13.3402 53.8243 14.9526 53.5049 16.5788C53.212 18.205 52.9325 19.7761 52.6663 21.292C52.4002 22.8079 52.1739 24.2136 51.9875 25.509C51.8012 26.7769 51.6548 27.8519 51.5483 28.7339C51.4685 28.7339 51.149 28.8028 50.59 28.9406C50.0576 29.0508 49.4321 29.1886 48.7134 29.354C48.0213 29.5194 47.3159 29.6985 46.5971 29.8915C45.905 30.0568 45.386 30.1947 45.0399 30.3049C44.8536 28.0723 44.6539 25.95 44.441 23.938C44.3611 23.0835 44.2679 22.2016 44.1615 21.292C44.055 20.3824 43.9485 19.5142 43.842 18.6873C43.7622 17.8605 43.669 17.0887 43.5625 16.3721C43.4827 15.6279 43.4028 15.0078 43.323 14.5116C43.2431 15.0078 43.1366 15.6141 43.0035 16.3307C42.8704 17.0474 42.7373 17.8191 42.6042 18.646C42.4711 19.4453 42.338 20.286 42.2049 21.168C42.0718 22.0224 41.9254 22.863 41.7657 23.6899C41.4197 25.6469 41.047 27.6865 40.6477 29.8088L34.8181 31.2145Z"
                    fill="#EE5E5E"
                  />
                  <path
                    d="M74.3927 15.2558C74.2064 18.0672 73.674 20.5202 72.7955 22.615C71.9171 24.6822 70.8523 26.4186 69.6012 27.8243C68.3767 29.23 67.0458 30.2773 65.6083 30.9664C64.1975 31.6555 62.8532 32 61.5755 32C60.5906 32 59.6456 31.7795 58.7405 31.3385C57.8621 30.8975 57.0901 30.236 56.4247 29.354C55.7858 28.4444 55.28 27.3144 54.9074 25.9638C54.5347 24.6133 54.3484 23.0284 54.3484 21.2093C54.3484 17.6537 54.8408 14.553 55.8257 11.907C56.8106 9.26098 58.0484 7.05599 59.5391 5.29199C61.0564 3.52799 62.6669 2.21878 64.3705 1.36434C66.1008 0.509907 67.7113 0.0826887 69.2019 0.0826887C70.2667 0.0826887 71.1851 0.275626 71.957 0.6615C72.7556 1.04737 73.4078 1.57106 73.9136 2.23256C74.4193 2.8665 74.7787 3.62446 74.9916 4.50646C75.2312 5.3609 75.351 6.27046 75.351 7.23514L69.8807 8.31008C69.6412 7.20758 69.3217 6.42205 68.9224 5.95349C68.5231 5.48493 68.0706 5.25065 67.5648 5.25065C66.8994 5.25065 66.1673 5.74677 65.3688 6.73902C64.5702 7.7037 63.8248 8.9578 63.1327 10.5013C62.4406 12.0448 61.855 13.7399 61.3759 15.5866C60.8967 17.4332 60.6305 19.2524 60.5773 21.0439C60.5773 22.7528 60.8168 23.9793 61.296 24.7235C61.8018 25.4401 62.4406 25.7985 63.2126 25.7985C63.745 25.7985 64.2907 25.6606 64.8497 25.385C65.4353 25.1094 65.9544 24.7235 66.4069 24.2274C66.8594 23.7037 67.2321 23.1111 67.5249 22.4496C67.8443 21.7606 68.0174 21.0164 68.044 20.2171L63.7716 20.8786L64.5302 16.7855C65.7814 16.6202 67.0058 16.4548 68.2037 16.2894C69.2152 16.1516 70.28 15.9862 71.398 15.7933C72.5427 15.6003 73.5409 15.4212 74.3927 15.2558Z"
                    fill="#EE5E5E"
                  />
                  <path
                    d="M102.756 8.64083C102.756 10.9009 102.516 13.037 102.037 15.0491C101.584 17.0336 100.972 18.8665 100.2 20.5478C99.4283 22.2015 98.5232 23.6899 97.4851 25.0129C96.4735 26.3084 95.4088 27.4246 94.2907 28.3618C93.1727 29.2713 92.0414 29.9742 90.8968 30.4703C89.7522 30.9388 88.6608 31.1731 87.6226 31.1731C86.7442 31.1731 85.9456 31.0078 85.2269 30.677C84.5082 30.3187 83.8826 29.8088 83.3502 29.1473C82.8178 28.4582 82.3919 27.59 82.0725 26.5426C81.7797 25.4677 81.6333 24.186 81.6333 22.6977C81.6333 21.1542 81.8196 19.528 82.1923 17.8191C82.5649 16.1103 83.0707 14.4152 83.7096 12.7339C84.3484 11.0525 85.1337 9.45392 86.0654 7.93798C86.9971 6.42205 88.0219 5.08527 89.1399 3.92765C90.2579 2.74246 91.4558 1.80534 92.7335 1.11628C94.0113 0.427219 95.3422 0.0826887 96.7264 0.0826887C97.9243 0.0826887 98.9092 0.344532 99.6812 0.868218C100.48 1.36434 101.092 2.02584 101.518 2.85271C101.97 3.65203 102.29 4.56159 102.476 5.5814C102.663 6.60121 102.756 7.62102 102.756 8.64083ZM87.5827 21.6227C87.5827 21.9259 87.6359 22.2429 87.7424 22.5736C87.8489 22.9044 87.9953 23.1938 88.1816 23.4419C88.368 23.6899 88.5942 23.9104 88.8604 24.1034C89.1532 24.2687 89.4594 24.3514 89.7788 24.3514C90.9766 24.3514 92.0414 23.9242 92.9731 23.0698C93.9048 22.2153 94.6767 21.168 95.289 19.9276C95.9012 18.6873 96.3671 17.3643 96.6865 15.9587C97.0325 14.553 97.2056 13.2713 97.2056 12.1137C97.2056 11.7829 97.179 11.4246 97.1257 11.0388C97.0991 10.6529 97.0192 10.2946 96.8861 9.96383C96.7797 9.63308 96.5933 9.35745 96.3271 9.13695C96.0876 8.88889 95.7548 8.76486 95.3289 8.76486C94.4771 8.76486 93.6918 8.98536 92.9731 9.42636C92.2544 9.86736 91.6022 10.4599 91.0166 11.2041C90.4576 11.9208 89.9651 12.7476 89.5392 13.6848C89.1133 14.5943 88.7406 15.5314 88.4212 16.4961C88.1284 17.4332 87.9154 18.3566 87.7823 19.2661C87.6492 20.1757 87.5827 20.9612 87.5827 21.6227Z"
                    fill="#EE5E5E"
                  />
                  <path
                    d="M100.327 31.2145C101.072 27.3282 101.791 23.5383 102.483 19.845C102.749 18.3015 103.028 16.6891 103.321 15.0078C103.641 13.2989 103.934 11.6314 104.2 10.0052C104.466 8.35142 104.705 6.76658 104.918 5.25065C105.158 3.70715 105.344 2.30147 105.477 1.03359C106.303 0.978467 107.101 0.923342 107.873 0.868218C108.565 0.813094 109.271 0.77175 109.989 0.744186C110.708 0.689062 111.347 0.633937 111.906 0.578811C112.252 2.70112 112.558 4.72696 112.824 6.65633C112.957 7.4832 113.091 8.35142 113.224 9.26098C113.383 10.143 113.53 10.9974 113.663 11.8243C113.796 12.6512 113.929 13.4505 114.062 14.2222C114.195 14.9664 114.328 15.6279 114.461 16.2067C114.568 15.6279 114.688 14.9664 114.821 14.2222C114.954 13.4505 115.1 12.6512 115.26 11.8243C115.42 10.9699 115.579 10.1016 115.739 9.21964C115.926 8.33764 116.099 7.4832 116.258 6.65633C116.658 4.72696 117.07 2.7149 117.496 0.620157L122.367 0C121.462 4.13437 120.664 8.04823 119.972 11.7416C119.652 13.3402 119.333 14.9526 119.013 16.5788C118.721 18.205 118.441 19.7761 118.175 21.292C117.909 22.8079 117.682 24.2136 117.496 25.509C117.31 26.7769 117.163 27.8519 117.057 28.7339C116.977 28.7339 116.658 28.8028 116.099 28.9406C115.566 29.0508 114.941 29.1886 114.222 29.354C113.53 29.5194 112.824 29.6985 112.106 29.8915C111.414 30.0568 110.894 30.1947 110.548 30.3049C110.362 28.0723 110.162 25.95 109.949 23.938C109.87 23.0835 109.776 22.2016 109.67 21.292C109.563 20.3824 109.457 19.5142 109.351 18.6873C109.271 17.8605 109.178 17.0887 109.071 16.3721C108.991 15.6279 108.911 15.0078 108.831 14.5116C108.752 15.0078 108.645 15.6141 108.512 16.3307C108.379 17.0474 108.246 17.8191 108.113 18.646C107.98 19.4453 107.847 20.286 107.713 21.168C107.58 22.0224 107.434 22.863 107.274 23.6899C106.928 25.6469 106.556 27.6865 106.156 29.8088L100.327 31.2145Z"
                    fill="#EE5E5E"
                  />
                  <path
                    d="M134.044 21.2093C133.272 21.2093 132.5 21.2369 131.728 21.292C130.956 21.3471 130.184 21.4022 129.412 21.4574C130.238 18.0672 131.036 14.6908 131.808 11.3282C132.607 7.96555 133.432 4.58915 134.284 1.19897C134.789 1.11628 135.362 1.03359 136.001 0.950904C136.666 0.868218 137.332 0.77175 137.997 0.6615C138.663 0.55125 139.301 0.454781 139.914 0.372093C140.553 0.289407 141.085 0.20672 141.511 0.124032C140.073 3.5969 138.756 7.09733 137.558 10.6253C136.387 14.1258 135.215 17.6537 134.044 21.2093ZM134.443 27.7003C134.443 28.2239 134.324 28.7476 134.084 29.2713C133.871 29.795 133.552 30.2636 133.126 30.677C132.7 31.0629 132.167 31.3661 131.529 31.5866C130.89 31.8346 130.158 31.9587 129.333 31.9587C128.773 31.9587 128.268 31.8484 127.815 31.6279C127.363 31.4074 126.99 31.118 126.697 30.7597C126.431 30.3738 126.218 29.9466 126.058 29.478C125.899 29.0095 125.819 28.5409 125.819 28.0723C125.819 27.5487 125.899 27.0525 126.058 26.584C126.218 26.0879 126.458 25.6606 126.777 25.3023C127.123 24.9165 127.536 24.5995 128.015 24.3514C128.521 24.1034 129.12 23.9793 129.812 23.9793C131.356 23.9793 132.514 24.3652 133.285 25.1369C134.057 25.8811 134.443 26.7356 134.443 27.7003Z"
                    fill="#EE5E5E"
                  />
                  <path
                    d="M144.533 21.2093C143.761 21.2093 142.989 21.2369 142.217 21.292C141.445 21.3471 140.673 21.4022 139.902 21.4574C140.727 18.0672 141.525 14.6908 142.297 11.3282C143.096 7.96555 143.921 4.58915 144.773 1.19897C145.279 1.11628 145.851 1.03359 146.49 0.950904C147.155 0.868218 147.821 0.77175 148.486 0.6615C149.152 0.55125 149.791 0.454781 150.403 0.372093C151.042 0.289407 151.574 0.20672 152 0.124032C150.563 3.5969 149.245 7.09733 148.047 10.6253C146.876 14.1258 145.705 17.6537 144.533 21.2093ZM144.933 27.7003C144.933 28.2239 144.813 28.7476 144.573 29.2713C144.36 29.795 144.041 30.2636 143.615 30.677C143.189 31.0629 142.657 31.3661 142.018 31.5866C141.379 31.8346 140.647 31.9587 139.822 31.9587C139.263 31.9587 138.757 31.8484 138.304 31.6279C137.852 31.4074 137.479 31.118 137.186 30.7597C136.92 30.3738 136.707 29.9466 136.547 29.478C136.388 29.0095 136.308 28.5409 136.308 28.0723C136.308 27.5487 136.388 27.0525 136.547 26.584C136.707 26.0879 136.947 25.6606 137.266 25.3023C137.612 24.9165 138.025 24.5995 138.504 24.3514C139.01 24.1034 139.609 23.9793 140.301 23.9793C141.845 23.9793 143.003 24.3652 143.775 25.1369C144.547 25.8811 144.933 26.7356 144.933 27.7003Z"
                    fill="#EE5E5E"
                  />
                </svg>
                <p
                  className={` ${styles.ralewayFont} text-[#161616] font_normal_13  mt-[12px]`}
                >
                  {message}
                </p>
                <p className={styles.secondsTimer}>{seconds}</p>
              </>
            ) : (
              <>
                <Lottie
                  loop
                  animationData={loadingAnimation}
                  play
                  style={{ width: 240, height: 120 }}
                />
                <p
                  className={` ${styles.ralewayFont} text-[#161616] font_normal_13 `}
                >
                  Thank you for your patience. {message}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default Searching;
