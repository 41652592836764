import { useContext, useEffect, useRef, useState } from "react";
import SignupButton from "../Components/SignupButton/SignupButton";
import "../../src/App.css";
import styles from "../Pages/styles/SignupPage.module.css";
import Backbutton from "../Components/Assets/backArrow.svg";
import { useLocation, useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Lottie from "react-lottie-player";
import loadingAnimation from "../assests/loading.json";
import logo from "../assests/goViralWhiteLogo.svg";
import LoadingFromCenter from "../Components/LoadingFromCenter/LoadingFromCenter";
import * as _ from "lodash";
import EnterOtp from "../Components/EnterOtp/EnterOtp";
import googleLogo from "../assests/googleLogo.svg";
import whatsappLike from "../assests/whatsappLike.svg";
import singinCloseButton from "../assests/singinCloseButton.svg";
import Context from "../context/Context";
import RetryLoginPopup from "../Components/RetryLoginPopup/RetryLoginPopup";
import axios from "axios";
import downArrow from "../assests/downArrowBlack.svg";
import upArrow from "../assests/upArrowBlack.svg";
import ForgotPasswordPopup from "../Components/ForgotPasswordPopup/ForgotPasswordPopup";

function SignupPage() {
  //

  const {
    sendOtp,
    otpShow,
    numberVerificationLoading,
    enterUserDisplayName,
    patchDisplayNameAndCreateUser,
    namePatchLoading,
    googleSignIn,
    pageLoading,
    setEnterUserDisplayName,
    setShowLogin,
    setOtpShow,
    reqForLike,
    setReqForLike,
    otpError,
    retryCreateUser,
    hideWhatsapp,
    emailError,
    setEmailError,
    customLogin,
    emailSignIn,
    emailLogin,
  } = useUserAuth();
  const [showEmailLogin, setShowEmailLogin] = useState(true);
  const [emailObj, setEmailObj] = useState({});
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);

  const { participantName } = useContext(Context);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [name, setName] = useState("");
  const [nameEmpty, setNameEmpty] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [numberObj, setNumberObj] = useState({
    countryCode: "",
    number: null,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filterdCountryCodes, setFilteredCountryCode] = useState([]);
  const dropdownRef = useRef(null);
  const [countryCodes, setCountryCodes] = useState([]);
  const [useDetails, setUserDetails] = useState({
    name: "",
    whatsApp: true,
  });
  const [selectedCountry, setSelectedCountry] = useState({});

  useEffect(() => {
    getCountryCodes();
  }, []);
  useEffect(() => {
    setOtpShow(false);
    setEnterUserDisplayName({ ...enterUserDisplayName, display: false });
    window.recaptchaVerifier = null;
  }, []);
  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const imageSrc = isDropdownOpen ? upArrow : downArrow;
  const validatePhone = (code) => {
    let regexPhone =
      // eslint-disable-next-line no-useless-escape
      /^\+(?:[0-9]●?){6,14}[0-9]$/;

    if (code.match(regexPhone)) {
      setInvalidPhone("");
      return true;
    } else {
      setInvalidPhone("Invalid Phone Number");
      return false;
    }
  };
  const handleInputChangeEmail = (e) => {
    e.preventDefault();
    setEmailObj((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleSubmitEmailPassword = (e, type) => {
    e.preventDefault();
    setEmailError("");
    if (_.isEmpty(emailObj.name) && type == "signin") {
      setEmailError("Please Enter your Name");
      return;
    }
    if (_.isEmpty(emailObj.email)) {
      setEmailError("Please Enter Email address");
      return;
    }
    if (_.isEmpty(emailObj.password)) {
      setEmailError("Please Enter a password");
      return;
    }
    switch (type) {
      case "signin":
        emailSignIn(emailObj?.name, emailObj?.email, emailObj?.password);
        break;
      default:
        emailLogin(emailObj?.email, emailObj?.password);
        break;
    }
  };
  const handleSubmitNumber = (e) => {
    e.preventDefault();

    if (_.isEmpty(numberObj.countryCode)) {
      setInvalidPhone("Enter Country Code");
      return;
    }
    if (_.isEmpty(numberObj.number)) return;
    let temp = numberObj.countryCode + numberObj.number;
    temp = temp.replace(/\s+/g, "");
    const validateRespose = validatePhone(temp);
    if (!validateRespose) {
      return;
    }
    setPhoneNumber(`${temp}`);
    sendOtp(`${temp}`);
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    setNumberObj((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleWhatappStausChange = (e) => {
    let status = !useDetails?.whatsApp;
    setUserDetails((prevState) => ({
      ...prevState,
      whatsApp: status,
    }));
  };
  const handleNameChange = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };
  const handleSubmit = () => {
    patchDisplayNameAndCreateUser(
      enterUserDisplayName?.otpResponse,
      useDetails
    );
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const tinyUrl = window.location.pathname.split("/")[1]?.replace(/\.html$/, "");;

  const handleWhatsappLike = () => {
    if (!name?.replace(/\s/g, "")) {
      setNameEmpty(true);
      return;
    }
    let phoneNumber = "+918618406454";

    let message = `#LikeRequest, 
Name:'${participantName}'. participationId:${tinyUrl}, From:${name}.`;

    const encodedContent = encodeURIComponent(message);
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isMobile) {
      const url = `https://wa.me/${phoneNumber}?text=${encodedContent}`;
      window.open(url, "_blank");
    } else {
      const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodedContent}`;
      window.open(url, "_blank");
    }
    setTimeout(window.location.reload(), 2000);
  };

  const getCountryCodes = async () => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviralcampaign/CountryCodes.json`
      );
      setCountryCodes(data.data);
      setFilteredCountryCode(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  useEffect(() => {
    handleCountryCodeChange({
      name: "India",
      code: "+91",
      shortCode: "IN",
    });
  }, []);
  const handleCountryCodeChange = (country) => {
    let { code } = country;
    setSelectedCountry(country);
    setNumberObj((prevState) => ({
      ...prevState,
      countryCode: code,
    }));
    setIsDropdownOpen(false);
  };
  const handleCountryChange = (event) => {
    const inputValue = event.target.value;

    // Filter country codes based on user input
    const filteredCountryCodes = countryCodes?.filter((country) =>
      country.code.includes(inputValue)
    );

    setFilteredCountryCode(filteredCountryCodes);
    setNumberObj((prevState) => ({
      ...prevState,
      countryCode: event.target.value,
    }));
    // Update the dropdown visibility
    setIsDropdownOpen(filteredCountryCodes.length > 0);

    // When the input is cleared, close the dropdown
    if (!inputValue) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);
  const getCountryFlag = (code) => {
    let lowerCaseCode = code?.toLowerCase();
    return (
      <img
        src={`https://storage.googleapis.com/goviralcampaign/countryFlags/${lowerCaseCode}.png`}
        className="h-full w-full "
      />
    );
  };
  return (
    <>
      {forgotPasswordPopup && (
        <ForgotPasswordPopup setPopup={setForgotPasswordPopup} />
      )}
      <div className={`no-scrollbar ${styles.signUpParent}`}>
        <div className={styles.signupPage}>
          <div className="w-[100%] h-[100%] pb-[30px]">
            <div className="w-[100%] flex justify-end">
              <div className="w-full min-w-[32px] min-h-[32px] relative">
                <img
                  src={singinCloseButton}
                  onClick={() => {
                    setReqForLike(false);
                    setShowLogin(false);
                  }}
                  className="absolute top-[10px] right-[10px] cursor-pointer"
                />
                {customLogin && (
                  <img
                    src={customLogin}
                    className="w-full h-[150px] object-contain"
                  />
                )}
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {pageLoading && <LoadingFromCenter />}
              <h3 className="text-regular-medium mb-[14px]">
                {reqForLike
                  ? "Like via Whatsapp"
                  : showEmailLogin
                  ? "Sign in to proceed"
                  : "Create an Account"}
              </h3>
              {!customLogin && (
                <p className="text-small text-opacity-80 mb-[25px]">
                  {reqForLike
                    ? " No OTP or login, just send us a message via Whatsapp to our business account to like this entry!"
                    : "To unlock all the features and enjoy a personalized experience, please log in to your account. Logging in not only enhances your user experience but also ensures the security of your data."}
                </p>
              )}
            </div>
            {/* {!enterUserDisplayName?.display && (
              <div className={styles.mobileLogin}>
                {!otpShow && (
                  <>
                    <div className="mb-[32px]">
                      <p className="text-left mb-[10px] text-small">
                        Enter Phone Number
                      </p>
                      <div className="flex items-center relative">
                        <div
                          ref={dropdownRef}
                          className={styles.country}
                          onClick={toggleOpen}
                        >
                          <img src={imageSrc} />
                          {!_.isEmpty(selectedCountry) && (
                            <div className="aspect-[4/3] h-[20px]">
                              {getCountryFlag(selectedCountry?.shortCode)}
                            </div>
                          )}
                          {isDropdownOpen && (
                            <div className={styles.dropdownList}>
                              {filterdCountryCodes?.map((country) => (
                                <div
                                  key={country?.shortCode}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCountryCodeChange(country);
                                  }}
                                  className="flex items-center gap-[10px]"
                                >
                                  <div className="w-[30px] h-[20px]">
                                    {getCountryFlag(country?.shortCode)}
                                  </div>
                                  {country?.code} - {country?.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div class={styles.phoneInput}>
                          <input
                            type="text"
                            id="countryCode"
                            name="countryCode"
                            maxlength="4"
                            className="text-regular-callout"
                            onChange={handleCountryChange}
                            onClick={toggleOpen}
                            autoComplete="off"
                            value={numberObj?.countryCode}
                            readOnly
                          />
                          <input
                            type="number"
                            id="number"
                            name="number"
                            placeholder="Enter phone number"
                            className="text-regular-callout"
                            onChange={handleInputChange}
                            value={numberObj?.number}
                          />
                        </div>
                      </div>
                      {invalidPhone && (
                        <div className="text-small text-left w-[100%] mb-[12px]">
                          <span className="text-red-600">*</span>
                          {invalidPhone}
                        </div>
                      )}
                      {otpError && (
                        <div className="text-small text-left w-[100%] mb-[12px]">
                          <span className="text-red-600">*</span>
                          {otpError}
                        </div>
                      )}
                      <div className="flex items-start justify-start gap-[15px]">
                        <input
                          type="checkbox"
                          name="whatsApp"
                          onChange={handleWhatappStausChange}
                          checked={useDetails?.whatsApp}
                        />
                        <label
                          htmlFor="whatsApp"
                          className="text-small text-left"
                        >
                          I would love to receive contest updates in the above
                          WhatsApp number
                        </label>
                      </div>
                    </div>
                    <button
                      className={`${styles.button} text-regular-medium bg-[#0092D6]  mb-[16px] drop-shadow-lg`}
                      onClick={handleSubmitNumber}
                      disabled={numberVerificationLoading || pageLoading}
                    >
                      Continue
                    </button>
                    {numberVerificationLoading && <LoadingFromCenter />}

                    <div className="flex items-center justify-center mb-[28px]">
                      <p className="text-small text-[#787878] max-w-[90%]">
                        We will send you a code to verify your phone number
                      </p>
                    </div>
                  </>
                )}
                {otpShow && <EnterOtp number={phoneNumber} />}
              </div>
            )}
            {enterUserDisplayName?.display && (
              <div className={styles.mobileLogin}>
                <div className="mb-[24px]">
                  <p className="text-left mb-[10px] text-small">
                    Please enter your name
                  </p>
                  <input type="text" id="name" onChange={handleNameChange} />
                </div>
                <button
                  className={`${styles.button} text-regular-medium bg-[#00A989] mb-[16px]`}
                  onClick={handleSubmit}
                  disabled={namePatchLoading || pageLoading}
                >
                  Submit
                </button>
                {namePatchLoading && <LoadingFromCenter />}
              </div>
            )} */}
            {/* {!otpShow && (
            <div className="px-[20px]">
              <p className="text-regular mb-[28px]">OR</p>
              <button
                className={`${styles.button} text-regular-medium bg-[#0092D6]   flex items-center justify-center gap-[14px]`}
                onClick={googleSignIn}
                disabled={pageLoading}
              >
                <img src={googleLogo} alt="" />
                Sign in with Google
              </button>
            </div>
          )} */}

            {!reqForLike && (
              <>
                <div className="bg-[#d5d5d5] p-[20px]">
                  {!showEmailLogin && (
                    <div className="">
                      <div className="mb-[32px]">
                        <div className="flex items-center relative">
                          <div class={styles.mobileLogin}>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Name"
                              className="text-regular-callout mb-[20px]"
                              onChange={handleInputChangeEmail}
                              value={emailObj?.name}
                            />
                            <input
                              type="email"
                              id="email"
                              name="number"
                              placeholder="Email"
                              className="text-regular-callout mb-[20px]"
                              onChange={handleInputChangeEmail}
                              value={emailObj?.email}
                            />
                            <input
                              type="password"
                              id="password"
                              name="number"
                              placeholder="Password"
                              className="text-regular-callout"
                              onChange={handleInputChangeEmail}
                              value={emailObj?.password}
                            />
                          </div>
                        </div>

                        {emailError && (
                          <div className="text-small text-left w-[100%] my-[10px]">
                            <span className="text-red-600">*</span>
                            {emailError}
                          </div>
                        )}
                      </div>
                      <button
                        className={`${styles.button} text-regular-medium bg-[#00A989] mb-[16px]`}
                        onClick={(e) => handleSubmitEmailPassword(e, "signin")}
                        disabled={numberVerificationLoading || pageLoading}
                      >
                        Sign up
                      </button>
                      <p
                        className="text-left mb-[10px] text-small text-center cursor-pointer"
                        onClick={() => {
                          setEmailError("");
                          setShowEmailLogin(true);
                        }}
                      >
                        Already have an account?{" "}
                        <span className="ml-[5px]  underline text-[#3075FC]">
                          Sign in
                        </span>
                      </p>
                    </div>
                  )}
                  {showEmailLogin && (
                    <div className="">
                      <div className="mb-[32px]">
                        <div className="flex items-center relative">
                          <div class={styles.mobileLogin}>
                            <input
                              type="email"
                              id="email"
                              name="number"
                              placeholder="Email"
                              className="text-regular-callout mb-[20px]"
                              onChange={handleInputChangeEmail}
                              value={emailObj?.email}
                            />
                            <input
                              type="password"
                              id="password"
                              name="password"
                              placeholder="Password"
                              className="text-regular-callout"
                              onChange={handleInputChangeEmail}
                              value={emailObj?.password}
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-beween">
                          {emailError && (
                            <div className="text-small text-left w-[100%] my-[10px]">
                              <span className="text-red-600">*</span>
                              {emailError}
                            </div>
                          )}
                          <p
                            className="text-right w-full my-[10px] text-small cursor-pointer"
                            onClick={() => {
                              setEmailError("");
                              setForgotPasswordPopup(true);
                            }}
                          >
                            Forgot your password?
                            <span className="ml-[5px] underline text-[#3075FC]">
                              Reset
                            </span>
                          </p>
                        </div>
                      </div>
                      <button
                        className={`${styles.button} text-regular-medium bg-[#00A989] mb-[16px]`}
                        onClick={(e) => handleSubmitEmailPassword(e, "login")}
                        disabled={numberVerificationLoading || pageLoading}
                      >
                        Sign in
                      </button>
                      <p
                        className="text-left mb-[10px] text-small text-center cursor-pointer"
                        onClick={() => setShowEmailLogin(false)}
                      >
                        Don't have an account?
                        <span className="ml-[5px] underline text-[#3075FC]">
                          Create one
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                <p className="text-regular my-[28px]">Or connect with</p>

                <div className="px-[20px]">
                  {/* <p className="text-regular mb-[28px]">OR</p> */}
                  <button
                    className={`${styles.button} mx-auto text-regular-medium bg-[#0092D6]   flex items-center justify-center gap-[14px]`}
                    onClick={googleSignIn}
                    disabled={pageLoading}
                  >
                    <img src={googleLogo} alt="" />
                    Google
                  </button>
                </div>
              </>
            )}

            {!otpShow && reqForLike && !hideWhatsapp && (
              <>
                <div className={`px-[20px] ${styles.whatsappLogin}`}>
                  {/* <p className="text-regular mb-[28px]">OR</p> */}
                  <div className="mb-[28px]">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                      className="text-regular-callout "
                      onChange={(e) => setName(e.target.value)}
                    />
                    {nameEmpty && (
                      <div className="text-small text-left w-[100%] mb-[12px]">
                        <span className="text-red-600">* </span>
                        Enter your name
                      </div>
                    )}
                  </div>
                  <button
                    className={`${styles.button} text-regular-medium bg-[#7AC280]   flex items-center justify-center gap-[14px] drop-shadow-lg`}
                    onClick={handleWhatsappLike}
                    disabled={pageLoading}
                  >
                    <img
                      src={whatsappLike}
                      alt=""
                      className="h-[24px] w-[24px]"
                    />
                    Like Via WhatsApp
                  </button>
                </div>
                {/* <div className="flex items-center justify-center mt-[10px]">
                  <p className="text-small text-[#787878] max-w-[90%]">
                    No OTP or login, just send us a message via Whatsapp to our
                    business account to like this entry!
                  </p>
                </div> */}
              </>
            )}
          </div>
        </div>
        <div id="recaptcha-container"></div>
      </div>
      {retryCreateUser && <RetryLoginPopup />}
    </>
  );
}

export default SignupPage;
