import axios from "axios";
import * as _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import Context from "../../context/Context";
import { useUserAuth } from "../../context/UserAuthContext";
import "./Share.css";
import styles from "./Share.module.css";
import MessagePopup from "../MessagePopup/MessagePopup";
import successTick from "../../assests/successTick.svg";
import uploadIcon from "../../assests/uploadIcon.svg";
import { ref, uploadBytesResumable } from "firebase/storage";
import { leadsStorage } from "../../store/firebase-config";
import { idProofs } from "../../utils/common";
import CollegeTemplate from "../RegisterFormTemplate/CollegeTemplate";
import DefaultTemplate from "../RegisterFormTemplate/DefaultTemplate";

const RegisterFormInput = ({
  setServiceForm,
  mail,
  setShowTerms,
  promotionData,
  service,
}) => {
  let type = promotionData?.templateType;
  let pageJXS;

  switch (type) {
    case "COLLEGE":
      pageJXS = (
        <CollegeTemplate
          setShowTerms={setShowTerms}
          promotionData={promotionData}
          setServiceForm={setServiceForm}
          mail={mail}
          service={service}
        />
      );
      break;
    default:
      pageJXS = (
        <DefaultTemplate
          setShowTerms={setShowTerms}
          promotionData={promotionData}
          setServiceForm={setServiceForm}
          mail={mail}
          service={service}
        />
      );
  }
  return <>{pageJXS}</>;
};
export default RegisterFormInput;
