import React from "react";
import styles from "./styles/BlackFooter.module.css";
import { useNavigate } from "react-router";
function BlackLeadsFooter({ color }) {
  const navigate = useNavigate();
  const shareToEmail = () => {
    window.location.href = `mailto:support@goviral.world`;
  };
  return (
    <div className="SharewidthController  bg-black pt-[32px] ">
      <div className="w-[100%] px-[16px] pt-[20px] pb-[50px] flex flex-col items-center justify-center gap-[5px]">
        <p className={styles.mailDesciption}>For help & Support</p>
        <button
          className={styles.mail}
          style={{ color: color }}
          onClick={shareToEmail}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M1.55556 0C0.705396 0 0 0.705396 0 1.55556V12.4444C0 13.2946 0.705396 14 1.55556 14H12.4444C13.2946 14 14 13.2946 14 12.4444V7H12.4444V12.4444H1.55556V1.55556H7V0H1.55556ZM8.55556 0V1.55556H11.3446L4.11675 8.78342L5.21658 9.88325L12.4444 2.65538V5.44444H14V0H8.55556Z"
              fill={color}
            />
          </svg>
          support@goviral.world
        </button>
      </div>
    </div>
  );
}

export default BlackLeadsFooter;
