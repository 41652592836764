import React from "react";
import styles from "./GallertPopup.module.css";
import Carousel from "../Carousel/Carousel";
import back from "../../assests/backButtonPromo.svg";

export default function GalleryPopup({ data, index, onClose }) {
  return (
    <div className={styles.container}>
      <div className={styles.caroselContainer}>
        <div onClick={onClose} className="py-[50px]">
          <img src={back} alt="" />
        </div>
        <Carousel items={data} index={index} />
      </div>
    </div>
  );
}
