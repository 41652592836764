import React, { useState, createContext, useContext, useEffect } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { authentication } from "../store/firebase-config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "lodash";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  //otp variable
  const [otpError, setOtpError] = useState("");
  const [otpResult, setOtpResult] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [numberVerificationLoading, setNumberVerificationLoading] =
    useState(false);
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const [urlPath, setUrlPath] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();
  const [previousLocation, setPreviousLocation] = useState();
  const [guestModeEnabled, setGuestModeEnabled] = useState(true);
  const previousLocationFromUrl = urlPath.split("/")[3];
  const [showLogin, setShowLogin] = useState(false);
  const [customLogin, setCustomLogin] = useState("");
  const [retryPayment, setRetryPayment] = useState(false);
  const [reqForLike, setReqForLike] = useState(false);
  const [reqForGenerate, setReqForGenerate] = useState(false);
  const [reqForLeadsSubmit, setReqLeadsSubmit] = useState(false);
  const [reqForRegister, setReqForRegister] = useState(false);
  const [reqForPromoLink, setReqForPrmoLink] = useState(false);
  const campaignUniqueText = localStorage.getItem("campaignUniqueText");
  const previousLocationLocal = localStorage.getItem("previousLocationLocal");
  const [retryCreateUser, setRetryCreateUser] = useState(false);
  const [errorData, setErrorData] = useState({});
  const verifyOrganizerFromUrl = window.location.pathname.split("/")[1]?.replace(/\.html$/, "");;
  const [hideWhatsapp, setHideWhatsapp] = useState(false);
  const [verificationPopup, setVerificationPopup] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [whatsappVerified, setWhatsappVerified] = useState();
  const [userDetailsFromApi, setUserDetailsFromApi] = useState();
  const [enterUserDisplayName, setEnterUserDisplayName] = useState({
    display: false,
  });
  const [namePatchLoading, setNamePatchLoading] = useState(false);
  const [numberVerificationError, setNumberVerificationError] = useState();
  const [wpNumberVerificationLoading, setWpNumberVerificationLoading] =
    useState();

  useEffect(() => {
    setUrlPath(window.location.href);
    // eslint-disable-next-line
  }, []);
  const userId = localStorage.getItem("userId");
  const loginChecker = async (accessToken) => {
    let config1 = {
      method: "post",
      url: `${process.env.REACT_APP_CREATE_USERS}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const loginCheck = await axios(config1);
    localStorage.setItem("userId", loginCheck.data?.userId);
    setUserDetailsFromApi(loginCheck.data);
    setWhatsappVerified(loginCheck.data?.phoneNumberVerified);
  };
  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (!isEmpty(user)) {
        setGuestModeEnabled(false);
        const userIdFromLocal = localStorage.getItem("userId");

        if (isEmpty(userIdFromLocal) || userIdFromLocal == "") {
          loginChecker(user.accessToken);
        } else if (whatsappVerified == null || whatsappVerified == undefined) {
          loginChecker(user.accessToken);
        }
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);
  const guestUserSignin = () => {
    setGuestModeEnabled(true);
    if (previousLocationFromUrl === "signup") {
      window.location.href = `/`;
    } else {
      if (previousLocation == null) {
        setPageLoading(false);
        window.location.href = urlPath;
      } else {
        setPageLoading(false);
        window.location.href = previousLocation;
      }
    }
  };
  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      setPageLoading(true);
      const login = await signInWithPopup(authentication, provider);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_USERS}`,
        headers: {
          Authorization: `Bearer ${login._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };

      const loginCheck = await axios(config);
      const campaignUniqueText = localStorage.getItem("campaignUniqueText");
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setUserDetailsFromApi(loginCheck.data);
        setWhatsappVerified(loginCheck.data?.phoneNumberVerified);
        setGuestModeEnabled(false);
        setPageLoading(false);
        setShowLogin(false);
      } else {
        setPageLoading(false);
        setShowLogin(false);
      }
    } catch (error) {
      setPageLoading(false);
      setShowLogin(false);
    }
  };

  // second step
  const updateUserssProfile = async (data, photoUrl, setEditProfile) => {
    try {
      const updateUserProfile = await updateProfile(currentUser, {
        displayName: data?.name,
        photoURL: photoUrl,
        phoneNumber: data?.phone,
      });
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_PROFILE_DETAILS}`,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const updateProfileDetails = await axios(config);

      setEditProfile(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  //otp signin
  const otpSignIn = async (number) => {
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          { size: "invisible" },
          authentication
        );
        window.recaptchaVerifier.render();
      }
      const otpData = await signInWithPhoneNumber(
        authentication,
        number,
        window.recaptchaVerifier
      );

      return otpData;
    } catch (error) {
      const cleanedErrorMessage = error?.message?.replace(/^Firebase:\s*/, "");
      setOtpError(cleanedErrorMessage);
      console.log("error", error);
    }
  };

  //Send OTP
  const sendOtp = async (phoneNumber) => {
    setNumberVerificationLoading(true);
    setOtpError("");
    if (phoneNumber === "" || phoneNumber === undefined)
      return setOtpError("Please enter a valid phone number!");
    try {
      const response = await otpSignIn(phoneNumber);
      setNumberVerificationLoading(false);
      if (response) {
        setOtpResult(response);
        setOtpShow(true);
      }
    } catch (err) {
      const cleanedErrorMessage = err?.message?.replace(/^Firebase:\s*/, "");
      setOtpError(cleanedErrorMessage);
    }
  };

  // Resend OTP
  const resendOtp = async (phoneNumber) => {
    signInWithPhoneNumber(
      authentication,
      phoneNumber,
      window.recaptchaVerifier
    );
  };

  //Verify Otp
  const verifyOtp = async (result, otp) => {
    setOtpError("");
    setOtpVerificationLoading(true);
    if (otp === "" || otp === null) return;
    try {
      const otpResponse = await result.confirm(otp);
      if (otpResponse?.user) {
        userCheck(otpResponse);
      }
      setOtpVerificationLoading(false);
    } catch (err) {
      console.log(err);
      const cleanedErrorMessage = err?.message?.replace(/^Firebase:\s*/, "");
      setOtpError(cleanedErrorMessage);
      setOtpVerificationLoading(false);
    }
  };
  const userCheck = async (otpResponse) => {
    setNamePatchLoading(true);
    setOtpError("");
    try {
      let config1 = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_USERS}`,
        headers: {
          Authorization: `Bearer ${otpResponse?._tokenResponse?.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config1);

      if (loginCheck?.status === 201) {
        localStorage.setItem("userId", loginCheck?.data?.userId);
        // window.recaptchaVerifier = null;
        // setGuestModeEnabled(false);
        // setNamePatchLoading(false);
        // setOtpShow(false);
        // setEnterUserDisplayName({
        //   display: false,
        // });
        // setShowLogin(false);
        if (!loginCheck?.data?.name) {
          setEnterUserDisplayName({
            display: true,
            otpResponse: otpResponse,
          });

          setNamePatchLoading(false);
        } else {
          window.recaptchaVerifier = null;
          setGuestModeEnabled(false);
          setNamePatchLoading(false);
          setOtpShow(false);
          setEnterUserDisplayName({
            display: false,
          });
          setShowLogin(false);
        }
      } else {
        // setShowLogin(true);
        // setOtpShow(false);
        // setEnterUserDisplayName({
        //   display: false,
        // });
        // setOtpVerificationLoading(false);
        // setNamePatchLoading(false);
        let error = {
          type: "createUser_from_sharepage",
          phoneNumber: otpResponse?.user.phoneNumber,
          msg: loginCheck?.data?.message,
          token: otpResponse?._tokenResponse?.idToken,
        };

        setErrorData(error);
        setRetryCreateUser(true);
      }
    } catch (err) {
      setOtpError(err.message);
      setOtpShow(false);
      setShowLogin(true);
      setEnterUserDisplayName({
        display: false,
      });
      setOtpVerificationLoading(false);
      setNamePatchLoading(false);
    }
  };

  //currently name patch api is not using
  const patchDisplayNameAndCreateUser = async (otpResponse, useDetails) => {
    setNamePatchLoading(true);
    try {
      let config2 = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_PROFILE_DETAILS}`,
        headers: {
          Authorization: `Bearer ${otpResponse?._tokenResponse?.idToken}`,
          "Content-Type": "application/json",
        },
        data: useDetails,
      };
      // updateFirebaseName(useDetails?.name);
      const updateUserProfile = updateProfile(currentUser, {
        displayName: useDetails?.name,
      });
      window.recaptchaVerifier = null;

      const updateProfileDetails = await axios(config2);
      if (updateProfileDetails?.status === 200) {
        // localStorage.setItem("userId", updateProfileDetails.data?.data?._id);
        window.recaptchaVerifier = null;
        setGuestModeEnabled(false);
        setNamePatchLoading(false);
        setOtpVerificationLoading(false);
        setPageLoading(false);
        setShowLogin(false);
        setOtpShow(false);

        setEnterUserDisplayName({
          display: false,
        });
      } else {
        // navigate(`/${organizerName}/signup`);'
        // setShowLogin(true);
        // setOtpVerificationLoading(false);
        // setNamePatchLoading(false);
        // setOtpShow(false);
        // setEnterUserDisplayName({
        //   display: false,
        // });
      }
    } catch (err) {
      setOtpError(err.message);
      window.recaptchaVerifier = null;
      setGuestModeEnabled(false);
      setNamePatchLoading(false);
      setOtpVerificationLoading(false);
      setPageLoading(false);
      setShowLogin(false);
      setOtpShow(false);

      setEnterUserDisplayName({
        display: false,
      });
    }
  };

  //Verify Whatsapp Otp
  const verifyWpOtp = async (otp) => {
    setNumberVerificationError("");
    setWpNumberVerificationLoading(true);
    let trimmedOtp = otp?.replace(/\s/g, "");
    if (trimmedOtp === "" || trimmedOtp === null) return;
    try {
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_VERIFY_WP_OTP}`,
        headers: {
          Authorization: `Bearer ${currentUser?.accessToken}`,
          "Content-Type": "application/json",
        },
        data: { otp: trimmedOtp },
      };
      const otpResponse = await axios(config);
      console.log("otpResponse?.data", otpResponse?.data);
      if (otpResponse?.data?.status == "success") {
        if (otpResponse?.data?.verified) {
          setVerificationPopup(false);
          setWhatsappVerified(true);
        } else {
          setNumberVerificationError(otpResponse?.data?.msg);
        }
      }
      setWpNumberVerificationLoading(false);
    } catch (err) {
      console.log(err);
      setNumberVerificationError();
      setWpNumberVerificationLoading(false);
    }
  };

  //email signin
  const emailSignIn = async (name, email, password) => {
    try {
      setPageLoading(true);
      const response = await createUserWithEmailAndPassword(
        authentication,
        email,
        password
      );
      const updateUserProfile = await updateProfile(response?.user, {
        displayName: name,
      });
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_USERS}`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
        data: { name },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setUserDetailsFromApi(loginCheck.data);
        setWhatsappVerified(loginCheck.data?.phoneNumberVerified);
        setGuestModeEnabled(false);
        setPageLoading(false);
        setShowLogin(false);
        emailVerification(response?.user);
      }
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };
  //email login
  const emailLogin = async (email, password) => {
    try {
      setPageLoading(true);
      const response = await signInWithEmailAndPassword(
        authentication,
        email,
        password
      );

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CREATE_USERS}`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        localStorage.setItem("userId", loginCheck.data?.userId);
        setUserDetailsFromApi(loginCheck.data);
        setWhatsappVerified(loginCheck.data?.phoneNumberVerified);
        setGuestModeEnabled(false);
        setPageLoading(false);
        setShowLogin(false);
      }
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }

      console.log("error", error);
    }
  };

  //email login password reset
  const emailPasswordReset = async (email) => {
    try {
      setPageLoading(true);
      const response = await sendPasswordResetEmail(authentication, email);
      setPageLoading(false);
      return true;
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }

      console.log("error", error);
    }
  };

  //email verifivation
  const emailVerification = async (user) => {
    console.log("user", user);
    try {
      setPageLoading(true);
      const response = await sendEmailVerification(user);

      return true;
    } catch (error) {
      setPageLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };
  return (
    <userAuthContext.Provider
      value={{
        guestUserSignin,
        googleSignIn,
        updateUserssProfile,
        currentUser,
        pageLoading,
        setPageLoading,
        setPreviousLocation,
        previousLocation,
        loading,
        otpSignIn,
        verifyOtp,
        otpError,
        setOtpError,
        otpResult,
        setOtpResult,
        sendOtp,
        otpShow,
        setOtpShow,
        numberVerificationLoading,
        otpVerificationLoading,
        enterUserDisplayName,
        setEnterUserDisplayName,
        patchDisplayNameAndCreateUser,
        namePatchLoading,
        urlPath,
        resendOtp,
        guestModeEnabled,
        setGuestModeEnabled,
        userId,
        showLogin,
        setShowLogin,
        reqForLike,
        setReqForLike,
        reqForLeadsSubmit,
        setReqLeadsSubmit,
        reqForPromoLink,
        setReqForPrmoLink,
        retryCreateUser,
        setRetryCreateUser,
        userCheck,
        errorData,
        hideWhatsapp,
        setHideWhatsapp,
        reqForGenerate,
        setReqForGenerate,
        whatsappVerified,
        userDetailsFromApi,
        wpNumberVerificationLoading,
        setWpNumberVerificationLoading,
        numberVerificationError,
        setNumberVerificationError,
        verifyWpOtp,
        verificationPopup,
        setVerificationPopup,
        customLogin,
        setCustomLogin,
        emailSignIn,
        emailLogin,
        emailError,
        setEmailError,
        emailPasswordReset,
        reqForRegister,
        setReqForRegister,
        retryPayment,
        setRetryPayment,
      }}
    >
      {!loading && children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
