import React from 'react'
import styles from '../Share/Share.module.css'
import arrow2 from '../../assests/arrow2.svg';
import logo from '../../assests/goViralTempLogo.svg'
import { useNavigate } from 'react-router';
function ShareNav() {
  const navigate = useNavigate()
  let organizerID = window.location.href.split('/')[3]
  let campainID = window.location.href.split('/')[4]
  const redirect = () =>{
    console.log("redirecting");
    navigate(`${"/"+organizerID+"/"+campainID}`)
  }
  return (
    <div className={styles.nav}>
      <div className={styles.navData}>
        <div className='hover:cursor-pointer' onClick={redirect}>
          {/* <p className={styles.icons}> Festivity </p> */}
          <img src={logo} alt="" className='h-[60px]'/>
        </div>
        {/* <div className={styles.donlode_app}>Download the App <img className={styles.arow} src={arrow2} alt="" /></div> */}
      </div>
    </div>
  )
}

export default ShareNav