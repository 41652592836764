import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template7.module.css";

export default function Template7({
  carousel,
  handleOpenForm,
  handleOpenDetails,
  handleLinkClick,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto">
        {carousel.heading && (
          <div className="flex items-center mb-[16px] w-full">
            <p
              className={` text-[22px] md:text-[32px] leading-[38px]   ${
                styles.heading
              }
          ${
            carousel.items.length < 5
              ? "md:text-center md:pl-[0px] pl-[16px] "
              : "md:pl-[100px] pl-[16px]"
          } whitespace-nowrap`}
              style={{ fontFamily: carousel?.font }}
            >
              {carousel.heading}
            </p>
            <div
              className="w-full h-[0.5px] mx-[16px] border-0 border-none opacity-50"
              style={{ background: carousel?.textColor }}
            />
          </div>
        )}
        <div
          className={`flex flex-wrap box  gap-[14px] pr-[16px] overflow-x-auto md:grid md:grid-cols-2  ${
            carousel.items.length < 5
              ? "md:justify-center md:pl-[0px] pl-[8px]"
              : "md:pl-[100px] pl-[8px]"
          }`}
        >
          {carousel &&
            carousel.items &&
            carousel.items.map((add) => {
              return (
                <div
                  style={{ background: add?.bgColor }}
                  className="p-[15px] rounded-[5px]"
                  onClick={() => {
                    if (add.goto) {
                      if (add.goto === "form") {
                        handleOpenForm(add);
                      } else if (add.goto == "details") {
                        handleOpenDetails(add);
                      } else {
                        handleLinkClick(add);
                      }
                    }
                  }}
                >
                  <div className=" w-[100%] flex flex-row md:flex-col md:px-[15px]">
                    {add?.fileType?.startsWith("image") && (
                      <img
                        src={add.fileUrl}
                        alt=""
                        className="min-w-[190px] md:min-w-[10px] md:w-full w-[190px] md:h-[300px]  h-[190px] object-cover rounded-[5px]"
                      />
                    )}
                    {add.fileType?.startsWith("video") && (
                      <div className="min-w-[190px] md:min-w-[10px] md:w-full w-[190px] md:h-[300px]  h-[190px] object-cover ">
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                            youtube: { playerVars: { disablekb: 1 } },
                          }}
                          // playing={true}
                          controls={true}
                          width="100%"
                          light={add?.thumbnailUrl ? add?.thumbnailUrl : true}
                          height="100%"
                          url={add?.fileUrl}
                          className={styles.reactPlayer}
                        />
                      </div>
                    )}
                    <div className="mt-auto pl-[5px] md:pl-0 w-full md:pt-[15px]">
                      <p
                        className="text-[16px] md:text-[24px] font-semibold tracking-[-0.32px] uppercase leading-[20px]"
                        style={{
                          color: add?.textColor,
                          fontFamily: carousel?.secondaryFont,
                        }}
                      >
                        {add.shopbuttton}
                      </p>
                      <p
                        className="text-[16px] md:text-[18px]  leading-[140%] opacity-90"
                        style={{
                          color: add?.textColor,
                          fontFamily: carousel?.secondaryFont,
                        }}
                      >
                        {add.shopbutttonDescription}
                      </p>
                    </div>
                  </div>
                  {add?.description && (
                    <div
                      className="text-[14px] md:px-[15px] md:text-[16px] pt-[15px] leading-[140%] "
                      style={{
                        color: add?.textColor,
                        fontFamily: carousel?.secondaryFont,
                      }}
                    >
                      <p className="opacity-75">{add.description}</p>
                      <p className=" flex items-baseline gap-[2px] w-fit font-semibold">
                        Read More
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="7"
                          viewBox="0 0 4 7"
                          fill="none"
                        >
                          <path
                            d="M0.165111 0.155358C0.382567 -0.0517864 0.735166 -0.0517864 0.952617 0.155358L3.67418 2.75052C4.10875 3.16486 4.10858 3.83632 3.67384 4.2505L0.950613 6.84463C0.733162 7.05179 0.380563 7.05179 0.163101 6.84463C-0.0543669 6.63747 -0.0543669 6.30161 0.163101 6.09445L2.49387 3.87414C2.71138 3.66699 2.71138 3.33111 2.49387 3.12396L0.165111 0.905532C-0.0523568 0.698388 -0.0523568 0.362555 0.165111 0.155358Z"
                            fill="white"
                          />
                        </svg>
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
