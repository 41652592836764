import React from 'react'

function RuleLine() {
  return (
    <div
      className={` justify-center items-center opacity-20 grid bg-[#000]  `}
    >
      <hr className={`w-[100%]  h-[1px]`} />
    </div>
  )
}

export default RuleLine