import * as _ from "lodash";
import "./Share.css";
import DefaultTemplate from "../PurchaseFormTemplates/DefaultTemplate";

const PurchaseFormInput = ({
  setShowPopup,
  mail,
  setShowTerms,
  promotionData,
  service,
}) => {
  let type = promotionData?.templateType;
  let pageJXS;

  switch (type) {
    default:
      pageJXS = (
        <DefaultTemplate
          setShowTerms={setShowTerms}
          promotionData={promotionData}
          setServiceForm={setShowPopup}
          mail={mail}
          service={service}
        />
      );
  }
  return <>{pageJXS}</>;
};
export default PurchaseFormInput;
