import React, { useContext, useEffect } from "react";
import doneAnimation from "./doneAnimation.json";
import Lottie from "react-lottie-player";
import Context from "../../context/Context";
import PopupWrapper from "../../Layout/PopupWrapper";

export default function DonePopup({ message }) {
  const { setSuccessMessage } = useContext(Context);
  useEffect(() => {
    setTimeout(() => {
      setSuccessMessage("");
    }, 3000);
  }, []);
  return (
    <PopupWrapper visible={message}>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20">
        <div className="flex items-center justify-center gap-[5px] backdrop-blur-md bg-white/30 py-[30px] px-[30px] rounded-[20px] overflow-hidden transition-all ease-in duration-500 text-regular-bold text-white">
          <Lottie
            loop
            animationData={doneAnimation}
            play
            style={{ width: 20, height: 20 }}
          />
          {message}
        </div>
      </div>
    </PopupWrapper>
  );
}
