import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template2.module.css";

export default function Template2({
  carousel,
  handleOpenForm,
  handleLinkClick,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      {/* {carousel.heading && (
        <p className="text-[18px] md:text-[21px] text-white font-medium mt-[22px]">
          {carousel.heading}
        </p>
      )} */}
      <div className="w-[100%] flex flex-col items-center ">
        {carousel?.logo && <img className={styles.logo} src={carousel.logo} />}
        {carousel?.heading && (
          <p className={styles.heading} style={{ fontFamily: carousel?.font }}>
            {carousel?.heading}
          </p>
        )}

        {carousel?.btnText && (
          <div
            className=" px-[24px] py-[8px] mt-[16px] rounded-[30px] font-medium text-[12px] flex items-center justify-center  hover:cursor-pointer "
            style={{
              background: carousel?.btnColor,
              color: carousel?.btnTextColor,
            }}
            onClick={() => {
              if (carousel?.goto === "form") {
                handleOpenForm(carousel);
              } else {
                handleLinkClick(carousel);
              }
            }}
          >
            {carousel?.btnText}
          </div>
        )}
      </div>
      {carousel?.fileType?.startsWith("image") && (
        <img
          src={carousel?.fileUrl}
          alt=""
          className="h-[350px] w-[100%] object-cover mt-[30px]"
        />
      )}
      {carousel?.fileType?.startsWith("video") && (
        <div className="h-[350px] w-[100%] object-cover mt-[30px]">
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
              youtube: { playerVars: { disablekb: 1 } },
            }}
            // playing={true}
            controls={true}
            width="100%"
            light={carousel?.bannerImage ? carousel?.bannerImage : true}
            height="100%"
            url={carousel?.fileUrl}
            className={styles.reactPlayer}
          />
        </div>
      )}
      {/* {carousel.items.length > 2 &&
              <div className='w-[100%] flex items-center justify-end mt-[10px]'>
                <p className='text-[14px] text-[#111111] mr-[10px] italic'>Swipe left to view more</p>
                <img src={swipe} alt="" className='h-[24px] w-[24px]' />
              </div>
            } */}
    </div>
  );
}
