import React, { useContext, useState } from "react";
import styles from "./CouponCart.module.css";
import Context from "../../context/Context";
import SelectedCoupon from "./SelectedCoupon";
import CopyCoupons from "./CopyCoupons";

export default function CouponCart({ setShowCouponCart, setCoupons }) {
  const { selectedCoupons, setSelectedCoupons, tinyUrlFromMetaData } =
    useContext(Context);
  const [copied, setCopied] = useState(false);
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  let campaignId = tinyUrl.split("cp").pop().split("cn")[0];

  function copyCoupons() {
    let copyText = selectedCoupons
      ?.map((coupon) => {
        const { offerText, conditionText, couponCode, generatedCoupon } =
          coupon;
        return `${offerText} ${conditionText} :- ${
          generatedCoupon || couponCode
        }`;
      })
      .join("\n");
    setCopied(true);
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");
    // alert(copyText + "\n copied to clipboard")
  }

  const handleWhatsappShare = () => {
    let phoneNumber = "+918618406454";
    let generated = selectedCoupons
      ?.map((coupon) => {
        const { type, index } = coupon;
        if (type == "GENERATE") {
          return `${index}`;
        }
      })
      .join("_");
    let instatCodes = selectedCoupons
      ?.map((coupon) => {
        const { type, index } = coupon;
        if (type !== "GENERATE") {
          return `${index}`;
        }
      })
      .join("_");
    let message = `Hello there,Please send my coupon codes from Goviral share page to my WhatsApp number. 
Request_ID: CMP_${campaignId}-GC_${generated}-DC_${instatCodes}`;

    const encodedContent = encodeURIComponent(message);
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isMobile) {
      const url = `https://wa.me/${phoneNumber}?text=${encodedContent}`;
      window.open(url, "_blank");
    } else {
      const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodedContent}`;
      window.open(url, "_blank");
    }
  };
  const handleRemoveFromCart = (coupon) => {
    let newCouponObj = coupon;
    newCouponObj.selected = !newCouponObj.selected;
    setSelectedCoupons((prevState) => {
      let couponArray = prevState;
      const isCouponSelected = prevState.some(
        (c) => c.videoName == newCouponObj.videoName
      );
      if (isCouponSelected) {
        return prevState.filter((c) => c.videoName !== newCouponObj.videoName);
      } else {
        return [...prevState, newCouponObj];
      }
    });
    setCoupons((prevState) => {
      let newCouponArray = prevState;
      let index = newCouponArray?.findIndex(
        (el) => el?.videoName == newCouponObj?.videoName
      );
      if (index !== -1) {
        newCouponArray[index] = newCouponObj;
      }
      return newCouponArray;
    });
  };
  return (
    <>
      {copied && <CopyCoupons setCopied={setCopied} />}
      <div
        className={`fixed top-0 left-0 h-[100%] w-[100%] z-[100] p-[40px] bg-white overflow-y-auto no-scrollbar transform transition-opacity ease-in-out duration-300`}
      >
        <div className="mx-auto max-w-[1440px] w-full pb-[90px]">
          <div className="flex gap-[10px] py-[20px] items-center">
            <button
              onClick={() => setShowCouponCart(false)}
              className="rounded-full border-[#D9D9D9] border-[1px] p-[4px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.375 9.375H16.875C17.0408 9.375 17.1997 9.44085 17.3169 9.55806C17.4342 9.67527 17.5 9.83424 17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5592 17.0408 10.625 16.875 10.625H4.375C4.20924 10.625 4.05027 10.5592 3.93306 10.4419C3.81585 10.3247 3.75 10.1658 3.75 10C3.75 9.83424 3.81585 9.67527 3.93306 9.55806C4.05027 9.44085 4.20924 9.375 4.375 9.375Z"
                  fill="#161616"
                />
                <path
                  d="M4.63375 10.0001L9.8175 15.1826C9.93486 15.2999 10.0008 15.4591 10.0008 15.6251C10.0008 15.791 9.93486 15.9502 9.8175 16.0676C9.70014 16.1849 9.54097 16.2508 9.375 16.2508C9.20903 16.2508 9.04986 16.1849 8.9325 16.0676L3.3075 10.4426C3.2493 10.3845 3.20312 10.3155 3.17161 10.2396C3.1401 10.1637 3.12389 10.0823 3.12389 10.0001C3.12389 9.91785 3.1401 9.83645 3.17161 9.76052C3.20312 9.68458 3.2493 9.61561 3.3075 9.55756L8.9325 3.93256C9.04986 3.8152 9.20903 3.74927 9.375 3.74927C9.54097 3.74927 9.70014 3.8152 9.8175 3.93256C9.93486 4.04992 10.0008 4.20909 10.0008 4.37506C10.0008 4.54103 9.93486 4.7002 9.8175 4.81756L4.63375 10.0001Z"
                  fill="#161616"
                />
              </svg>
            </button>
            <h3 className={styles.heading}>Coupon cart</h3>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-[15px]">
            {selectedCoupons?.map((obj) => {
              return (
                <SelectedCoupon
                  data={obj}
                  handleRemoveFromCart={handleRemoveFromCart}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.saveCoupon}>
        <button onClick={copyCoupons}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6931 6.22245C16.6931 4.7497 15.4475 3.55579 13.9109 3.55579H6.49175C4.9552 3.55579 3.70957 4.7497 3.70957 6.22245V16.8891C3.70957 18.3619 4.9552 19.5558 6.49175 19.5558H13.9109C15.4475 19.5558 16.6931 18.3619 16.6931 16.8891V6.22245ZM14.8383 6.22245C14.8383 5.73154 14.4231 5.33356 13.9109 5.33356H6.49175C5.97957 5.33356 5.56436 5.73154 5.56436 6.22245V16.8891C5.56436 17.3801 5.97957 17.778 6.49175 17.778H13.9109C14.4231 17.778 14.8383 17.3801 14.8383 16.8891V6.22245Z"
              fill="black"
            />
            <path
              d="M2.78218 1.77778H12.0561C12.5683 1.77778 12.9835 1.3798 12.9835 0.888889C12.9835 0.397973 12.5683 0 12.0561 0H2.78218C1.24563 0 0 1.19391 0 2.66667V15.1111C0 15.602 0.415212 16 0.927392 16C1.43957 16 1.85478 15.602 1.85478 15.1111V2.66667C1.85478 2.17575 2.27 1.77778 2.78218 1.77778Z"
              fill="black"
            />
          </svg>
          Copy all codes{" "}
        </button>
        <button onClick={handleWhatsappShare}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.20932 9.49998C2.20932 5.25266 5.57239 1.80952 9.72093 1.80952C13.8695 1.80952 17.2326 5.25266 17.2326 9.49998C17.2326 13.7473 13.8695 17.1904 9.72093 17.1904C8.24317 17.1904 6.86763 16.7545 5.70705 16.0017C5.49106 15.8616 5.2264 15.8242 4.98139 15.899L2.43703 16.6764L3.39502 14.3814C3.50825 14.1102 3.48521 13.7995 3.33328 13.5489C2.62085 12.3735 2.20932 10.9873 2.20932 9.49998ZM9.72093 0C4.59626 0 0.441879 4.25329 0.441879 9.49998C0.441879 11.163 0.860003 12.7284 1.59481 14.0896L0.0711143 17.7396C-0.0635842 18.0624 -0.00387827 18.4357 0.224307 18.6975C0.452493 18.9594 0.808005 19.0626 1.13646 18.9621L5.11269 17.7473C6.471 18.5444 8.04513 19 9.72093 19C14.8456 19 19 14.7467 19 9.49998C19 4.25329 14.8456 0 9.72093 0ZM11.7469 11.4745L10.5855 12.3121C10.0415 11.9949 9.44017 11.5521 8.83695 10.9345C8.20986 10.2925 7.74495 9.62963 7.40215 9.02163L8.14022 8.38025C8.45695 8.10502 8.54364 7.641 8.34851 7.26548L7.40809 5.45596C7.28146 5.21229 7.05312 5.04114 6.78799 4.99114C6.52286 4.94116 6.24995 5.0178 6.04683 5.19928L5.76801 5.44842C5.09751 6.04754 4.70095 7.03206 5.02962 8.02893C5.37036 9.06235 6.09753 10.6888 7.5872 12.214C9.1899 13.8549 10.8179 14.501 11.7699 14.752C12.537 14.9541 13.2629 14.6832 13.7744 14.2565L14.2971 13.8204C14.5207 13.6339 14.6413 13.3474 14.6203 13.0529C14.5992 12.7584 14.439 12.4929 14.1912 12.3417L12.7081 11.437C12.4085 11.2543 12.032 11.269 11.7469 11.4745Z"
              fill="black"
            />
          </svg>
          Send all codes{" "}
        </button>
      </div>
    </>
  );
}
