import React, { useState } from "react";
import ReactPlayer from "react-player";
import heart from "../../assests/heart.svg";
import * as _ from "lodash";
import whiteHeart from "../../assests/whiteHeart.svg";
import Play from "../../../src/assests/play.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import ProfileDropDown from "../ProfileDropDown/ProfileDropDown";
import user from "../../assests/user.svg";
import NormalPromotion from "./NormalPromotion";

export default function CampaignPromotion({
  promotionDataJson,
  setShare,
  setOpenDetail,
  likeState,
  likeCount,
  handleLogin,
  likeLoading,
  likeHandle,
  handleOpenForm,
  handleOpenLink,
  hanldeOpenCampaign,
  inflencerDetails,
  handleOpenCoupon,
  handleProductPurchase
}) {
  const { currentUser, setReqForLike } = useUserAuth();
  const [play, setPlay] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const handleProfileClick = () => {
    if (currentUser) {
      setShowProfileDropdown((prevState) => !prevState);
    } else {
      handleLogin();
    }
  };

  const formImageRender = (innerWidth) => {
    if (
      !_.isEmpty(
        promotionDataJson?.bannerMobile || promotionDataJson?.bannerWeb
      )
    ) {
      if (innerWidth <= 600) {
        return promotionDataJson?.bannerMobile;
      } else {
        return promotionDataJson?.bannerWeb;
      }
    } else {
      return promotionDataJson?.fileUrl;
    }
  };

  let templateType = promotionDataJson?.templateType;
  let pageJSX;

  switch (templateType) {
    case "DEFAULT":
      pageJSX = (
        <NormalPromotion
          promotionDataJson={promotionDataJson}
          setShare={setShare}
          setOpenDetail={setOpenDetail}
          likeState={likeState}
          likeCount={likeCount}
          handleLogin={handleLogin}
          likeHandle={likeHandle}
          likeLoading={likeLoading}
          handleOpenLink={handleOpenLink}
          handleOpenForm={handleOpenForm}
          handleOpenCoupon={handleOpenCoupon}
        />
      );
      break;
    default:
      pageJSX = (
        <div className="template-banner-container">
          <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto ">
            <div className="w-full py-[20px] hidden md:block">
              <div
                className="w-fit ml-auto items-center gap-[10px] flex self-end relative py-[6px] px-[10px] transition duration-300 ease-in z-10"
                onClick={handleProfileClick}
                style={{
                  background: showProfileDropdown ? "#171717 " : "",
                  color: showProfileDropdown ? "#F6F6F6" : "#F6F6F6",
                }}
              >
                <p className="text-regular-medium ">
                  {currentUser?.displayName}
                </p>
                <div className="flex md:h-[52px] md:w-[52px] min-w-[44px] md:min-w-[52px]  h-[44px] w-[44px] bg-blue-400  items-center justify-center rounded-full">
                  <img
                    src={currentUser?.photoURL ? currentUser?.photoURL : user}
                    alt=""
                    className="h-full w-full object-cover rounded-full overflow-hidden cursor-pointer z-10"
                  />
                </div>
                {showProfileDropdown && <ProfileDropDown />}
              </div>
            </div>

            <div className=" max-h-auto  relative overflow-hidden">
              <div className="template-layout">
                <div className="template-left">
                  <div className="flex flex-col gap-[28px]">
                    <div className="flex items-start justify-between ">
                      <div className=" mr-auto">
                        <img
                          src={
                            inflencerDetails?.displayDetails
                              ? inflencerDetails?.dop
                              : promotionDataJson?.eventHeader?.promoterLogo
                          }
                          alt=""
                          className="template-logo"
                        />
                        {/* <div>
                                  <p className="template-promotion-name">
                                    {inflencerDetails?.displayDetails
                                      ? inflencerDetails?.promoterName
                                      : promotionDataJson?.eventHeader?.promoterName}
                                  </p>
                                  <p className={`md:mt-[7px] "template-subtext-medium`}>
                                    {promotionDataJson?.eventHeader?.type}
                                  </p>
                                </div> */}
                      </div>
                      <div className=" block md:hidden">
                        <div
                          className="min-w-[100px] justify-end items-center gap-[10px] flex self-end relative py-[6px] px-[10px] transition duration-300 ease-in z-10"
                          onClick={handleProfileClick}
                          style={{
                            background: showProfileDropdown ? "#171717 " : "",
                            color: showProfileDropdown ? "#F6F6F6" : "#F6F6F6",
                          }}
                        >
                          <div className="flex md:h-[52px] md:w-[52px] min-w-[40px] md:min-w-[52px]  h-[40px] w-[40px] bg-blue-400  items-center justify-center rounded-full">
                            <img
                              src={
                                currentUser?.photoURL
                                  ? currentUser?.photoURL
                                  : user
                              }
                              alt=""
                              className="h-full w-full object-cover rounded-full overflow-hidden cursor-pointer z-10"
                            />
                          </div>
                          {showProfileDropdown && <ProfileDropDown />}
                        </div>
                      </div>
                    </div>
                    <div className="flex md:flex-col gap-[28px] justify-between md:justify-start md:items-start items-center">
                      <div className="flex flex-col gap-[5px] ">
                        <h1>
                          {promotionDataJson?.eventHeader?.promoNameHeading}
                        </h1>
                        <h2>
                          {promotionDataJson?.eventHeader?.promoNameTagLine}
                        </h2>
                      </div>

                      <button
                        onClick={() =>
                          promotionDataJson?.showMoreHeader?.type == "link"
                            ? handleOpenLink(promotionDataJson)
                            : handleOpenForm(promotionDataJson)
                        }
                        className={`ml-0 md:ml-[0px] md:hidden flex template-register-button`}
                      >
                        {promotionDataJson?.showMoreHeader?.name}
                      </button>

                      {promotionDataJson?.showMoreHeader?.display && (
                        <button
                          onClick={() =>
                            promotionDataJson?.showMoreHeader?.type == "link"
                              ? handleOpenLink(promotionDataJson)
                              : handleOpenForm(promotionDataJson)
                          }
                          className={`ml-0 md:ml-[0px] template-register-button`}
                        >
                          {promotionDataJson?.showMoreHeader?.name}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <pre
                      className={`text_Ell mb-[14px] template-view-details-description`}
                    >
                      {promotionDataJson?.aboutPromotion}
                    </pre>
                    <button
                      onClick={hanldeOpenCampaign}
                      className="template-view-details-button"
                    >
                      know more{" "}
                      <img
                        src={promotionDataJson?.campaignDetails?.buttonLogo}
                      />
                    </button>
                  </div>
                </div>
                <div className="template-right">
                  <div className="w-full h-[84%] md:ml-[-3%] flex  template-right-inner-container ">
                    <div className="template-campaign-file-container">
                      {promotionDataJson?.fileType?.startsWith("video") ? (
                        (!play && (
                          <>
                            <div
                              onClick={() => setPlay(true)}
                              className="flex justify-center items-center  overflow-hidden"
                            >
                              <img
                                src={promotionDataJson?.thumbReplaceUrl}
                                alt=""
                                className="md:h-full h-auto w-full md:w-auto  object-contain "
                              />
                              <img
                                className="absolute h-[60px] w-[60px] cursor-pointer"
                                src={Play}
                                alt=""
                              />
                            </div>
                          </>
                        )) ||
                        (play && (
                          <div className="flex justify-center items-center w-[100%] md:px-[100px] h-[100%] overflow-hidden">
                            <ReactPlayer
                              config={{
                                file: {
                                  attributes: { controlsList: "nodownload" },
                                },
                              }}
                              playing={true}
                              controls={true}
                              width="100%"
                              height="100%"
                              playIcon={promotionDataJson?.thumbReplaceUrl}
                              url={promotionDataJson?.fileUrl}
                              className="object-contain height-[100%] abovethousand"
                            />
                            <ReactPlayer
                              config={{
                                file: {
                                  attributes: { controlsList: "nodownload" },
                                },
                              }}
                              playing={true}
                              controls={true}
                              width="100%"
                              height="100%"
                              playIcon={promotionDataJson?.thumbReplaceUrl}
                              url={promotionDataJson?.fileUrl}
                              className="object-contain height-[100%] belowthousand"
                            />
                          </div>
                        ))
                      ) : (
                        <img
                          src={formImageRender(window.innerWidth)}
                          className="w-full h-full  object-cover "
                        />
                      )}
                    </div>

                    <div className="template-campaign-details flex flex-col">
                      <h1>
                        {promotionDataJson?.campaignDetails?.campaignName}
                      </h1>
                      <ul>
                        {promotionDataJson?.campaignDetails?.priceDescription?.map(
                          (el) => {
                            return <li>{el}</li>;
                          }
                        )}
                      </ul>
                      <div className="mt-[20px] pl-[40px]">
                        <h3>
                          {promotionDataJson?.campaignDetails?.judgeTitle}
                        </h3>
                        <h2>
                          {promotionDataJson?.campaignDetails?.judgeHeading}
                        </h2>
                        <div className="flex gap-[15px]  mt-[10px] overflow-x-auto no-scrollbar text-center">
                          {promotionDataJson?.campaignDetails?.judges?.map(
                            (judge, index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex flex-col gap-[4px] items-center"
                                >
                                  <div className="template-judge-image">
                                    <img
                                      src={judge?.logo}
                                      className="w-full h-full object-cover"
                                    />
                                  </div>
                                  <p>{judge?.name}</p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="template-camapign-prize-tagline">
                        <p>
                          {
                            promotionDataJson?.campaignDetails
                              ?.campaignPriceDescription
                          }
                        </p>
                        <div className="template-camapign-prize-line1" />

                        <div className="template-camapign-prize-line2" />
                      </div>
                    </div>
                  </div>
                  <div className="h-fit pt-[4%] px-[3%] flex gap-[3%] items-center justify-end">
                    <button
                      className={`template-button-like-share                   
                                 flex items-center justify-center  transition duration-300 ease-in 
                                   ${likeState ? "template-button-liked " : ""}
                                 `}
                      onClick={
                        currentUser
                          ? () => {
                              setReqForLike(true);
                              likeHandle();
                            }
                          : () => handleLogin()
                      }
                      disabled={likeLoading}
                    >
                      {!likeLoading && (
                        <>
                          {likeCount}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            className="mr-[12px] ml-[3px]"
                          >
                            <path
                              d="M16.875 6.60938C16.875 11.5312 9.57726 15.5152 9.26648 15.6797C9.18457 15.7238 9.09301 15.7468 9 15.7468C8.90699 15.7468 8.81543 15.7238 8.73352 15.6797C8.42273 15.5152 1.125 11.5312 1.125 6.60938C1.1263 5.4536 1.58601 4.34553 2.40327 3.52827C3.22053 2.71101 4.3286 2.2513 5.48438 2.25C6.93633 2.25 8.20758 2.87437 9 3.92977C9.79242 2.87437 11.0637 2.25 12.5156 2.25C13.6714 2.2513 14.7795 2.71101 15.5967 3.52827C16.414 4.34553 16.8737 5.4536 16.875 6.60938Z"
                              fill={likeState ? "#E07578" : "#FFFFFF"}
                            />
                          </svg>
                          Likes
                        </>
                      )}
                      {likeLoading && (
                        <span className="template-button-loader" />
                      )}
                    </button>
                    <button
                      onClick={() => setShare(true)}
                      className={`template-button-like-share`}
                    >
                      <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                        Share
                      </p>
                    </button>
                  </div>
                </div>

                <div className="template-right-mobile">
                  <div className="w-full  md:ml-[-3%]   template-right-inner-container ">
                    <h1>{promotionDataJson?.campaignDetails?.campaignName}</h1>
                    <div className="template-campaign-container">
                      <ul>
                        {promotionDataJson?.campaignDetails?.priceDescription?.map(
                          (el) => {
                            return <li>{el}</li>;
                          }
                        )}
                      </ul>
                      <div className="template-camapign-prize-tagline">
                        <img
                          src={promotionDataJson?.campaignDetails?.giftLogo}
                        />
                        <p>
                          {
                            promotionDataJson?.campaignDetails
                              ?.campaignPriceDescription
                          }
                        </p>
                      </div>
                      <div className="template-campaign-file-container">
                        {promotionDataJson?.fileType?.startsWith("video") ? (
                          (!play && (
                            <>
                              <div
                                onClick={() => setPlay(true)}
                                className="flex justify-center items-center  overflow-hidden"
                              >
                                <img
                                  src={promotionDataJson?.thumbReplaceUrl}
                                  alt=""
                                  className="md:h-full h-auto w-full md:w-auto  object-contain "
                                />
                                <img
                                  className="absolute h-[60px] w-[60px] cursor-pointer"
                                  src={Play}
                                  alt=""
                                />
                              </div>
                            </>
                          )) ||
                          (play && (
                            <div className="flex justify-center items-center w-[100%] md:px-[100px] h-[100%] overflow-hidden">
                              <ReactPlayer
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                                playing={true}
                                controls={true}
                                width="100%"
                                height="100%"
                                playIcon={promotionDataJson?.thumbReplaceUrl}
                                url={promotionDataJson?.fileUrl}
                                className="object-contain height-[100%] abovethousand"
                              />
                              <ReactPlayer
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                                playing={true}
                                controls={true}
                                width="100%"
                                height="100%"
                                playIcon={promotionDataJson?.thumbReplaceUrl}
                                url={promotionDataJson?.fileUrl}
                                className="object-contain height-[100%] belowthousand"
                              />
                            </div>
                          ))
                        ) : (
                          <img
                            src={formImageRender(window.innerWidth)}
                            className="w-full h-full  object-cover  "
                          />
                        )}
                      </div>

                      <div className="template-campaign-details ">
                        <div className="mt-[20px]  flex flex-col items-center">
                          <h3>
                            {promotionDataJson?.campaignDetails?.judgeTitle}
                          </h3>
                          <h2>
                            {promotionDataJson?.campaignDetails?.judgeHeading}
                          </h2>
                          <div className="flex gap-[15px]  mt-[10px]">
                            {promotionDataJson?.campaignDetails?.judges?.map(
                              (judge, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex flex-col gap-[4px] items-center"
                                  >
                                    <div className="template-judge-image">
                                      <img
                                        src={judge?.logo}
                                        className="w-full h-full object-cover"
                                      />
                                    </div>
                                    <p>{judge?.name}</p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-fit pt-[4%] px-[3%] flex gap-[3%] items-center justify-end">
                    <button
                      className={`template-button-like-share                   
                                 flex items-center justify-center  transition duration-300 ease-in 
                                   ${likeState ? "template-button-liked " : ""}
                                 `}
                      onClick={
                        currentUser
                          ? () => {
                              setReqForLike(true);
                              likeHandle();
                            }
                          : () => handleLogin()
                      }
                      disabled={likeLoading}
                    >
                      {!likeLoading && (
                        <>
                          {likeCount}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            className="mr-[12px] ml-[3px]"
                          >
                            <path
                              d="M16.875 6.60938C16.875 11.5312 9.57726 15.5152 9.26648 15.6797C9.18457 15.7238 9.09301 15.7468 9 15.7468C8.90699 15.7468 8.81543 15.7238 8.73352 15.6797C8.42273 15.5152 1.125 11.5312 1.125 6.60938C1.1263 5.4536 1.58601 4.34553 2.40327 3.52827C3.22053 2.71101 4.3286 2.2513 5.48438 2.25C6.93633 2.25 8.20758 2.87437 9 3.92977C9.79242 2.87437 11.0637 2.25 12.5156 2.25C13.6714 2.2513 14.7795 2.71101 15.5967 3.52827C16.414 4.34553 16.8737 5.4536 16.875 6.60938Z"
                              fill={likeState ? "#E07578" : "#FFFFFF"}
                            />
                          </svg>
                          Likes
                        </>
                      )}

                      {likeLoading && (
                        <span className="template-button-loader" />
                      )}
                    </button>
                    <button
                      onClick={() => setShare(true)}
                      className={`template-button-like-share`}
                    >
                      Share
                    </button>
                  </div>

                  <div className="block md:hidden mt-[26px] flex flex-col items-center">
                    <pre
                      className={`text_Ell mb-[14px] template-view-details-description`}
                    >
                      {promotionDataJson?.aboutPromotion}
                    </pre>
                    <button
                      onClick={hanldeOpenCampaign}
                      className="template-view-details-button"
                    >
                      know more{" "}
                      <img
                        src={promotionDataJson?.campaignDetails?.buttonLogo}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
  return <>{pageJSX}</>;
}
