import React, { useContext, useEffect, useState } from "react";
import "./Share.css";
import close from "../../assests/closebuttonblack.png";
import ServiceTerms from "./ServiceTerms";
import PurchaseFormInput from "./PurchaseFormInput";
import Context from "../../context/Context";
import styles from "./styles/PurchasePopup.module.css";

const PurchasePopup = ({ service, setShowPopup, promotionData }) => {
  const { setGallaryClick, tinyUrlFromMetaData } = useContext(Context);
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  let promotion = tinyUrl.startsWith("pro");
  const [showTerms, setShowTerms] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  var coverStyle = {
    background: `linear-gradient(0deg, rgba(1, 1, 15, 0.7) 0%, rgba(1, 1, 15, 0.7) 100%), url('${promotionData?.bannerWeb}') lightgray 50% / cover no-repeat`,
  };

  return (
    <>
      <div className="w-[100%] h-[100%] fixed top-0 flex flex-col items-center justify-center serviceformbg backdrop-blur-xl z-[50] overflow-x-hidden overflow-y-auto no-scrollbar">
        <div className=" absolute top-0 w-full">
          <div className="h-auto  box w-full w-full bg-[#FFFFFF] pb-[30px] mx-auto">
            <div className="w-[100%] h-[30%] relative overflow-hidden">
              <div
                className="w-[100%] h-[200px] relative serviceformbanner flex flex-col items-center text-center justify-center gap-[8px] px-[5%] py-[2%]"
                style={coverStyle}
              >
                {!promotionData?.showMoreHeader?.directReg && (
                  <div
                    className="absolute top-2 right-2 rounded-full bg-white h-[48px] w-[48px] flex justify-center items-center hover:cursor-pointer"
                    onClick={() => {
                      setShowPopup(false);
                      setGallaryClick({});
                    }}
                  >
                    <img src={close} alt="" className="h-[16px] w-[16px] " />
                  </div>
                )}
                <h2 className={`${styles.heading} ${styles.malayalam}`}>
                  {promotionData?.eventHeader?.eventName}
                </h2>
                <h3 className={styles.subHeading}>
                  {promotionData?.eventHeader?.location}
                </h3>
              </div>
            </div>
            <div className="max-w-[800px] w-full mx-auto">
              {promotionData?.showMoreHeader?.regOpen == false ? (
                <pre className="leadcardtext text-black text-center py-[50px] px-[20px]">
                  {promotionData?.showMoreHeader?.regText || "Will open soon"}
                </pre>
              ) : (
                <PurchaseFormInput
                  setShowPopup={setShowPopup}
                  mail={service?.email}
                  setShowTerms={setShowTerms}
                  promotionData={promotionData}
                  service={service}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showTerms && <ServiceTerms setShowTerms={setShowTerms} />}
    </>
  );
};

export default PurchasePopup;
