import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template5.module.css";

export default function Template5({
  carousel,
  handleOpenForm,
  handleLinkClick,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      {carousel.heading && (
        <p
          className={` text-[24px] md:text-[24px]  mb-[16px] ${styles.heading} 
          ${
            carousel.items.length < 5
              ? "md:text-center md:pl-[0px] pl-[16px] "
              : "md:pl-[100px] pl-[16px]"
          }
          `}
          style={{ fontFamily: carousel?.font }}
        >
          {carousel.heading}
        </p>
      )}
      <div
        className={`flex box  gap-[16px] pr-[16px] overflow-x-auto  ${
          carousel.items.length < 5
            ? "md:justify-center md:pl-[0px] pl-[16px]"
            : "md:pl-[100px] pl-[16px]"
        }`}
      >
        {carousel &&
          carousel.items &&
          carousel.items.map((add) => {
            return (
              <>
                <div
                  className="md:w-[264px] w-[264px]  flex flex-col align-center justify-center mb-[16px]"
                  style={{ background: add?.bgColor }}
                >
                  <div className="min-w-[264px] md:w-[264px]  pt-[60px] px-[30px]">
                    {add?.fileType?.startsWith("image") && (
                      <img
                        src={add.fileUrl}
                        alt=""
                        className="  aspect-square object-cover rounded-full"
                      />
                    )}
                    {add.fileType?.startsWith("video") && (
                      <div className=" aspect-square object-cover rounded-full">
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                            youtube: { playerVars: { disablekb: 1 } },
                          }}
                          // playing={true}
                          controls={true}
                          width="100%"
                          light={add?.thumbnailUrl ? add?.thumbnailUrl : true}
                          height="100%"
                          url={add?.fileUrl}
                          className={styles.reactPlayer}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="p-[16px] text-center cursor-pointer"
                    style={{ color: add?.btnTextColor }}
                    onClick={() => {
                      if (add.goto === "form") {
                        handleOpenForm(add);
                      } else {
                        handleLinkClick(add);
                      }
                    }}
                  >
                    {add?.shopbuttton && (
                      <button className="  text-[21px] font-medium ">
                        {add.shopbuttton}
                      </button>
                    )}
                    {add?.tagLine && (
                      <p
                        className={`text_Ell ${styles.tagline}`}
                        style={{ fontFamily: carousel?.font }}
                      >
                        {add.tagLine}
                      </p>
                    )}
                  </div>
                </div>
              </>
            );
          })}
      </div>
      {/* {carousel.items.length > 2 &&
              <div className='w-[100%] flex items-center justify-end mt-[10px]'>
                <p className='text-[14px] text-[#111111] mr-[10px] italic'>Swipe left to view more</p>
                <img src={swipe} alt="" className='h-[24px] w-[24px]' />
              </div>
            } */}
    </div>
  );
}
