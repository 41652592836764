import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template1.module.css";

export default function Template1({
  carousel,
  handleOpenForm,
  handleLinkClick,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      {carousel.heading && (
        <p
          className={`text-[16px] md:text-[21px]  mt-[24px] text-white font-semibold  mb-[16px] 
        ${
          carousel.items.length < 5
            ? "md:text-center md:pl-[0px] pl-[16px] "
            : "md:pl-[100px] pl-[16px]"
        }
        `}
        >
          {carousel.heading}
        </p>
      )}
      <div
        className={` h-[100%] flex box w-[100%] gap-[16px] pr-[16px] overflow-x-auto  ${
          carousel.items.length < 5
            ? "md:justify-center md:pl-[0px] pl-[16px]"
            : "md:pl-[100px] pl-[16px]"
        }`}
      >
        {carousel &&
          carousel.items &&
          carousel.items.map((add) => {
            return (
              <>
                <div
                  className="md:w-[300px] w-[300px] aspect-square flex flex-col bg-[black]  "
                  // style={{backgroundColor: add?.bgColor}}
                >
                  {add?.fileType?.startsWith("image") && (
                    <img
                      src={add.fileUrl}
                      alt=""
                      className="min-w-[300px] md:w-[300px] pb-[16px] md:h-[100%] aspect-square w-[300px] h-[100%] object-cover "
                    />
                  )}
                  {add.fileType?.startsWith("video") && (
                    <div className="min-w-[300px] md:w-[300px] md:h-[100%] w-[300px] aspect-square h-[100%] object-cover ">
                      <ReactPlayer
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                          youtube: { playerVars: { disablekb: 1 } },
                        }}
                        // playing={true}
                        controls={true}
                        width="100%"
                        light={true}
                        height="100%"
                        url={add?.fileUrl}
                        className={styles.reactPlayer}
                      />
                    </div>
                  )}
                  {add?.description && (
                    <p className="font-[14px] text_Ell text-white  px-[15p] mb-[10px]  text-left">
                      {add.description}
                    </p>
                  )}

                  {add?.shopbuttton && (
                    <div
                      className="w-max px-[16px] py-[8px] bg-[#B81C2A] text-white rounded-[50px] font-medium flex items-center justify-center mt-auto hover:cursor-pointer mx-[15p]"
                      onClick={() => {
                        if (add.goto === "form") {
                          handleOpenForm(add);
                        } else {
                          handleLinkClick(add);
                        }
                      }}
                    >
                      {add.shopbuttton}
                    </div>
                  )}
                </div>
              </>
            );
          })}
      </div>
      {/* {carousel.items.length > 2 && (
        <div className="w-[100%] flex items-center justify-end mt-[10px]">
          <p className="text-[14px] text-[#111111] mr-[10px] italic">
            Swipe left to view more
          </p>
          <img src={swipe} alt="" className="h-[24px] w-[24px]" />
        </div>
      )} */}
    </div>
  );
}
