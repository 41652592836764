import React, { useContext, useEffect, useState } from "react";
import styles from "./InstallButton.module.css";
import Context from "../../context/Context";
import gvLogo from "./gvLogo.svg";
import { useNavigate } from "react-router";

function InstallButton() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  // const { promptInstall, setPromptInstall } = useContext(Context);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setSupportsPWA(true);
      setShow(true);
    }, 2000);
  }, []);
  // useEffect(() => {
  //   const handler = (e) => {
  //     console.log("we are being triggered :D");
  //     e.preventDefault();
  //     setSupportsPWA(true);
  //     setPromptInstall(e);
  //   };
  //   window.addEventListener("beforeinstallprompt", handler);

  //   return () => window.removeEventListener("transitionend", handler);
  // }, []);

  // const onClick = (evt) => {
  //   evt.preventDefault();
  //   if (!promptInstall) {
  //     return;
  //   }
  //   promptInstall.prompt();
  // };
  const onClick = () => {
    navigate("/install");
    setSupportsPWA(false);
  };
  if (!supportsPWA) {
    console.log("pwa not supported");
    return null;
  }
  return (
    <div
      className={`fixed top-0 left-0 h-[100%] w-[100%] z-10 p-[20px] ${
        styles.popupBackground
      } overflow-y-auto no-scrollbar transform transition-transform ease-in-out duration-300
      ${show ? "translate-y-0" : "translate-y-full"}`}
    >
      <div className={styles.prompt}>
        <img src={gvLogo} />
        <h3>Install Goviral App</h3>
        <p>Install app on your home screen for quick access</p>
        <div className="flex items-center justify-around w-full">
          <button
            className={styles.cancel}
            onClick={() => setSupportsPWA(false)}
          >
            Cancel
          </button>
          <button className={styles.install} onClick={onClick}>
            Install
          </button>
        </div>
      </div>
    </div>
  );
}

export default InstallButton;
