import { useEffect, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";

function UserLinkGenrationCampaign({
  generateLinkLoading,
  handleLogin,
  handleShareLink,
  promotionDataJson,
  handleClickCounterPromotion,
  setPromotorShare,
}) {
  const { currentUser, reqForPromoLink, setReqForPrmoLink } = useUserAuth();
  useEffect(() => {
    if (reqForPromoLink) {
      handleShareLink();
      setReqForPrmoLink(false);
    }
  }, [currentUser]);
  const [admin, setIsAdmin] = useState(false);
  const tinyUrl = window.location.pathname
    .split("/")[1]
    ?.replace(/\.html$/, "");
  const promotorId = tinyUrl.split("inf").pop().split("c")[0];

  useEffect(() => {
    if (tinyUrl == promotorId) {
      setIsAdmin(true);
    }
  }, []);

  const viewContest = () => {
    window.open(
      `https://contest.goviral.world/${promotionDataJson?.campaignDetails?.orgDomain}/${promotionDataJson?.campaignDetails?.campaignUniqueText}.html?p=${promotorId}`
    );
    handleClickCounterPromotion(
      "leads",
      promotionDataJson._id,
      "footerButton",
      "footerButton",
      promotionDataJson?.contactTitle,
      "leads",
      "promotion"
    );
  };
  return (
    <div className="template-link-container ">
      <div className="template-link-content">
        <h2>
          {admin ? "Ready to reach out to millions?" : "Ready to win big? "}
        </h2>
        <h1>
          {admin
            ? "Promote this event and unlock endless possibilities!"
            : "Seize your chance in our captivating contests!"}
        </h1>

        {admin && (
          <button
            onClick={() => setPromotorShare(true)}
            className="tempalte-genrateLink"
            disabled={generateLinkLoading}
          >
            {generateLinkLoading ? "Loading" : "How To Promote?"}
          </button>
        )}

        {!admin && (
          <button onClick={viewContest} className="tempalte-genrateLink">
            VIEW CONTESTS
          </button>
        )}
      </div>
    </div>
  );
}

export default UserLinkGenrationCampaign;
