import React from "react";
import styles from "./CouponCart.module.css";

export default function CartBanner({ setShowCouponCart }) {
  return (
    <div className={styles.cartBanner}>
      <div className="w-full max-w-[1440px] mx-auto px-[16px] md:px-[116px] flex justify-center sm:justify-end">
        <button onClick={() => setShowCouponCart(true)}>
          {" "}
          SEE COUPON CART
        </button>
      </div>
    </div>
  );
}
