import React from "react";
import styles from "./PurchaseList.module.css";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import regSuccess from "../../assests/regSuccess.svg";
import regFailed from "../../assests/regFailed.svg";

export default function PurchaseList({ details }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Link
      to={{
        pathname: details?.orderId,
        search: `${searchParams ? createSearchParams(searchParams) : ""}`,
      }}
    >
      <div className={styles.container}>
        <div className="flex gap-[15px]">
          <div className="flex flex-col gap-[8px]">
            <p className="text-small-bold">{details?.name}</p>
            <p className="text-small ">{details?.orderId}</p>
            <p className="text-small ">{details?.birthStar}</p>
            <p className="text-small ">₹ {details?.totalAmt}</p>
            <p className="text-small ">
              {details?.status == "CREATED" && "Pending Approval"}
              {details?.status == "FREE_ENTRY_CREATED" && "Pending Approval"}
              {details?.status == "APPROVED" && "Approved"}
              {details?.status == "REJECTED" && "Rejected"}
            </p>
            {/* <p className="text-small flex items-center gap-[5px]">
              {details?.paymentStatus}
              <img
                src={
                  details?.paymentStatus == "PAYMENT_SUCCESS" ||
                  details?.paymentStatus == "FREE_ENTRY"
                    ? regSuccess
                    : regFailed
                }
                alt=""
                style={{ width: "15px", height: "15px" }}
              />
            </p> */}
          </div>
        </div>
        <button>View Order</button>
      </div>
    </Link>
  );
}
