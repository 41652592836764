import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Back from "./Back";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AccordianSection from "./AccordianSection";
import LoadingCircle from "./LoadingCircle/LoadingCircle";
import styles from "./InstallPage.module.css";

export default function InstallPage() {
  const [scrolling, setScrolling] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  const getData = async () => {
    try {
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/goviral_magazine_data/install.json`,
      };
      let result = await axios(config);
      setData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const bgImage = {
    background: `url(${data?.bannerImage}) center center / cover`,
  };
  return (
    <div className="fonts-for-Sathosi-howitWorks min-h-[80vh] bg-white">
      <div
        onClick={handleNavigateToPrevLocation}
        className={`fonts-for-Sathosi-homepage py-4 sticky top-0  w-[100%] ${
          scrolling && "py-4"
        } bg-[white]   items-center z-20   `}
      >
        <div className="ml-4">
          <Back />
        </div>
      </div>
      <div className=" max-w-[1440px] mx-auto">
        {data?.goviralSharePageSteps ? (
          <>
            <div style={bgImage} className={styles.banner}>
              <div className="w-[90%] max-w-[1440px] mx-auto">
                <h2>{data?.bannerHeading}</h2>
                <p>{data?.bannerPara}</p>
              </div>
            </div>
            <AccordianSection data={data?.goviralSharePageSteps} />
          </>
        ) : (
          <div className=" mx-auto mt-20">
            <LoadingCircle />
          </div>
        )}
      </div>
      {data?.goviralSharePageSteps && <Footer />}
    </div>
  );
}
