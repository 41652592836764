import React from 'react'
import uploadanimation from './upload-animation.json'
import Lottie from 'react-lottie-player'
function finalUploadloading() {
  return (
    <>
      <div className="flex items-center justify-center h-[100vh] w-[100%] flex-col">
        <Lottie
          loop
          animationData={uploadanimation}
          play
          style={{ width: 173, height: 173 }}
        />
      </div>
    </>
  )
}

export default finalUploadloading
