import React, { useContext, useEffect, useState } from "react";
import "./Share.css";
import close from "../../assests/closebuttonblack.png";
import ServiceFormInput from "./ServiceFormInput";
import ServiceTerms from "./ServiceTerms";
import Context from "../../context/Context";

const ServiceForm = ({ service, setServiceForm, promotionData }) => {
  const { tinyUrlFromMetaData } = useContext(Context);
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  let promotion = tinyUrl.startsWith("pro");

  const [showTerms, setShowTerms] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const type = service?.formType || promotionData?.formType;

  return (
    // w-[100%] h-[100%] absolute backdrop flex flex-col items-center justify-center
    <div className="w-[100%] h-[100%] fixed top-0 serviceformbg backdrop-blur-xl z-[50] overflow-y-auto no-scrollbar px-[20px]">
      <div className="w-full h-auto max-w-[800px]  bg-[#FFFFFF] rounded-[20px] my-[20px] mx-auto">
        <div className="w-[100%] h-[400px] relative rounded-t-[20px] overflow-hidden">
          <div
            className="absolute top-2 right-2 rounded-full bg-white h-[48px] w-[48px] flex justify-center items-center hover:cursor-pointer"
            onClick={() => setServiceForm(false)}
          >
            <img src={close} alt="" className="h-[16px] w-[16px] " />
          </div>
          <img
            src={
              promotion
                ? promotionData?.bannerMobile
                : service?.fileType?.startsWith("image")
                ? service?.fileUrl
                : service?.thumbnailUrl
            }
            alt=""
            className="w-[100%] h-[100%] object-cover"
          />
          <div className="w-[100%] h-[149px]  absolute bottom-0 serviceformbanner flex flex-col items-center text-center justify-evenly px-[5%] py-[2%]">
            <p className="serviceformheading text-[21px] md:text-[32px]">
              {promotion
                ? promotionData?.eventHeader?.eventName
                : service?.title}
            </p>
            <p className="servicedescription text-[14px] md:text-[16px]">
              {promotion ? promotionData?.description : service?.description}
            </p>
          </div>
        </div>
        <ServiceFormInput
          setServiceForm={setServiceForm}
          mail={service?.email}
          setShowTerms={setShowTerms}
          promotionData={promotionData}
          service={service}
          type={type}
        />
      </div>

      {showTerms && <ServiceTerms setShowTerms={setShowTerms} />}
    </div>
  );
};

export default ServiceForm;
