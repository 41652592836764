import React, { useContext, useEffect, useState } from "react";
import ParticipationCertificate from "../Components/ParticipationCertificate/ParticipationCertificate";
import axios from "axios";
import BlackLeadsFooter from "../Components/ShareBlackTemplate/BlackLeadsFooter";
import Context from "../context/Context";
import { useUserAuth } from "../context/UserAuthContext";

export default function CertificatePage() {
  const tinyUrl = window.location.pathname.split("/")[1]?.replace(/\.html$/, "");;
  const orgId = tinyUrl.split("o").pop().split("cp")[0];
  const campaignId = tinyUrl.split("cp").pop().split("cn")[0];
  let contestId = tinyUrl.split("cn").pop().split("e")[0];
  let videoId = tinyUrl.split("e").pop();
  const [campaignTemplateData, setCampaignTemplateData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);
  const { userIdToken } = useContext(Context);
  const { currentUser } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    loadCampaignTemplateData(orgId, campaignId);
  }, []);
  const loadCampaignTemplateData = async (orgId, campaignId) => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviral-share/${orgId}/${campaignId}/sharePageDetails/shareDetails.json`
      );
      setCampaignTemplateData(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const handleReport = async () => {
    setReportLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_CERTIFICATE_REPORT}/${orgId}/${campaignId}/${contestId}/${videoId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
    } catch (error) {
      console.log(error.message);
      setReportLoading(false);
      //navigate("/error")
    }
  };
  const pageBgStyleGradient = encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="781" height="688" viewBox="0 0 781 688" fill="none">
  <g filter="url(#filter0_f_3339_1290)">
    <path d="M214.087 478.697C305.032 584.994 464.928 597.438 571.225 506.493C677.521 415.548 689.966 255.653 599.021 149.356L566.604 111.467C475.659 5.17072 315.763 -7.27399 209.466 83.6711C103.17 174.616 90.7251 334.512 181.67 440.808L214.087 478.697Z" fill="url(#paint0_radial_3339_1290)"/>
  </g>
  <defs>
    <filter id="filter0_f_3339_1290" x="0.187973" y="-97.8111" width="780.314" height="785.787" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="60.323" result="effect1_foregroundBlur_3339_1290"/>
    </filter>
    <radialGradient id="paint0_radial_3339_1290" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.704 330.705) rotate(-107.784) scale(259.431 243.666)">
      <stop offset="0.55" stop-color="${campaignTemplateData?.color1}"/>
      <stop offset="0.919792" stop-color="${campaignTemplateData?.color2}"/>
    </radialGradient>
  </defs>
</svg>
`);
  const pageBgStyle = {
    background: `black url("data:image/svg+xml;charset=utf-8,${pageBgStyleGradient}") no-repeat center center`,
    backgroundSize: "auto",
  };
  return (
    <>
      <div
        className="bg-black min-h-[85vh] px-[0] md:px-[116px]"
        style={pageBgStyle}
      >
        <ParticipationCertificate
          campaignTemplateData={campaignTemplateData}
          handleReport={handleReport}
          reportLoading={reportLoading}
        />
      </div>
      <BlackLeadsFooter color={campaignTemplateData?.color1} />
    </>
  );
}
