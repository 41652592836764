import React, { useContext, useEffect, useState } from "react";
import styles from "./RegistationDetails.module.css";
import PopupWrapper from "../../Layout/PopupWrapper";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import regSuccess from "../../assests/regSuccess.svg";
import regFailed from "../../assests/regFailed.svg";
import successTick from "../../assests/successTick.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import EditRegistration from "../EditRegistration/EditRegistration";
import DonePopup from "../DonePopup/DonePopup";
import Context from "../../context/Context";
import { useParams } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import user from "../../assests/user.svg";
import ProfileDropDown from "../ProfileDropDown/ProfileDropDown";

const RegistationDetails = ({
  retryLoading,
  loading,
  handleClick,
  details,
  promotionDataJson,
}) => {
  const { currentUser, setShowLogin } = useUserAuth();
  const { successMessage } = useContext(Context);
  const [edit, setEdit] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const { tinyUrl } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const downloadQr = (e) => {
    fetch(details?.qrUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "entry-pass.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleProfileClick = () => {
    if (currentUser) {
      setShowProfileDropdown((prevState) => !prevState);
    } else {
      setShowLogin(true);
    }
  };
  return (
    <div className="bg-white">
      {successMessage && <DonePopup message={successMessage} />}
      {edit && <EditRegistration setPopup={setEdit} details={details} />}
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          {loading ? (
            <div className="flex justify-center items-center h-[30vh]">
              <div className="w-[25px] h-[25px] ">
                <LoadingCircle />
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center w-full mb-[10px]">
                <Link
                  to={{
                    pathname: `/${tinyUrl}/details`,
                    search: `${
                      searchParams ? createSearchParams(searchParams) : ""
                    }`,
                  }}
                  className="w-full"
                >
                  <button className="px-[25px] py-[10px] flex items-center gap-[5px] bg-[#252525] rounded-full mr-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M6.47173 3.02843C6.50976 3.06643 6.53994 3.11155 6.56052 3.16121C6.58111 3.21087 6.59171 3.2641 6.59171 3.31787C6.59171 3.37163 6.58111 3.42486 6.56052 3.47452C6.53994 3.52419 6.50976 3.5693 6.47173 3.6073L3.48792 6.59059L11.5005 6.59059C11.609 6.59059 11.713 6.63369 11.7897 6.71041C11.8665 6.78713 11.9096 6.89119 11.9096 6.99968C11.9096 7.10818 11.8665 7.21224 11.7897 7.28895C11.713 7.36567 11.609 7.40877 11.5005 7.40877H3.48792L6.47173 10.3921C6.54849 10.4688 6.59161 10.5729 6.59161 10.6815C6.59161 10.7901 6.54849 10.8942 6.47173 10.9709C6.39497 11.0477 6.29085 11.0908 6.1823 11.0908C6.07374 11.0908 5.96963 11.0477 5.89286 10.9709L2.21105 7.28912C2.17301 7.25112 2.14284 7.206 2.12225 7.15634C2.10166 7.10668 2.09106 7.05344 2.09106 6.99968C2.09106 6.94592 2.10166 6.89269 2.12225 6.84303C2.14284 6.79336 2.17301 6.74825 2.21105 6.71025L5.89286 3.02843C5.93086 2.9904 5.97598 2.96022 6.02564 2.93964C6.0753 2.91905 6.12853 2.90845 6.1823 2.90845C6.23606 2.90845 6.28929 2.91905 6.33895 2.93964C6.38862 2.96022 6.43373 2.9904 6.47173 3.02843Z"
                        fill="#F6F6F6"
                      />
                    </svg>
                    <p className="text-small-bold">View All Registrations</p>
                  </button>
                </Link>
                <div
                  className=" items-center gap-[10px] flex self-end relative py-[6px] px-[10px] transition duration-300 ease-in"
                  onClick={handleProfileClick}
                  style={{
                    background: showProfileDropdown ? " " : "",
                    color: showProfileDropdown ? "#F6F6F6" : "#F6F6F6",
                  }}
                >
                  <div className="flex md:h-[52px] md:w-[52px] min-w-[44px] md:min-w-[52px]  h-[44px] w-[44px] bg-blue-400  items-center justify-center rounded-full">
                    <img
                      src={currentUser?.photoURL ? currentUser?.photoURL : user}
                      alt=""
                      className="h-full w-full object-cover rounded-full overflow-hidden cursor-pointer z-10"
                    />
                  </div>
                  {showProfileDropdown && <ProfileDropDown />}
                </div>
              </div>
              <img
                src={successTick}
                alt=""
                className="w-[56px] h-[56px] mb-[15px]  "
              />
              <p className="text-regular-medium px-[20px] mb-[16px]">
                Registration Details
              </p>
              <table className={styles.table}>
                <tbody>
                  {details?.name && (
                    <tr>
                      <td className="text-small-bold whitespace-nowrap">
                        Name
                      </td>
                      <td className="text-small break-all">{details?.name}</td>
                    </tr>
                  )}
                  {details?.regId && (
                    <tr>
                      <td className="text-small-bold whitespace-nowrap">
                        Registration Id
                      </td>
                      <td className="text-small break-all">{details?.regId}</td>
                    </tr>
                  )}
                  {details?.createdAt && (
                    <tr>
                      <td className="text-small-bold whitespace-nowrap">
                        Registration date & time
                      </td>
                      <td className="text-small break-all">
                        {(() => {
                          const dateTime = new Date(details.createdAt);
                          const date = dateTime.toDateString();
                          const time = dateTime.toTimeString().split(" ")[0];
                          return `${date} ${time}`;
                        })()}
                      </td>
                    </tr>
                  )}

                  {details?.userEmail && (
                    <tr>
                      <td className="text-small-bold whitespace-nowrap">
                        Contact Email
                      </td>
                      <td className="text-small break-all">
                        {details?.userEmail}
                      </td>
                    </tr>
                  )}
                  {details?.status && (
                    <tr>
                      <td className="text-small-bold whitespace-nowrap">
                        Status
                      </td>
                      <td className="text-small break-all">
                        {details?.status == "CREATED" && "Pending Approval"}
                        {details?.status == "FREE_ENTRY_CREATED" &&
                          "Pending Approval"}
                        {details?.status == "APPROVED" && "Approved"}
                        {details?.status == "REJECTED" && "Rejected"}
                      </td>
                    </tr>
                  )}
                  {details?.paymentStatus &&
                    details?.paymentStatus !== "FREE_ENTRY" && (
                      <tr>
                        <td className="text-small-bold whitespace-nowrap">
                          Payment Status
                        </td>
                        <td className="text-small break-all flex items-center gap-[5px] justify-end">
                          {details?.paymentStatus}
                          <img
                            src={
                              details?.paymentStatus == "PAYMENT_SUCCESS" ||
                              details?.paymentStatus == "FREE_ENTRY"
                                ? regSuccess
                                : regFailed
                            }
                            alt=""
                            className="w-[15px] h-[15px]"
                          />
                        </td>
                      </tr>
                    )}
                  {details?.editReason && details?.status !== "APPROVED" && (
                    <tr>
                      <td className="text-small-bold whitespace-nowrap">
                        Edit Details
                      </td>
                      <td className="text-small break-all">
                        {details?.editReason}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="flex  gap-[16px] w-[100%] cursor-pointer ">
                {details?.uid == currentUser?.uid &&
                  details?.status !== "APPROVED" && (
                    <button
                      className={`text-small-medium ${styles.button} disabled:opacity-40`}
                      onClick={() => setEdit(true)}
                    >
                      Edit Registration
                    </button>
                  )}
                {details?.paymentStatus !== "PAYMENT_SUCCESS" &&
                promotionDataJson?.showMoreHeader?.regFee[details?.regType] ? (
                  <button
                    className={`text-small-medium ${styles.button} disabled:opacity-40`}
                    onClick={handleClick}
                    disabled={retryLoading || loading}
                  >
                    {retryLoading ? (
                      <div className="flex justify-center">
                        <div className="w-[25px] h-[25px] ">
                          <LoadingCircle />
                        </div>
                      </div>
                    ) : (
                      "Retry Payment"
                    )}
                  </button>
                ) : (
                  details?.qrUrl && (
                    <button
                      className={`text-small-medium ${styles.button} disabled:opacity-40`}
                      onClick={downloadQr}
                    >
                      Download Entry Pass
                    </button>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistationDetails;
