import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useUserAuth } from "../context/UserAuthContext";
import Context from "../context/Context";
import SignupPage from "./SignupPage";
import RegistationDetails from "../Components/RegistationDetails/RegistationDetails";

export default function RegistrationDetailsPage() {
  const { userIdToken, update, tinyUrlFromMetaData } = useContext(Context);
  const domain = window.location.href.split("/")[2];
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const organizationId = tinyUrl.split("o").pop().split("ent")[0];
  const entryId = tinyUrl.split("ent").pop().split("inf")[0];
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);
  const [promotionDataJson, setPromotionDataJson] = useState({});
  const [registrationDetails, setRegistrationDetails] = useState({});
  const { regId } = useParams();

  const {
    currentUser,
    setPreviousLocation,
    showLogin,
    setCustomLogin,
    setShowLogin,
  } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    if (currentUser) {
      loadRegistationDetails();
    }
  }, [currentUser, update]);

  useEffect(() => {
    loadJsonDetails();
  }, []);

  const loadJsonDetails = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_GET_EVENT_JSON}/${organizationId}/${entryId}/eventDetails.json`
      );
      const promotionDataJson = data.data;
      setPromotionDataJson(promotionDataJson);
      if (!currentUser) {
        setPreviousLocation(window.location.pathname);
        setShowLogin(true);
        setCustomLogin(promotionDataJson?.loginBanner);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const loadRegistationDetails = async () => {
    try {
      setLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_REGISTERATION_DETAILS}/${organizationId}/${entryId}/${regId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setRegistrationDetails(response?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const initiatePayment = async () => {
    try {
      setRetryLoading(true);
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_INITIATE_PAYMENT_PHONEPE}/${organizationId}/${entryId}/${regId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        let paymentRedirectUrl = response?.data?.paymentRedirectUrl;
        let url =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo
            ?.url;
        handleRedirectToLink(url, paymentRedirectUrl);

        // window.open(url, "_self", "noopener,noreferrer");
      }
      setRetryLoading(false);
    } catch (err) {
      setRetryLoading(false);

      console.log(err.message);
    }
  };
  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.shootmates.com/thirdpartyPayment";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
  }
  const handleNavigate = () => {
    window.location.href = `https://${domain}/${tinyUrl}`;
  };

  return (
    <div>
      {showLogin && <SignupPage />}
      <RegistationDetails
        details={registrationDetails}
        loading={loading}
        retryLoading={retryLoading}
        handleClick={initiatePayment}
        promotionDataJson={promotionDataJson}
      />
    </div>
  );
}
