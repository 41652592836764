import React, { useContext, useEffect, useState } from "react";
import CouponTemplate1 from "../CouponTemplate/CouponTemplate1";
import styles from "./Share.module.css";
import CouponDetails from "../CouponDetails/CouponDetails";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import axios from "axios";
import CouponLoginScreen from "../CouponLoginScreen/CouponLoginScreen";

export default function Coupons({
  coupons,
  setCoupons,
  handleClickCounterCampaign,
}) {
  const [showCoupon, setShowCoupon] = useState(false);
  const [couponData, setCouponData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const {
    userIdToken,
    setSelectedCoupons,
    selectedCoupons,
    tinyUrlFromMetaData,
  } = useContext(Context);
  const {
    currentUser,
    setPreviousLocation,
    setShowLogin,
    reqForGenerate,
    setReqForGenerate,
  } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  let campaignId = tinyUrl.split("cp").pop().split("cn")[0];

  const handleShowCoupon = (data) => {
    handleClickCounterCampaign(
      data?.brandId,
      data?.videoName,
      data?.discountName,
      data?.brandName,
      "ads",
      "campaign"
    );
    switch (data?.type) {
      case "GENERATE":
        if (currentUser) {
          if (data?.generatedCoupon) {
            setCouponData(data);
            setShowCoupon(true);
          } else {
            handleGenerateCoupon(data);
          }
        } else {
          setCouponData(data);
          setLoginScreen(true);
        }
        break;
      case "INSTANT_SHOW":
        setCouponData(data);
        setShowCoupon(true);
        break;
      default:
        break;
    }
  };
  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    setReqForGenerate(true);
    localStorage.setItem("previousLocationLocal", window.location.pathname);
    setShowLogin(true);
  };
  const handleGenerateCoupon = async (data) => {
    try {
      setLoading(true);
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_GENENRATE_COUPON}/${campaignId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          videoName: data?.videoName,
        },
      };
      const resonse = await axios(config);
      let generatedCoupon = resonse?.data?.offer;
      let newCouponArray = coupons;
      let index = newCouponArray?.findIndex(
        (el) => el?.videoName == generatedCoupon?.videoName
      );
      if (index !== -1) {
        newCouponArray[index] = generatedCoupon;
      }
      setCoupons(newCouponArray);
      setShowCoupon(true);
      setCouponData(generatedCoupon);
      handleSelectCoupon(generatedCoupon, true);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSelectCoupon = (coupon, viewCoupon) => {
    let newCouponObj = coupon;
    newCouponObj.selected = !newCouponObj.selected;
    setSelectedCoupons((prevState) => {
      let couponArray = prevState;
      const isCouponSelected = prevState.some(
        (c) => c.videoName == newCouponObj.videoName
      );
      if (isCouponSelected) {
        return prevState.filter((c) => c.videoName !== newCouponObj.videoName);
      } else {
        return [...prevState, newCouponObj];
      }
    });
    setCoupons((prevState) => {
      let newCouponArray = prevState;
      let index = newCouponArray?.findIndex(
        (el) => el?.videoName == newCouponObj?.videoName
      );
      if (index !== -1) {
        newCouponArray[index] = newCouponObj;
      }
      return newCouponArray;
    });
    if (!viewCoupon) {
      setShowCoupon(false);
    }
  };

  useEffect(() => {
    if (reqForGenerate) {
      setLoginScreen(false);
      handleGenerateCoupon(couponData);
      setReqForGenerate(false);
    }
  }, [currentUser]);
  return (
    <>
      {showCoupon && (
        <CouponDetails
          setShowPopup={setShowCoupon}
          data={couponData}
          handleSelectCoupon={handleSelectCoupon}
        />
      )}
      {loginScreen && (
        <CouponLoginScreen
          setShowPopup={setLoginScreen}
          data={couponData}
          handleLogin={handleLogin}
        />
      )}
      <div className={`${styles.couponsParent} py-[30px] bg-white`}>
        <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto">
          <h3 className="mb-[20px] pl-[16px]">All Coupons</h3>
          <div
            className={`flex box  gap-[16px] pr-[16px] overflow-x-auto w-full pb-[20px] pl-[16px]`}
          >
            {coupons?.map((obj) => {
              return (
                <CouponTemplate1
                  data={obj}
                  handleShowCoupon={handleShowCoupon}
                  loading={loading}
                  handleSelectCoupon={handleSelectCoupon}
                  handleGenerateCoupon={handleGenerateCoupon}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
