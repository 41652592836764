import React, { useEffect, useState } from "react";
import { authentication } from "../../store/firebase-config";
import { useUserAuth } from "../../context/UserAuthContext";

export default function ProfileDropDown() {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
  }, []);
  const { currentUser } = useUserAuth();
  return (
    <div
      className={`z-10 flex flex-col gap-[8px] w-fit absolute top-[50px] md:top-[58px] right-0 h-fit  rounded-b-[7px] py-[6px] px-[10px] transition duration-300 ease-in drop-shadow-md ${
        load ? "bg-[#171717]" : ""
      }`}
    >
      <p className="text-regular-medium whitespace-nowrap py-[10px]">
        {currentUser?.email || currentUser?.displayName}
      </p>
      <button
        onClick={() => authentication.signOut()}
        className="whitespace-nowrap  py-[10px]"
      >
        Sign Out
      </button>
    </div>
  );
}
