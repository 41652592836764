import React from "react";
import ReactPlayer from "react-player";

export default function DanceTemplatePromotors({ promotionDataJson }) {
  return (
    <div className="pro-promotor-container w-full ">
      <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] pb-[32px] md:pb-[60px] pt-[32px] md:pt-[60px] mx-auto">
        <div className="px-[16px] md:px-0 flex flex-col gap-[10px]">
          <h2>ABOUT US</h2>
          <pre>{promotionDataJson?.description}</pre>
        </div>
      </div>
    </div>
  );
}
