import React from "react";
import styles from "./LoadingCircle.module.css";

export default function LoadingCircle() {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingCircle}></div>
    </div>
  );
}
