import React, { useEffect, useState } from "react";
import "./Share.css";
import close from "../../assests/closebuttonblack.png";
import styles from "./Share.module.css";
import ReactPlayer from "react-player";
import { isEmpty } from "lodash";

const DetailsPopup = ({ data, setPopup, promotionData }) => {
  const [showTerms, setShowTerms] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 h-[100%] w-[100%] z-10 px-[20px] pb-[100px] ${styles.detailsPopupBackground} overflow-y-auto no-scrollbar`}
    >
      <div className={`${styles.detailsPopupParent}`}>
        <div className="w-[100%] h-[30%] relative rounded-t-[20px] overflow-hidden">
          <div className="w-full">
            <div
              className="ml-auto rounded-full bg-white h-[48px] w-[48px] flex justify-center items-center hover:cursor-pointer"
              onClick={() => setPopup(false)}
            >
              <img src={close} alt="" className="h-[16px] w-[16px] " />
            </div>
          </div>
          <div className="text-black w-[100%] overflow-auto  no-scrollbar flex flex-col  items-center justify-start pt-[16px] text-center px-[20px]">
            <p className="mb-[5px] text-[20px] ">{data?.shopbuttton}</p>
            <img
              className="rounded-full w-[80px] h-[80px] object-cover shrink-0"
              src={data?.fileUrl}
              alt=""
            />
            <p className="judgesName shrink-0 text-black">{data?.position}</p>

            <pre className="mt-[8px] text-regular text-black">
              {data?.about}
            </pre>
          </div>
          {!isEmpty(data?.media) && (
            <div className="px-[20px] mt-[30px] ">
              <p className="text-medium mb-[15px] text-black">Gallery</p>
              <div className="flex flex-col gap-[30px]">
                {data?.media?.map((obj) => {
                  return (
                    <>
                      {obj.fileType?.startsWith("video") ? (
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          playing={false}
                          controls={true}
                          width="100%"
                          height="100%"
                          url={obj.fileUrl}
                        />
                      ) : (
                        <img
                          src={obj.fileUrl}
                          alt=""
                          className="h-[100%] w-[100%] mx-auto object-contain"
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsPopup;
