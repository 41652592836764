// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfiJfqBgu_HG7aH-ynw56MEsNDketJScg",
  authDomain: "queueeasy.firebaseapp.com",
  projectId: "queueeasy",
  storageBucket: "queueeasy.appspot.com",
  messagingSenderId: "1055043371765",
  appId: "1:1055043371765:web:e4eebd08efaead0e783bbc",
  measurementId: "G-D2HVQ935K8",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
export const storage = getStorage(app);
export const profileStorage = getStorage(app, "goviralprofiledatas");
export const mediaStorage = getStorage(app, "goviralmedias");
export const leadsStorage = getStorage(app, "leads_media");
